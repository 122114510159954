import Hotjar from "@components/thirdParty/hotjar";
import VWO from "@components/thirdParty/vwo";
import HeaderNav from "@components/navigation/HeaderNav";
import { useSessionStore } from "@stores/session";
import { NextComponentType, NextPageContext } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState, useCallback } from "react";
import { captureException } from "@sentry/nextjs";
import { QueryClientProvider } from "@common/utils/use-query";
import Snowplow from "@common/components/thirdParty/snowplow";
import SnowplowAnon from "@common/components/thirdParty/snowplowanon";
import { MantineProvider, AppShell } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

// GDPR
import GDPRCookieNotice from "@components/thirdParty/passatgt";
import "public/gdpr-cookie-notice/style.css";
import { useGDPRStore } from "@stores/gdpr";
import { persistUTMQueryParamsToUrlObject } from "@common/utils/router";
import { IAccount } from "@stores/session/types";
import { isBrowser } from "@common/utils/browser";
import SideNav from "@components/navigation/SideNav";
import { usePurchasesStore } from "@stores/purchases";
import { defaultTheme as defaultThemeMantine } from "@common/constants/theme-mantine/constants";
import { usePaymentMethodsStore } from "@stores/payment-methods";
import DefaultContainer from "@common/components/layout/DefaultContainer";
import { GoogleTagManager, GoogleAnalytics } from "@next/third-parties/google";
import { isProduction } from "@common/utils/config";

type StoreAppComponentProps = NextComponentType<NextPageContext, any, any> & {
  account?: IAccount | null;
  hideNav?: boolean;
};

type StoreAppProps = AppProps & {
  Component: StoreAppComponentProps;
};

const baseAPIUrl = `${process.env.NEXT_PUBLIC_HARBORMASTER_API_BASE_URL}${process.env.NEXT_PUBLIC_HARBORMASTER_API_BASE_PATH}`;

export default function StoreApp({
  Component,
  pageProps,
}: StoreAppProps): ReactElement {
  const router = useRouter();
  const { pathname, query } = router;

  // session
  const { loading, account, getAccountDetails, logout } = useSessionStore(
    (state) => ({
      loading: state.loading,
      account: state.account,
      getAccountDetails: state.getAccountDetails,
      logout: state.logout,
    }),
  );

  // payment methods
  const { clearPaymentMethods } = usePaymentMethodsStore((state) => ({
    clearPaymentMethods: state.clearPaymentMethods,
  }));

  const logoutCallback = useCallback(async () => {
    const { success } = await logout();
    if (success) {
      clearPaymentMethods();
      router.push(persistUTMQueryParamsToUrlObject(router, "/"));
    }
  }, [logout, clearPaymentMethods, router]);

  // purchases
  const { purchases, getPurchases } = usePurchasesStore((state) => ({
    purchases: state.purchases,
    getPurchases: state.getPurchases,
  }));

  // keep `query` as a dependency:
  // to `getPurchases` on every steps of the checkout,
  // we use `query` from `router`
  // as it is the part that changes when the url updates.
  useEffect(() => {
    getPurchases();
  }, [query, getPurchases]);

  // are we in one of the checkout pages? `/checkout`
  const isCheckoutPathname = !!(pathname.indexOf("/checkout") > -1);

  // GDPR
  const { marketing, analytics } = useGDPRStore((state) => ({
    marketing: state.marketing,
    analytics: state.analytics,
    // performance: state.performance,
  }));

  // page url
  const [previousPageUrl, setPreviousPageUrl] = useState<string>();
  const onRouteChangeStart = useCallback(() => {
    setPreviousPageUrl(window.location.href);
  }, []);

  useEffect(() => {
    router.events.on("routeChangeStart", onRouteChangeStart);
    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
    };
  }, [router.events, onRouteChangeStart]);

  // page
  const hideNav = Component.hideNav || false;
  const visible = !!(
    process.env.VISIBLE_PATHS.indexOf(router.pathname) > -1 ||
    process.env.VISIBLE_PATHS.indexOf(router.asPath) > -1
  );

  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    async function getAccountDetailsAsync() {
      await getAccountDetails();
      setFirstLoading(false);
    }

    getAccountDetailsAsync();
  }, [getAccountDetails]);

  // side nav
  const [opened, setOpened] = useState(false);

  // Is it a restricted page?
  useEffect(() => {
    if (!visible && !loading && !account && router) {
      router.replace(
        persistUTMQueryParamsToUrlObject(router, "/", {
          redirectTo: router.asPath,
        }),
      );
    }
  }, [visible, loading, account, router]);

  if (loading && firstLoading) {
    return <></>;
  }

  if (!visible && !loading && !account) {
    return <></>;
  }

  return (
    <>
      <Head>
        <title>The Metabase Store</title>
      </Head>

      {isBrowser() && isProduction() && (
        <GoogleTagManager
          gtmId={process.env.GTM}
          // preview={process.env.NODE_ENV}
        />
      )}

      <QueryClientProvider baseUrl={baseAPIUrl} onError={captureException}>
        <MantineProvider
          theme={defaultThemeMantine}
          withGlobalStyles
          withNormalizeCSS
        >
          <NotificationsProvider>
            <AppShell
              padding={0}
              navbarOffsetBreakpoint="sm"
              asideOffsetBreakpoint="sm"
              header={
                !hideNav ? (
                  <HeaderNav
                    account={account}
                    hasPurchases={!!(purchases && purchases.length > 0)}
                    opened={opened}
                    onBurgerClick={() => setOpened(!opened)}
                    logout={logoutCallback}
                  />
                ) : undefined
              }
              navbar={
                !hideNav && !isCheckoutPathname ? (
                  <SideNav
                    account={account}
                    hasPurchases={!!(purchases && purchases.length > 0)}
                    opened={opened}
                    logout={logoutCallback}
                  />
                ) : undefined
              }
            >
              <DefaultContainer {...pageProps}>
                <Component {...pageProps} account={account} />
              </DefaultContainer>
            </AppShell>

            {isBrowser() && (
              <>
                <SnowplowAnon />

                {marketing && (
                  <>
                    <Hotjar />
                    <Snowplow
                      initCallsArgs={[
                        "'enableActivityTracking', 10, 10",
                        "'enableLinkClickTracking'",
                      ]}
                      referrerUrl={previousPageUrl || document.referrer}
                      account={account}
                    />
                    <VWO />
                  </>
                )}

                {analytics && <GoogleAnalytics gaId={process.env.GA} />}
              </>
            )}

            <GDPRCookieNotice />
          </NotificationsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </>
  );
}
