export function setCookie(
  name: string,
  value: string | number | boolean,
  days = 7,
  path = "/",
): void {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    expires +
    "; path=" +
    path;
}

function parseValue(value: string): string | number | boolean | undefined {
  if (typeof value === "string" && value.length > 0) {
    // true
    if (value === "true") {
      return true;
    }
    // false
    else if (value === "false") {
      return false;
    }
    // number
    else if (!isNaN(parseInt(value))) {
      return parseInt(value);
    }
    // string
    else if (value !== "undefined" && value !== "null") {
      return value;
    }
  }

  return undefined;
}

export function getCookie(name: string): string | number | boolean | undefined {
  const value = document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");

  return parseValue(value);
}
