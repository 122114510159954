import { createStyles } from "@mantine/core";
import { IDisabledContainerProps } from "./types";

export const useStyles = createStyles(
  (theme, { disabled }: IDisabledContainerProps) => ({
    root: {
      margin: 0,
      padding: 0,

      ...(disabled
        ? {
            cursor: "default",
            opacity: theme.other.disabled.opacity,
            pointerEvents: "none",
            userSelect: "none",

            "input, textarea, select": {
              pointerEvents: "none",
            },
          }
        : {}),
    },
  }),
);
