import {
  Box,
  Button,
  Flex,
  Group,
  Loader,
  NativeSelect,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { configStore } from "@stores/config";
import { ReactElement, useMemo } from "react";
import { ISelectCountryProps } from "./types";
import { captureException } from "@sentry/nextjs";
import { normaliseValue } from "./utils";
import ErrorMessage from "@common/components/text/ErrorMessage";

export default function SelectCountry({
  defaultValue,
  value,
  onChange,
  "data-testid": dataTestid,
  mt,
}: ISelectCountryProps): ReactElement {
  const theme = useMantineTheme();

  const { isLoading, error, data, refetch } = configStore.useGetConfig();

  const isDisabled = !!(isLoading || error);
  const theData = useMemo(
    () =>
      data
        ? Object.keys(data.permitted_countries)
            .map((key) => ({
              value: key,
              label: data.permitted_countries[key],
            }))
            .sort((pair1, pair2) => pair1.label.localeCompare(pair2.label))
        : [],
    [data],
  );

  // Error
  if (error) {
    captureException(`SelectCountry, error loading config: ${error}`);

    return (
      <Box mt={mt === 0 ? mt : mt || "lg"}>
        <Text c="gray.5" fw={700} fz="sm" mb={theme.spacing.xs / 2} w="100%">
          Country{" "}
          <Text span c="red.3">
            *
          </Text>
        </Text>
        <Group spacing="xs" mt={theme.spacing.xs / 2}>
          <Button onClick={() => refetch()}>Retry</Button>
          <ErrorMessage m={0}>Error loading country...</ErrorMessage>
        </Group>
      </Box>
    );
  }

  // Normalise values
  const theValue = normaliseValue(theData, value);
  const theDefaultValue = normaliseValue(theData, defaultValue);

  return (
    <Flex
      align="flex-end"
      direction="row"
      justify="flex-start"
      wrap="nowrap"
      mt={mt === 0 ? mt : mt || "lg"}
      w="100%"
    >
      <NativeSelect
        data-testid={dataTestid}
        disabled={isDisabled}
        label="Country"
        placeholder={isLoading ? "Loading" : "Select one..."}
        data={theData}
        defaultValue={theDefaultValue}
        value={theValue}
        onChange={onChange}
        required
        w="100%"
      />

      {isLoading && <Loader size="sm" mb="sm" ml="sm" />}
    </Flex>
  );
}
