import {
  STARTER_PLAN,
  PRO_PLAN,
  PRO_CLOUD_WITH_DWH_PLAN,
  ENTERPRISE_PLAN,
  PLAN_STARTER,
  PLAN_PRO,
  PRO_SUBPLAN_SELF_HOSTED,
  STARTER_WITH_DWH_PLAN,
  PLAN_STARTER_WITH_DWH,
  PLAN_PRO_CLOUD_WITH_DWH,
} from "./constants";
import { PlanSlugType, PlanType, SubPlanType } from "./types";

export const PLANS_LIST: PlanType[] = [
  STARTER_PLAN,
  STARTER_WITH_DWH_PLAN,
  PRO_PLAN,
  PRO_CLOUD_WITH_DWH_PLAN,
  ENTERPRISE_PLAN,
];

// checkout
const PLAN_SLUGS: PlanSlugType[] = [
  PLAN_STARTER,
  PLAN_STARTER_WITH_DWH,
  PLAN_PRO,
  PLAN_PRO_CLOUD_WITH_DWH,
];
export const VALID_PLANS_LIST: PlanType[] = PLANS_LIST.filter(
  ({ slug }) => PLAN_SLUGS.indexOf(slug) > -1,
);
export const DEFAULT_PLAN: PlanType = STARTER_PLAN;

export const DEFAULT_SELF_HOSTED_PLAN: PlanType = PRO_PLAN;
export const DEFAULT_SELF_HOSTED_SUBPLAN: SubPlanType = PRO_SUBPLAN_SELF_HOSTED;
