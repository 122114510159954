import create from "zustand";
import { GDPRStore } from "./types";

export const useGDPRStore = create<GDPRStore>(
  (setState): GDPRStore => ({
    marketing: false,
    setMarketing(value: boolean) {
      setState({ marketing: value });
    },
    analytics: false,
    setAnalytics(value: boolean) {
      setState({ analytics: value });
    },
    performance: false,
    setPerformance(value: boolean) {
      setState({ performance: value });
    },
  }),
);
