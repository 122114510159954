import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconPage({ size, color }: IIconProps): ReactElement {
  return (
    <svg
      height={size || 16}
      width={size || 16}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1845_2308)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.63684 1.12109H7.51682V4.08162V4.67374H10.5V11.2609C10.5 11.8741 9.99103 12.3711 9.36316 12.3711H2.63684C2.00898 12.3711 1.5 11.8741 1.5 11.2609V2.23129C1.5 1.61815 2.00898 1.12109 2.63684 1.12109ZM10.4226 4.08162L8.11849 1.23021V4.08162H10.4226ZM2.70336 5.62109H9.32186V6.2132H2.70336V5.62109ZM9.32186 7.21979H2.70336V7.81189H9.32186V7.21979ZM2.70336 8.81845H9.32186V9.41055H2.70336V8.81845ZM6.0126 10.3579H2.73529V10.95H6.0126V10.3579Z"
          fill={color || "#4C5773"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1845_2308">
          <rect
            height="12"
            width="12"
            fill="white"
            transform="translate(0 0.746094)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
