import { billingStore } from "@stores/billing";
import { getTaxIdLabelForRow, getUniqueIdForTaxIdForRow } from "./utils";
import { Flex, Text } from "@mantine/core";
import DisabledContainer from "@common/components/layout/DisabledContainer";
import ButtonAsText from "@common/components/thirdParty/mantine/ButtonAsText";
import { ITaxIdRowProps } from "./types";
import { ReactElement } from "react";
import ErrorMessage from "@common/components/text/ErrorMessage";

export default function TaxIdRow({ taxId }: ITaxIdRowProps): ReactElement {
  const { value } = taxId;
  const {
    isLoading,
    error,
    mutate: deleteTaxId,
  } = billingStore.useDeleteTaxId(taxId);

  return (
    <Flex
      data-testid={getUniqueIdForTaxIdForRow(taxId, value)}
      align="baseline"
      direction="row"
    >
      <Text fz="sm" mr="sm">
        {getTaxIdLabelForRow(taxId, value)}
      </Text>

      <DisabledContainer disabled={isLoading}>
        <ButtonAsText
          // So we do not have a HTML hierarchy issue
          // with buttons inside button
          component="div"
          onClick={(e) => {
            e.stopPropagation();
            deleteTaxId(taxId);
          }}
          disabled={isLoading}
        >
          Remove
        </ButtonAsText>
      </DisabledContainer>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Flex>
  );
}
