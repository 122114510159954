import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconUser({ color, size }: IIconProps): ReactElement {
  return (
    <svg
      height={size || 12}
      width={size || 12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.02538 0.00197315C7.96841 -0.067349 8.76124 1.70642 8.76124 2.72372C8.76124 3.74103 8.25894 5.23956 7.40857 6.01254C7.22068 6.18332 7.25562 7.26473 7.66577 7.44035C8.52586 7.80862 10.8794 8.55417 10.9686 10.0897C11.068 11.7991 7.98089 11.9729 6.07766 11.9729C4.17442 11.9729 1.125 11.7404 1.125 10.3045C1.125 9.63836 1.19188 9.20027 2.28963 8.4589C3.38739 7.71753 4.22441 7.536 4.40659 7.4233C4.58878 7.31061 4.92545 6.72509 4.70663 6.071C4.70663 6.071 3.38739 4.63 3.38739 3.04308C3.38739 1.45617 4.08235 0.0712953 6.02538 0.00197315Z"
        fill={color || "#4C5773"}
      />
    </svg>
  );
}
