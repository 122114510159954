import { useState, ReactElement, useCallback } from "react";
import {
  Anchor,
  Button,
  Divider,
  Flex,
  Group,
  List,
  Modal,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { IDataFetcherResponseError } from "@common/utils/use-query/types";
import { IConfirmationModalProps } from "./types";
import { PurchaseRow } from "./ConfirmationModal.components";
import { useSessionStore } from "@stores/session";
import { showNotification } from "@mantine/notifications";
import Tick from "@common/components/icons/Tick";
import WarningCard from "@common/components/thirdParty/mantine/WarningCard";
import IconWarning from "@common/components/icons/IconWarning";
import { useStyles } from "./ConfirmationModal.styled";
import ErrorMessage from "@common/components/text/ErrorMessage";
import { persistUTMQueryParamsToString } from "@common/utils/router";
import { useRouter } from "next/router";

export default function ConfirmationModal({
  purchases,
  opened,
  onClose,
}: IConfirmationModalProps): ReactElement | null {
  const router = useRouter();

  const [theLoading, setTheLoading] = useState(false);
  const [theError, setTheError] = useState<IDataFetcherResponseError | null>(
    null,
  );

  // purchases
  const monthlyPurchases = purchases
    ?.filter(({ product }) => product.plan.alias.indexOf("yearly") === -1)
    .filter(({ subscription }) => !subscription.is_canceled);

  // confirm switching to invoicing
  const { switchToInvoicing } = useSessionStore((state) => ({
    switchToInvoicing: state.switchToInvoicing,
  }));
  const handleConfirm = useCallback(async () => {
    setTheLoading(true);

    const { success, error } = await switchToInvoicing();
    if (success) {
      setTheError(null);
      onClose();
      setTimeout(() => {
        showNotification({
          color: "teal",
          icon: <Tick color="white" />,
          autoClose: 5000,
          message: `Payment method switched to invoicing`,
        });
      }, 400);
    } else {
      setTheLoading(false);
      setTheError(error);
    }
  }, [switchToInvoicing, onClose]);

  // styling
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Modal
      data-testid="Invoicing-modal"
      centered
      onClose={onClose}
      opened={opened}
      padding={2 * theme.spacing.md}
      size="lg"
      title={
        <Title c="gray.6" size="h3">
          Switch to invoicing
        </Title>
      }
    >
      <Stack spacing="lg">
        <div>
          <List>
            <List.Item>
              <Text c="gray.5" fw={400} fz="sm">
                Billing contacts will receive an emailed invoice for annual
                payments, and every 3 months after that for any added users.
              </Text>
            </List.Item>
            <List.Item>
              <Text c="gray.5" fw={400} fz="sm">
                Invoices are due within 30 days, payable via ACH or wire
                transfer.
              </Text>
            </List.Item>
            <List.Item>
              <Text c="gray.5" fw={400} fz="sm">
                To switch back to paying with a credit card, you&apos;ll need to
                contact our Success team.
              </Text>
              <Text c="gray.5" fw={400} fz="sm">
                You may not be eligible for a refund for annual billing.
              </Text>
            </List.Item>
          </List>

          {monthlyPurchases && monthlyPurchases.length > 0 ? (
            <Text c="gray.5" fw={400} fz="sm" mt="sm">
              <Anchor
                href={persistUTMQueryParamsToString(
                  router,
                  "https://www.metabase.com/docs/latest/cloud/how-billing-works#what-counts-as-a-user-account",
                )}
                target="_blank"
                rel="noreferrer"
              >
                Learn more about how users are counted for billing.
              </Anchor>
            </Text>
          ) : (
            <Text c="gray.5" fw={400} fz="sm" mt="sm">
              <Anchor
                href={persistUTMQueryParamsToString(
                  router,
                  "https://www.metabase.com/docs/latest/cloud/how-billing-works",
                )}
                target="_blank"
                rel="noreferrer"
              >
                Learn more about how we bill.
              </Anchor>
            </Text>
          )}
        </div>

        {monthlyPurchases && monthlyPurchases.length > 0 && (
          <WarningCard withBorder>
            <Flex
              align="center"
              direction="row"
              justify="flex-start"
              wrap="nowrap"
              w="100%"
            >
              <div className={classes.warningIcon}>
                <IconWarning />
              </div>
              <Text c="gray.5" fw={700} fz="sm" ml="xs">
                All your instances will switch to annual billing
              </Text>
            </Flex>
            <Text c="gray.5" fz="xs" mt="sm">
              As part of switching to invoicing, you&apos;ll also switch all
              instances to annual billing. Your annual payment will be included
              in your first invoice, and your credit card won&apos;t be billed.
            </Text>

            <Divider
              color="yellow.3"
              opacity={theme.other.disabled.opacity / 2}
              mt="md"
              w="100%"
            />

            <Text c="gray.5" fw={700} fz="sm" mb="sm" mt="md">
              Summary of changes to billing
            </Text>

            <Stack spacing="sm">
              {monthlyPurchases.map((purchase, index) => {
                return (
                  <PurchaseRow
                    key={purchase.id}
                    index={index}
                    purchase={purchase}
                  />
                );
              })}
            </Stack>
          </WarningCard>
        )}

        <Group position="right" mt={theme.spacing.xs / 2}>
          <Button disabled={theLoading} onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button loading={theLoading} onClick={handleConfirm}>
            Confirm
          </Button>
        </Group>

        {theError && (
          <ErrorMessage>{theError.message || theError.status}</ErrorMessage>
        )}
      </Stack>
    </Modal>
  );
}
