import { COUNTRY_US_CODE, STATES_US } from "@common/constants/data";
import { usePaymentMethodsStore } from "@stores/payment-methods";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { ICreatePaymentMethodFormProps } from "./types";
import {
  Button,
  Card,
  Flex,
  Stack,
  NativeSelect,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { customerStore } from "@stores/customer";
import { ICustomerAddress } from "@stores/customer/types";
import { DEFAULT_CUSTOMER_ADDRESS } from "@stores/customer/constants";
import SelectCountry from "@components/ui/form/SelectCountry";
import Form from "@common/components/ui/form/Form";
import ErrorMessage from "@common/components/text/ErrorMessage";

interface ICreatePaymentMethodForm extends ICustomerAddress {
  name: string;
}

export default function CreatePaymentMethodForm({
  account,
}: PropsWithChildren<ICreatePaymentMethodFormProps>): ReactElement {
  const theme = useMantineTheme();
  const [theLoading, setTheLoading] = useState(false);

  // customer
  const {
    isLoading: loadingCustomerDetails,
    data: customerDetails,
    error: customerDetailsError,
    refetch: refetchCustomerDetails,
  } = customerStore.useGetDetails();

  // Payment methods
  const { loading: loadingPaymentMethods, createPaymentMethod } =
    usePaymentMethodsStore((state) => ({
      loading: state.loading,
      createPaymentMethod: state.createPaymentMethod,
    }));

  // Form
  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<ICreatePaymentMethodForm>({
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: customerDetails?.billing_address || DEFAULT_CUSTOMER_ADDRESS,
  });
  const watchCountry = watch("country");

  useEffect(() => {
    if (account) {
      setValue("name", `${account.first_name} ${account.last_name}`);
    }
  }, [account, setValue]);

  // Stripe
  const stripe = useStripe();
  const elements = useElements();
  const [stripeError, setStripeError] = useState<string | null>(null);

  const handleStripeSubmit = useCallback(
    async (formData: ICreatePaymentMethodForm) => {
      if (!account || !stripe || !elements) {
        return;
      }

      setTheLoading(true);

      // 1. Stripe related
      const card = elements.getElement(CardElement);
      if (!card) {
        console.error("CreatePaymentMethodForm, missing Stripe card element");
        return;
      }

      // format data
      const { name, ...address } = formData;

      // 2. save customer PM
      const { success, error } = await createPaymentMethod(card, stripe, {
        name,
        email: account.email,
        address,
      });
      // success
      if (success) {
        setStripeError(null);

        // 3. reload customer details
        refetchCustomerDetails();
      }
      // error
      else {
        setStripeError(error?.message || null);
      }

      setTheLoading(false);
    },
    [stripe, account, elements, createPaymentMethod, refetchCustomerDetails],
  );

  const showSpinner =
    loadingCustomerDetails ||
    loadingPaymentMethods ||
    theLoading ||
    !stripe ||
    !elements;

  return (
    <Form
      data-testid="CreatePaymentMethodForm"
      onSubmit={handleSubmit(handleStripeSubmit)}
      disabled={showSpinner}
    >
      <Stack spacing="sm">
        <TextInput
          id="name"
          type="text"
          label="Name on card"
          placeholder="Name on card"
          required
          {...register("name", { required: true })}
        />

        <div>
          <Text c="gray.5" fz="sm" fw={700} mb={theme.spacing.xs / 2}>
            Credit card info *
          </Text>
          <Card id="stripe-form" withBorder p="sm">
            <CardElement
              options={{
                disableLink: true,
                hidePostalCode: true,
                style: {
                  base: {
                    fontFamily: theme.fontFamily,
                  },
                },
              }}
            />
          </Card>
          {stripeError && <ErrorMessage mt="lg">{stripeError}</ErrorMessage>}
        </div>

        <Flex
          align="flex-end"
          direction={{ base: "column", xs: "row" }}
          justify="flex-start"
          gap={theme.spacing.sm}
          w="100%"
        >
          <TextInput
            w="100%"
            id="street"
            type="text"
            label="Street address"
            placeholder="123 Main St"
            required
            {...register("street", { required: true })}
          />
          <TextInput
            w="100%"
            id="street2"
            type="text"
            placeholder="Unit number (optional)"
            {...register("street2")}
          />
        </Flex>

        <Controller
          name="country"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <SelectCountry
              data-testid="CreatePaymentMethodForm-country"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              mt={0}
            />
          )}
        />

        <Flex
          align="flex-start"
          direction={{ base: "column", xs: "row" }}
          justify="flex-start"
          gap={theme.spacing.lg}
          w="100%"
        >
          <TextInput
            w="100%"
            id="city"
            type="text"
            label="City"
            placeholder="City"
            defaultValue=""
            required
            {...register("city", { required: true })}
          />

          {watchCountry === COUNTRY_US_CODE ? (
            <Controller
              name="region"
              control={control}
              render={({ field: { value, onChange } }) => (
                <NativeSelect
                  w="100%"
                  data-testid="CreatePaymentMethodForm-region"
                  label="State"
                  placeholder="Select one..."
                  data={STATES_US.map(({ value, name: label }) => ({
                    value,
                    label,
                  }))}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}
            />
          ) : (
            <TextInput
              w="100%"
              id="region"
              type="text"
              label="State"
              placeholder="State"
              {...register("region")}
            />
          )}

          <TextInput
            w="100%"
            id="zip"
            type="text"
            label="ZIP Code"
            placeholder="Zipcode"
            maxLength={20}
            required
            {...register("zip", {
              required: true,
            })}
          />
        </Flex>
        {errors.zip && <ErrorMessage m={0}>{errors.zip.message}</ErrorMessage>}

        {customerDetailsError && (
          <ErrorMessage m={0}>
            {customerDetailsError.message || customerDetailsError.status}
          </ErrorMessage>
        )}
      </Stack>

      <Button
        type="submit"
        loading={showSpinner}
        loaderPosition="right"
        disabled={!isValid || showSpinner}
        mt="lg"
      >
        Save
      </Button>
    </Form>
  );
}
