export const SUBSCRIPTION_STATUS_TRIAL = "trial";
export const SUBSCRIPTION_STATUS_ACTIVE = "active";
// will have this status until the end of the period
export const SUBSCRIPTION_STATUS_CANCELLATION_PENDING = "cancellation-pending";
export const SUBSCRIPTION_STATUS_CANCELLED = "cancelled";

export const SUBSCRIPTION_STATUSES = [
  SUBSCRIPTION_STATUS_TRIAL,
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLATION_PENDING,
  SUBSCRIPTION_STATUS_CANCELLED,
] as const;
