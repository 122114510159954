import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconInfo({
  color,
  backgroundColor,
  size,
}: IIconProps): ReactElement {
  const theSize = size || 16;

  return (
    <svg
      height={theSize}
      width={theSize}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {backgroundColor && <circle cx={8} cy={8} r={8} fill={backgroundColor} />}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.8485 6.00025V13H6.756V6.00025H8.8485ZM9.0645 4.08325C9.0645 4.24975 9.03075 4.40612 8.96325 4.55238C8.89575 4.69863 8.8035 4.82687 8.6865 4.93713C8.5695 5.04738 8.43338 5.13512 8.27812 5.20038C8.12287 5.26563 7.9575 5.29825 7.782 5.29825C7.611 5.29825 7.45125 5.26563 7.30275 5.20038C7.15425 5.13512 7.02263 5.04738 6.90788 4.93713C6.79312 4.82687 6.70313 4.69863 6.63788 4.55238C6.57262 4.40612 6.54 4.24975 6.54 4.08325C6.54 3.91225 6.57262 3.7525 6.63788 3.604C6.70313 3.4555 6.79312 3.325 6.90788 3.2125C7.02263 3.1 7.15425 3.01225 7.30275 2.94925C7.45125 2.88625 7.611 2.85475 7.782 2.85475C7.9575 2.85475 8.12287 2.88625 8.27812 2.94925C8.43338 3.01225 8.5695 3.1 8.6865 3.2125C8.8035 3.325 8.89575 3.4555 8.96325 3.604C9.03075 3.7525 9.0645 3.91225 9.0645 4.08325ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill={color || "#4C5773"}
      />
    </svg>
  );
}
