import { createStyles } from "@mantine/core";
import { ILogo } from "./types";

export const useLogoWrapperStyles = createStyles(() => ({
  root: {
    display: "inline-block",
    position: "relative",
    width: "auto",
  },
}));

export const useLogoIconStyles = createStyles(
  (theme, { height, width }: ILogo) => ({
    root: {
      height: `${height}px`,
      minHeight: `${height}px`,
      minWidth: `${width}px`,
      verticalAlign: "bottom",
      width: `${width}px`,
    },
  }),
);
