import { COUNTRY_US, STATES_US, COUNTRY_US_CODE } from "@common/constants/data";
import { ICustomerAddress } from "./types";
import { IConfig } from "@stores/config/types";

export function formatAddress(
  billingAddress: ICustomerAddress,
  countries: ThisType<Pick<IConfig, "permitted_countries">>,
): string {
  const billingAddressContent: string[] = [];

  if (billingAddress.street && billingAddress.street.length > 0) {
    billingAddressContent.push(billingAddress.street);
  }
  if (billingAddress.street2 && billingAddress.street2.length > 0) {
    billingAddressContent.push(billingAddress.street2);
  }

  // zip + city
  const zipCity: string[] = [];
  if (billingAddress.zip && billingAddress.zip.length > 0) {
    zipCity.push(billingAddress.zip);
  }
  if (billingAddress.city && billingAddress.city.length > 0) {
    zipCity.push(billingAddress.city);
  }
  if (zipCity && zipCity.length > 0) {
    billingAddressContent.push(zipCity.join(" "));
  }

  if (billingAddress.region && billingAddress.region.length > 0) {
    // US state
    if (
      billingAddress.country === COUNTRY_US ||
      billingAddress.country === COUNTRY_US_CODE
    ) {
      const state = STATES_US.find(
        ({ value, name }) =>
          billingAddress.region === value || billingAddress.region === name,
      );
      billingAddressContent.push(state?.name || billingAddress.region);
    }
    // Other
    else {
      billingAddressContent.push(billingAddress.region);
    }
  }

  if (billingAddress.country && billingAddress.country.length > 0) {
    const countryKey = Object.keys(countries).find(
      (key) =>
        key === billingAddress.country ||
        countries[key] === billingAddress.country,
    );
    const countryName = countryKey
      ? countries[countryKey]
      : billingAddress.country;
    billingAddressContent.push(countryName);
  }

  return billingAddressContent.join(", ");
}
