import {
  FONT_FAMILY,
  FONT_FAMILY_SRC,
} from "@common/constants/theme-mantine/constants";

export const ElementsOptions = {
  fonts: [
    {
      cssSrc: FONT_FAMILY_SRC,
      family: FONT_FAMILY,
    },
  ],
};
