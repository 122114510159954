import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconInvoicing({
  color,
  size,
}: IIconProps): ReactElement {
  return (
    <svg
      height={size || 16}
      width={size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 1C3.05933 1 2.5 1.56 2.5 2.25V13.75C2.5 14.44 3.06 15 3.75 15H12.25C12.94 15 13.5 14.44 13.5 13.75V8.5C13.5 7.83696 13.2366 7.20107 12.7678 6.73223C12.2989 6.26339 11.663 6 11 6H9.75C9.41848 6 9.10054 5.8683 8.86612 5.63388C8.6317 5.39946 8.5 5.08152 8.5 4.75V3.5C8.5 2.83696 8.23661 2.20107 7.76777 1.73223C7.29893 1.26339 6.66304 1 6 1H3.75ZM5 10C5 9.86739 5.05268 9.74022 5.14645 9.64645C5.24021 9.55268 5.36739 9.5 5.5 9.5H10.5C10.6326 9.5 10.7598 9.55268 10.8536 9.64645C10.9473 9.74022 11 9.86739 11 10C11 10.1326 10.9473 10.2598 10.8536 10.3536C10.7598 10.4473 10.6326 10.5 10.5 10.5H5.5C5.36739 10.5 5.24021 10.4473 5.14645 10.3536C5.05268 10.2598 5 10.1326 5 10ZM5.5 11.5C5.36739 11.5 5.24021 11.5527 5.14645 11.6464C5.05268 11.7402 5 11.8674 5 12C5 12.1326 5.05268 12.2598 5.14645 12.3536C5.24021 12.4473 5.36739 12.5 5.5 12.5H8C8.13261 12.5 8.25979 12.4473 8.35355 12.3536C8.44732 12.2598 8.5 12.1326 8.5 12C8.5 11.8674 8.44732 11.7402 8.35355 11.6464C8.25979 11.5527 8.13261 11.5 8 11.5H5.5Z"
        fill={color || "#4C5773"}
      />
      <path
        d="M8.64746 1.21094C9.19847 1.84625 9.5013 2.6593 9.50013 3.50027V4.75027C9.50013 4.88827 9.61213 5.00027 9.75013 5.00027H11.0001C11.8411 4.9991 12.6542 5.30193 13.2895 5.85294C12.9962 4.73741 12.4118 3.7198 11.5962 2.9042C10.7806 2.08859 9.76299 1.50425 8.64746 1.21094Z"
        fill={color || "#4C5773"}
      />
    </svg>
  );
}
