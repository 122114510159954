import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconStar({ color, size }: IIconProps): ReactElement {
  const theSize = size || 16;

  return (
    <svg
      width={theSize}
      height={theSize}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31626 9.68374L0 8L6.31626 6.31626L8 0L9.68374 6.31626L16 8L9.68374 9.68374L8 16L6.31626 9.68374Z"
        fill={color || "#5A6072"}
      />
    </svg>
  );
}
