import { getColors } from "./utils";
import { MantineThemeCustomOverride } from "./types";

export const GOLDEN_RATIO = 1.61803398875;

export const FONT_FAMILY_SRC =
  "https://fonts.googleapis.com/css2?family=Lato:wght@400;500;700;900&display=swap";
export const FONT_FAMILY = "Lato, Helvetica Neue, Helvetica, Arial, sans-serif";

export const defaultTheme: MantineThemeCustomOverride = {
  colorScheme: "light",
  colors: {
    brand: [
      "#EEF6FD",
      "#E4ECFB",
      "#C2DAF0",
      "#90c1ec",
      "#509ee3",
      "#2d86d4",
      "#1c6bb0",
    ],
    gray: [
      "#FAFBFE",
      "#FCFCFD", // replaced "#f0f0f0"
      "#EEECEC", // replaced "#F1F2F4",
      "#DADADA",
      "#8D93A5",
      "#5A6072",
      "#434856",
      "#232C42",
      "#C6C9D2",
      "#74838f",
    ],
    green: ["#F9FCF6", "#84BB4C"],
    yellow: ["#FFFEFA", "#fef9e6", "#FDECB5", "#F9CB39"],
    orange: ["#EB8542"],
    purple: ["#5D60A2"],
    red: ["#FFF7F7", "#f9d2d2", "#ED6E6E", "#E84A4A", "#ec3939"],
  },
  primaryColor: "brand",

  other: {
    input: {
      height: 46,
    },
    animation: {
      duration: {
        interaction: 200,
        panel: 400,
      },
    },
    disabled: {
      opacity: 2 / 3,
    },
    health: {
      loading: "#F9CB39",
      // contract
      draft: "#F9CB39",
      new: "#F9CB39",
      ready: "#84BB4C",
      // subscription
      // trial, active, cancelled
      "cancellation-pending": "#F9CB39",
      "past-due": "#F9CB39",
      unpaid: "#ED6E6E",
      // instance health
      healthy: "#84BB4C",
      unhealthy: "#ED6E6E",
      provisioning: "#F9CB39",
      "waiting for dns": "#F9CB39",
      // instance status
      creating: "#F9CB39",
      suspending: "#F9CB39",
      running: "#84BB4C",
      unknown: "#F9CB39",
      dnsfail: "#ED6E6E",
      "updating-version": "#F9CB39",
      starting: "#F9CB39",
      stopping: "#F9CB39",
      stopped: "#ED6E6E",
      deleting: "#F9CB39",
      deleted: "#74838f",
      cancelled: "#ED6E6E",
      canceled: "#ED6E6E",
      "delete-failed": "#ED6E6E",
      snapshot: "#F9CB39",
      "change-region": "#F9CB39",
      "changing-subscription-plan": "#F9CB39",
      restore: "#F9CB39",
      error: "#ec3939",
      suspended: "#74838f",
      // migration status
      initialized: "#F9CB39",
      "snapshot-uploaded": "#F9CB39",
      migrating: "#F9CB39",
      "migration-failed": "#ED6E6E",
      completed: "#84BB4C",
      // action result code
      ok: "#84BB4C",
      "timed out": "#F9CB39",
      "timed-out": "#F9CB39",
      failed: "#ED6E6E",
      // maintenance
      true: "#84BB4C",
      false: "#ED6E6E",
      // organisation & user status
      active: "#84BB4C",
      enabled: "#84BB4C",
      inactive: "#ED6E6E",
      disabled: "#C6C9D2",
      "gdpr-deleted": "#74838f",
      // quote status
      created: "#C6C9D2",
      accepted: "#F9CB39",
      signed: "#84BB4C",
      rejected: "#ED6E6E",
      // token state
      trial: "#84BB4C",
      valid: "#84BB4C",
      expired: "#ED6E6E",
      "expired-trial": "#ED6E6E",
    },
  },

  fontFamily: FONT_FAMILY,
  fontFamilyMonospace: `"Courier New",Courier,"Lucida Sans Typewriter","Lucida Typewriter",monospace`,

  headings: {
    fontFamily: FONT_FAMILY,
    fontWeight: 700,
  },

  spacing: { xs: 8 },

  globalStyles(theme) {
    return {
      body: {
        color: theme.colors.gray[5],
        minHeight: "100%",
        position: "relative",
      },

      form: {
        position: "relative",
      },

      button: {
        fontFamily: theme.fontFamily,
      },

      img: {
        maxWidth: "100%",
        verticalAlign: "bottom",
      },

      svg: {
        maxWidth: "100%",
        verticalAlign: "bottom",
      },

      a: {
        color: theme.colors.brand[4],
      },

      /* Stripe */
      "#stripe-form": {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
        minHeight: theme.other.input.height,
      },

      ".StripeElement": {
        width: "100%",
      },

      /* Next app */
      "#__next": {
        margin: 0,
        minHeight: "100%",
        padding: 0,
        position: "relative",
        width: "100%",
      },

      ".react-calendar": {
        border: "none",
      },

      /* Change Autocomplete styles in Chrome */
      "input, textarea, select": {
        "&:-webkit-autofill": {
          WebkitTextFillColor: "fieldtext",
          border: `1px solid ${theme.colors.gray[3]}`,
          transition: `background-color 5000s ease-in-out 0s`,

          "&:hover, &:focus": {
            WebkitTextFillColor: "fieldtext",
            border: `1px solid ${theme.colors.brand[4]}`,
            transition: `background-color 5000s ease-in-out 0s`,
          },
        },
      },
    };
  },

  components: {
    AppShell: {
      styles(theme) {
        return {
          root: {
            backgroundColor: theme.colors.gray[0],
          },
          main: {
            backgroundColor: theme.colors.gray[0],
          },
        };
      },
    },
    Header: {
      styles(theme) {
        return {
          root: {
            borderBottom: `1px solid ${theme.colors.gray[2]}`,
          },
        };
      },
    },
    Navbar: {
      styles(theme) {
        return {
          root: {
            backgroundColor: theme.colors.gray[1],
            border: "none",
          },
        };
      },
    },
    NavLink: {
      styles(theme, options) {
        return {
          root: {
            backgroundColor: "transparent",
            border: "none",
            borderRadius: theme.radius.md,
            color: options.active ? "white" : "inherit",
            fontSize: theme.fontSizes.xs,
            fontWeight: 700,
            padding: (theme.spacing.md + theme.spacing.xs) / 2,
            transition: `background-color ${theme.other.animation.duration.interaction}ms, color ${theme.other.animation.duration.interaction}ms`,
            width: "auto",

            "svg path": {
              transition: `fill ${theme.other.animation.duration.interaction}ms`,
            },

            "&:hover": {
              backgroundColor: theme.colors.brand[1],
              textDecoration: "none",
            },

            "&[data-active]": {
              backgroundColor: theme.colors.brand[4],
              color: "white",

              "svg path": {
                fill: "white",
              },

              "&:hover": {
                backgroundColor: theme.colors.brand[5],
                textDecoration: "none",
              },
            },
          },

          icon: {
            color: "red",
            fill: "red",
          },
        };
      },
    },

    Stepper: {
      styles(theme) {
        return {
          step: {
            color: "inherit",

            "&:disabled": {
              cursor: "default",
              opacity: theme.other.disabled.opacity,
              pointerEvents: "none",
            },
          },

          stepIcon: {
            backgroundColor: theme.colors.gray[2],
            color: theme.colors.gray[4],

            "&[data-progress]": {
              backgroundColor: "white",
              border: `1px solid ${theme.colors.brand[4]}`,
              color: theme.colors.brand[4],
            },

            "&[data-completed]": {
              backgroundColor: theme.colors.brand[4],
              borderColor: theme.colors.brand[4],

              "&:hover": {
                backgroundColor: theme.colors.brand[5],
                borderColor: theme.colors.brand[5],
              },
            },

            "&:hover": {
              borderColor: theme.colors.brand[5],
              color: theme.colors.brand[5],
            },
          },

          stepLabel: {
            fontWeight: 700,
          },

          separator: {
            backgroundColor: theme.colors.brand[5],
            height: 1,
          },
        };
      },
    },

    Container: {
      styles(theme) {
        return {
          root: {
            marginLeft: 0,
            padding: `${2 * theme.spacing.xs}px ${2 * theme.spacing.xs}px`,

            [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
              padding: `${2 * theme.spacing.sm}px ${2 * theme.spacing.sm}px`,
            },

            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
              paddingBottom: `${2 * theme.spacing.md}px`,
              paddingLeft: `${2 * theme.spacing.md}px`,
              paddingRight: `${2 * theme.spacing.md}px`,
            },

            [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
              paddingBottom: `${2 * theme.spacing.lg}px`,
              paddingLeft: `${2 * theme.spacing.lg}px`,
              paddingRight: `${2 * theme.spacing.lg}px`,
            },

            [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
              paddingBottom: `${2 * theme.spacing.xl}px`,
              paddingLeft: `${2 * theme.spacing.xl}px`,
              paddingRight: `${2 * theme.spacing.xl}px`,
            },
          },
        };
      },
    },

    Card: {
      styles(theme) {
        return {
          root: {
            borderColor: theme.colors.gray[2],
            borderRadius: theme.radius.md,
            color: "inherit",
          },
        };
      },
    },

    List: {
      styles(theme) {
        return {
          item: {
            "&::marker": {
              color: theme.colors.brand[4],
            },
          },
        };
      },
    },

    Table: {
      styles(theme) {
        return {
          root: {
            borderColor: "green", // not working?

            "thead tr th": {
              borderColor: theme.colors.gray[2],
            },

            "tbody tr td": {
              borderColor: theme.colors.gray[2],
            },

            "&[data-hover] tbody tr:hover": {
              backgroundColor: theme.colors.gray[1],
            },
          },
        };
      },
    },

    Accordion: {
      styles(theme) {
        return {
          item: {
            backgroundColor: "white",
            border: `1px solid ${theme.colors.gray[2]}`,
            borderTop: "none",
            color: "inherit",
            maxWidth: theme.breakpoints.sm,
            padding: `${theme.spacing.md}px ${theme.spacing.xl}px`,

            "&:first-of-type": {
              borderTop: `1px solid ${theme.colors.gray[2]}`,
              borderTopLeftRadius: theme.radius.md,
              borderTopRightRadius: theme.radius.md,
            },

            "&:last-child": {
              borderBottomLeftRadius: theme.radius.md,
              borderBottomRightRadius: theme.radius.md,
            },

            "&[data-active]": {
              backgroundColor: "white",
            },
          },

          control: {
            alignItems: "flex-start",
            color: "inherit",
            padding: 0,

            "&:hover": {
              backgroundColor: "transparent",
            },
          },

          chevron: {
            width: "auto",

            "&:empty": {
              display: "none",
            },
          },

          label: {
            width: "100%",
          },

          content: {
            padding: `${theme.spacing.md}px 0 ${theme.spacing.xs / 2}px 0`,
            position: "relative",

            "&::after": {
              clear: "both",
              content: `""`,
              display: "table",
            },
          },
        };
      },
    },

    Tabs: {
      styles(theme) {
        return {
          tab: {
            "&[data-active]": {
              "&::before": {
                backgroundColor: theme.colors.gray[0],
              },
            },
          },
        };
      },
    },

    Code: {
      styles(theme, { color }) {
        return {
          root: {
            backgroundColor:
              color === "dark" ? theme.colors.gray[7] : theme.colors.gray[1],
            border:
              color === "dark" ? "none" : `1px solid ${theme.colors.brand[4]}`,
            borderRadius: theme.radius.md,
            color: color === "dark" ? "white" : theme.colors.brand[4],
            fontSize: theme.fontSizes.xs,
            fontWeight: 700,
            padding: `${(theme.spacing.xs + theme.spacing.sm) / 2}px ${
              theme.spacing.sm
            }px`,
          },

          block: {
            padding: `${(theme.spacing.xs + theme.spacing.sm) / 2}px ${
              theme.spacing.sm
            }px`,
          },
        };
      },
    },

    Image: {
      styles: {
        image: {
          margin: "0 auto",
          maxWidth: "100%",
        },
      },
    },

    Title: {
      styles(theme) {
        return {
          root: {
            color: theme.colors.gray[7],
            fontStyle: "normal",
            marginBottom: theme.spacing.xl,

            "&:last-child": {
              marginBottom: 0,
            },

            a: {
              color: theme.colors.gray[6],
              textDecoration: "none",

              "&:hover": {
                color: theme.colors.gray[7],
                textDecoration: "underline",
              },
            },
          },
        };
      },
    },

    Text: {
      styles(theme) {
        return {
          root: {
            color: "inherit",

            a: {
              color: theme.colors.brand[4],
              fontWeight: 700,
              textDecoration: "none",
              whiteSpace: "nowrap",

              "&:hover": {
                color: theme.colors.brand[5],
                textDecoration: "none",
              },
            },
          },
        };
      },
    },

    Anchor: {
      styles(theme) {
        return {
          root: {
            color: theme.colors.brand[4],
          },
        };
      },
    },

    Badge: {
      styles(theme) {
        return {
          root: {
            color: "white",
            padding: `${theme.spacing.xs / 2}px`,
          },
        };
      },
    },

    Button: {
      styles(theme, { color, variant }) {
        return {
          root: {
            backgroundColor:
              variant === "outline" ? "white" : getColors({ theme, color }),
            border:
              variant === "outline"
                ? `1px solid ${getColors({ theme, color })}`
                : "none",
            borderRadius: theme.radius.md,
            color:
              variant === "outline" ? getColors({ theme, color }) : "white",
            fontSize: theme.fontSizes.sm,
            fontWeight: 900,
            height: 2 * theme.spacing.lg,
            opacity: 1,
            outline: "none",
            padding: `${theme.spacing.xs}px ${
              (theme.spacing.sm + theme.spacing.md) / 2
            }px`,
            transition: `background-color ${theme.other.animation.duration.interaction}ms, border-color ${theme.other.animation.duration.interaction}ms, color ${theme.other.animation.duration.interaction}ms, opacity ${theme.other.animation.duration.interaction}ms`,
            userSelect: "none",

            "&:hover, &:focus": {
              backgroundColor:
                variant === "outline"
                  ? "white"
                  : getColors({ theme, color, hover: true }),
              border:
                variant === "outline"
                  ? `1px solid ${getColors({ theme, color, hover: true })}`
                  : "none",
              color:
                variant === "outline"
                  ? getColors({ theme, color, hover: true })
                  : "white",
              outline: "none",

              ".mantine-Button-icon svg path": {
                fill: getColors({ theme, color, hover: true }),
              },
            },

            "&:disabled": {
              backgroundColor:
                variant === "outline"
                  ? "white"
                  : getColors({ theme, color, disabled: true }),
              border:
                variant === "outline"
                  ? `1px solid ${getColors({
                      theme,
                      color,
                      outline: true,
                      disabled: true,
                    })}`
                  : "none",
              color:
                variant === "outline"
                  ? getColors({ theme, color, outline: true, disabled: true })
                  : "white",
              opacity: variant === "outline" ? 1 : theme.other.disabled.opacity,

              ".mantine-Button-icon svg path": {
                fill: getColors({
                  theme,
                  color,
                  outline: true,
                  disabled: true,
                }),
              },
            },

            "&[data-disabled]": {
              backgroundColor:
                variant === "outline"
                  ? "white"
                  : getColors({ theme, color, disabled: true }),
              border:
                variant === "outline"
                  ? `1px solid ${getColors({
                      theme,
                      color,
                      outline: true,
                      disabled: true,
                    })}`
                  : "none",
              color:
                variant === "outline"
                  ? getColors({ theme, color, outline: true, disabled: true })
                  : "white",
              opacity: variant === "outline" ? 1 : theme.other.disabled.opacity,

              ".mantine-Button-icon svg path": {
                fill: getColors({
                  theme,
                  color,
                  outline: true,
                  disabled: true,
                }),
              },
            },
          },

          inner: {
            margin: 0,
            transform: "none",
          },

          icon: {
            "svg path": {
              transition: `fill ${theme.other.animation.duration.interaction}ms`,
            },
          },

          leftIcon: {
            marginRight: theme.spacing.xs,
          },

          rightIcon: {
            marginLeft: theme.spacing.xs,
          },
        };
      },
    },

    InputWrapper: {
      styles(theme) {
        return {
          label: {
            color: theme.colors.gray[5],
            fontSize: theme.fontSizes.sm,
            fontWeight: 700,
            marginBottom: theme.spacing.xs / 2,
          },

          description: {
            fontSize: theme.fontSizes.sm,
            marginBottom: theme.spacing.xs,
          },

          required: {
            color: theme.colors.red[3],
          },
        };
      },
    },

    Input: {
      styles(theme) {
        return {
          input: {
            border: `1px solid ${theme.colors.gray[2]}`,
            borderRadius: theme.radius.md,
            fontSize: theme.fontSizes.sm,
            lineHeight: 1.5714,
            fontWeight: 400,
            height: theme.other.input.height,
            padding: theme.spacing.sm,

            "&::placeholder": {
              color: theme.colors.gray[4],
            },
          },
        };
      },
    },

    NativeSelect: {
      styles() {
        return {
          input: {
            backgroundImage: "none", // TEMP
          },
        };
      },
    },

    Select: {
      styles(theme) {
        return {
          dropdown: {
            borderColor: theme.fn.lighten(theme.colors.gray[4], 0.55),
            overflow: "hidden",
          },

          itemsWrapper: {
            padding: 0,
          },

          item: {
            borderRadius: 0,
            color: theme.colors.gray[7],

            "&:hover": {
              backgroundColor: theme.colors.gray[2],
            },

            "&[data-selected]": {
              backgroundColor: theme.colors.gray[2],
              color: theme.colors.gray[7],
            },
          },
        };
      },
    },

    MultiSelect: {
      styles(theme) {
        return {
          input: {
            height: "auto",
            minHeight: theme.other.input.height,
            paddingBottom: theme.spacing.xs / 2,
            paddingTop: theme.spacing.xs / 2,
          },
        };
      },
    },

    Checkbox: {
      styles(theme) {
        return {
          root: {
            cursor: "pointer",
            userSelect: "none",
          },

          body: {
            alignItems: "center",
            cursor: "inherit",
          },

          inner: {
            cursor: "inherit",
          },

          input: {
            backgroundColor: theme.colors.gray[1],
            borderColor: theme.colors.gray[4],
            cursor: "inherit",

            "&:checked": {
              backgroundColor: theme.colors.brand[4],
              borderColor: "transparent",
              border: "none",
            },

            "&:disabled": {
              cursor: "default",
              opacity: theme.other.disabled.opacity,
              pointerEvents: "none",
              userSelect: "none",
            },
          },

          labelWrapper: {
            cursor: "inherit",
          },

          label: {
            cursor: "inherit",
            color: theme.colors.gray[5],
            fontSize: theme.fontSizes.sm,
            paddingLeft: theme.spacing.xs,

            "&[data-disabled]": {
              cursor: "default",
              opacity: theme.other.disabled.opacity,
              pointerEvents: "none",
              userSelect: "none",
            },
          },
        };
      },
    },

    Textarea: {
      styles() {
        return { input: { minHeight: 100 } };
      },
    },

    Switch: {
      styles(theme) {
        return {
          root: {
            cursor: "pointer",
            height: 20,
            userSelect: "none",
          },

          body: {
            cursor: "inherit",
          },

          track: {
            cursor: "inherit",
            backgroundColor: theme.fn.darken(theme.colors.gray[2], 0.15),
            border: "none",
          },

          thumb: {
            border: "none",
          },

          labelWrapper: {
            color: "inherit",
            cursor: "inherit",
          },

          label: {
            color: "inherit",
            cursor: "inherit",
          },
        };
      },
    },

    Slider: {
      styles(theme) {
        return {
          root: {
            minHeight: theme.other.input.height,
          },
        };
      },
    },

    Tooltip: {
      styles(theme, { color }) {
        return {
          tooltip: {
            backgroundColor:
              color === "dark"
                ? "black"
                : color === "brand"
                ? theme.colors.brand[4]
                : "white",
            border: `1px solid ${
              color === "dark" ? "black" : theme.colors.gray[2]
            }`,
            borderRadius: (theme.radius.sm + theme.radius.md) / 2,
            boxShadow: theme.shadows.lg,
            color:
              color === "dark" || color === "brand"
                ? "white"
                : theme.colors.gray[5],
          },

          arrow: {
            border: `1px solid ${
              color === "dark" ? "black" : theme.colors.gray[2]
            }`,
          },
        };
      },
    },

    Modal: {
      styles(theme) {
        return {
          inner: {
            overflow: "hidden",
          },

          header: {
            marginBottom: theme.spacing.xl,
          },
        };
      },
    },

    Notification: {
      styles(theme) {
        return {
          title: {
            fontSize: theme.fontSizes.md,
            fontWeight: 700,
          },

          description: {
            fontSize: theme.fontSizes.sm,
            fontWeight: 400,
          },
        };
      },
    },
  },
};
