import { COUNTRIES_WITH_COOKIE_POLICY_RESTRICTION } from "./constants";
import { IGeoLocation } from "./types";

export function hasCookiePolicyRestriction(geoLocation: IGeoLocation): boolean {
  const countryObj = COUNTRIES_WITH_COOKIE_POLICY_RESTRICTION.find(
    (countryObj) =>
      countryObj.country === geoLocation.country ||
      countryObj.country_3 === geoLocation.country_3,
  );

  return !!countryObj;
}
