import Visit from "@common/components/icons/Visit";
import { capitalize } from "@common/utils/string";
import { Badge, Table, Text } from "@mantine/core";
import { formatDate } from "@utils/date";
import { ReactElement } from "react";
import { formatAmount } from "./utils";
import { IInvoicesListProps } from "./types";
import { INVOICE_STATUS_TYPE_PAID } from "@stores/billing/constants";

export default function InvoicesList({
  invoices,
}: IInvoicesListProps): ReactElement {
  return (
    <Table highlightOnHover>
      <thead>
        <tr>
          <th>Date</th>
          <th>Total</th>
          <th>Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {invoices.map(
          ({ status, created, amount_due, hosted_invoice_url }, index) => (
            <tr key={index}>
              <td>{formatDate(created, "default")}</td>
              <td>
                {amount_due > 0 ? `$${formatAmount(amount_due)}` : "No charge"}
              </td>
              <td>
                <Badge
                  bg={
                    status === INVOICE_STATUS_TYPE_PAID ? "green.1" : "yellow.3"
                  }
                >
                  {capitalize(status)}
                </Badge>
              </td>
              <td>
                {hosted_invoice_url ? (
                  <a href={hosted_invoice_url} target="_blank" rel="noreferrer">
                    <Visit />
                  </a>
                ) : (
                  <Text fz="sm" mr="xs">
                    not avail.
                  </Text>
                )}
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  );
}
