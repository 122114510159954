import { CSSProperties, MouseEventHandler } from "react";
import { IGetColorsProps } from "./types";
import { MantineTheme, useMantineTheme } from "@mantine/core";

export function getColor(
  theme: MantineTheme,
  color?: string,
  fallbackColor: string = theme.colors.gray[7],
): string {
  if (color && color.length > 0) {
    if (color[0] === "#") {
      return color;
    } else if (color.includes("[") && color.includes("]")) {
      const colorSplit = color.split("[");
      const colorType = colorSplit[0];
      const colorIndex = parseInt(colorSplit[1].split("]")[0]);
      return theme.colors[colorType][colorIndex];
    } else {
      console.warn(`getColor, color not found: ${color}`);
    }
  }

  return fallbackColor;
}

export function getColors({
  theme,
  color,
  outline,
  hover,
  disabled,
}: IGetColorsProps): string {
  if (outline && disabled) {
    return theme.colors.gray[8];
  }

  if (color === "red") {
    return hover ? theme.colors.red[3] : theme.colors.red[2];
  }

  return hover ? theme.colors.brand[5] : theme.colors.brand[4];
}

export function ClearFixMixin(): Record<string, any> {
  return {
    "&::after": {
      clear: "both",
      content: '""',
      display: "table",
    },
  };
}

export function DisabledMixin(
  isDisabled: boolean | undefined,
): Record<string, any> {
  const theme = useMantineTheme();

  return {
    cursor: isDisabled ? "default" : "auto",
    opacity: isDisabled ? theme.other.disabled.opacity : 1,
    pointerEvents: isDisabled ? "none" : "auto",
    userSelect: isDisabled ? "none" : "auto",
  };
}

export function WordBreakMixin(
  wordBreak: boolean | undefined,
): Record<string, any> {
  return wordBreak
    ? {
        wordBreak: "break-all",
        wordWrap: "break-word",
      }
    : {};
}

export function EllipsisMixin(
  ellipsisWidth?: number,
  wordBreak = false,
): Record<string, any> {
  const ellipsisWidthAbs = ellipsisWidth ? Math.abs(ellipsisWidth) : undefined;
  const ellipsisDirection =
    typeof ellipsisWidth === "number" && ellipsisWidth < 0
      ? {
          direction: "rtl",
          textAlign: "left",
        }
      : {};

  return ellipsisWidthAbs
    ? {
        ...{
          display: "inline-block",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace:
            !ellipsisWidthAbs && !wordBreak ? "break-spaces" : "nowrap",
          maxWidth: `${ellipsisWidthAbs}px`,
          verticalAlign: "bottom",
        },
        ...ellipsisDirection,
      }
    : {
        whiteSpace: !wordBreak ? "break-spaces" : undefined,
      };
}

interface IGetPropertiesForDisabledComponentWithTooltipRes {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  style: CSSProperties;
}

export function getPropertiesForDisabledComponentWithTooltip(
  disabled: boolean,
): IGetPropertiesForDisabledComponentWithTooltipRes | null {
  return disabled
    ? {
        onClick(event) {
          return event.preventDefault();
        },
        style: { pointerEvents: "all" },
      }
    : null;
}
