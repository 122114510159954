import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconGear({ color, size }: IIconProps): ReactElement {
  return (
    <svg
      height={size || 16}
      width={size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.04425 0C6.49196 0 6.04425 0.447715 6.04425 1V1.6688C6.04425 1.97094 5.90764 2.25689 5.67258 2.44674L4.93642 3.04133C4.6286 3.28996 4.20312 3.3337 3.85115 3.15289L2.72522 2.57451C2.25655 2.33376 1.68146 2.49797 1.41058 2.9499L0.329938 4.75276C0.0447989 5.22847 0.201268 5.84536 0.678668 6.12765L1.52454 6.62782C1.82886 6.80778 2.01555 7.13505 2.01555 7.4886V8.61704C2.01555 8.95574 1.8441 9.27142 1.56 9.45583L0.711647 10.0065C0.256962 10.3016 0.120215 10.9053 0.403328 11.3676L1.48919 13.1406C1.77922 13.6141 2.39957 13.7607 2.87086 13.467L3.74217 12.924C4.08401 12.7109 4.52028 12.7238 4.84899 12.9566L5.62216 13.5041C5.8869 13.6915 6.04425 13.9958 6.04425 14.3202V15C6.04425 15.5523 6.49196 16 7.04425 16H9.14407C9.69636 16 10.1441 15.5523 10.1441 15V14.3386C10.1441 14.004 10.3114 13.6916 10.5899 13.5062L11.4835 12.9113C11.7961 12.7033 12.1989 12.6878 12.5265 12.8714L13.2242 13.2623C13.6655 13.5096 14.2219 13.3884 14.5204 12.98L15.6306 11.4608C15.9468 11.0282 15.8657 10.4232 15.4468 10.0891L14.6588 9.46039C14.4209 9.27065 14.2824 8.98293 14.2824 8.67866V7.39636C14.2824 7.06357 14.4479 6.75258 14.724 6.56677L15.389 6.11917C15.8315 5.82138 15.9624 5.22879 15.6865 4.77235L14.6387 3.03851C14.3741 2.6006 13.8215 2.4331 13.3583 2.65032L12.3813 3.10843C12.0774 3.25092 11.7223 3.23146 11.4358 3.0566L10.6231 2.56058C10.3256 2.37899 10.1441 2.05557 10.1441 1.707V1C10.1441 0.447715 9.69636 0 9.14408 0H7.04425ZM11.0149 8C11.0149 9.65685 9.66557 11 8.00113 11C6.33669 11 4.98739 9.65685 4.98739 8C4.98739 6.34315 6.33669 5 8.00113 5C9.66557 5 11.0149 6.34315 11.0149 8Z"
        fill={color || "#5A6072"}
      />
    </svg>
  );
}
