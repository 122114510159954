import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  root: {
    appearance: "none",
    backgroundColor: "transparent",
    border: "none",
    color: theme.colors.brand[4],
    cursor: "pointer",
    display: "inline",
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    height: "auto",
    lineHeight: 1.333333,
    margin: 0,
    padding: 0,
    transition: `opacity ${theme.other.animation.duration.interaction}ms`,

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.fn.darken(theme.colors.brand[4], 0.1),
    },

    "&:focus": {
      backgroundColor: "transparent",
      color: theme.fn.darken(theme.colors.brand[4], 0.1),
    },

    "&:disabled": {
      backgroundColor: "transparent",
      color: theme.fn.darken(theme.colors.brand[4], 0.1),
      cursor: "default",
      opacity: theme.other.disabled.opacity,
    },

    "&:[data-disabled]": {
      backgroundColor: "transparent",
      color: theme.fn.darken(theme.colors.brand[4], 0.1),
      cursor: "default",
      opacity: theme.other.disabled.opacity,
    },
  },
}));
