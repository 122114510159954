import { createStyles } from "@mantine/core";
import { IForm } from "./types";
import {
  ClearFixMixin,
  DisabledMixin,
} from "@common/constants/theme-mantine/utils";

export const useStyles = createStyles((theme, { disabled, mt }: IForm) => ({
  form: {
    ...ClearFixMixin(),
    ...DisabledMixin(disabled),
    marginTop: typeof mt === "number" || typeof mt === "string" ? `${mt}` : 0,
    position: "relative",
    width: "100%",
  },
}));
