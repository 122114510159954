import {
  IStripeWithPaymentMethod,
  IStripePaymentMethod,
  IStripeSetupIntentRes,
  PaymentMethodsStore,
  IStripeSetupCardRes,
  IStripeWithPaymentMethodOnly,
} from "./types";
import { dataFetcher } from "@utils/data";
import create from "zustand";
import { generateBEAPIUrl } from "@utils/api";
import { IDataFetcherResponse } from "@common/utils/use-query/types";
import {
  PaymentMethodCreateParams,
  Stripe,
  StripeCardElement,
} from "@stripe/stripe-js";
import { DEFAULT_ERROR_MESSAGE } from "@common/utils/use-query/constants";
import { captureException } from "@sentry/nextjs";

export const usePaymentMethodsStore = create<PaymentMethodsStore>(
  (setState, getState): PaymentMethodsStore => ({
    loading: false,
    error: null,

    async createStripeSetupIntent(): Promise<
      IDataFetcherResponse<IStripeSetupIntentRes>
    > {
      setState({ loading: true });

      const { success, error, data } = await dataFetcher(
        generateBEAPIUrl("/account/create_setup_intent"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: {},
        },
      );

      if (success) {
        setState({
          error: null,
          loading: false,
        });
      } else {
        setState({
          error,
          loading: false,
        });
      }

      return {
        success,
        error,
        data,
      };
    },

    async confirmStripeCardSetup(
      card: StripeCardElement,
      stripe: Stripe,
      client_secret: string,
      billing_details: PaymentMethodCreateParams.BillingDetails,
    ): Promise<IDataFetcherResponse<IStripeSetupCardRes>> {
      try {
        const { error, setupIntent } = await stripe.confirmCardSetup(
          client_secret,
          {
            payment_method: {
              card,
              billing_details,
            },
          },
        );

        // error
        if (error) {
          const message =
            error.code !== "incomplete_number"
              ? error.message ||
                error.code ||
                error.decline_code ||
                DEFAULT_ERROR_MESSAGE
              : null;
          const status = message ? 500 : null;
          const theError =
            status && message
              ? {
                  status,
                  message,
                }
              : null;

          setState({
            error: theError,
            loading: false,
          });

          return {
            error: theError,
            success: false,
          };
          // success
        } else {
          setState({
            error: null,
            loading: false,
          });

          return {
            error: null,
            success: true,
            data: { setupIntent },
          };
        }
      } catch (error: any) {
        setState({
          error,
          loading: false,
        });

        captureException(error);

        return {
          error,
          success: false,
        };
      }
    },

    async createPaymentMethod(
      card: StripeCardElement,
      stripe: Stripe,
      billing_details: PaymentMethodCreateParams.BillingDetails,
    ): Promise<IDataFetcherResponse> {
      // 1. get client secret
      const createStripeSetupIntent = getState().createStripeSetupIntent;
      const { data } = await createStripeSetupIntent();
      const { client_secret } = data || {};
      if (!client_secret) {
        return {
          error: {
            status: 500,
            message: "Missing Stripe client_secret",
          },
          success: false,
        };
      }

      // format data
      const { name, email, ...billingDetailsRest } = billing_details;

      // 2. confirm card
      const confirmStripeCardSetup = getState().confirmStripeCardSetup;
      const {
        error,
        success,
        data: stripeCardSetupRes,
      } = await confirmStripeCardSetup(card, stripe, client_secret, {
        name,
        email,
      });

      // 3. update customer PM
      if (success && stripeCardSetupRes?.setupIntent) {
        const updatePaymentMethod = getState().updatePaymentMethod;
        return await updatePaymentMethod({
          ...(billingDetailsRest.address || {}),
          stripe_payment_method: String(
            stripeCardSetupRes.setupIntent.payment_method,
          ),
        });
      }

      return {
        error: error || {
          status: 500,
          message: DEFAULT_ERROR_MESSAGE,
        },
        success: false,
      };
    },

    defaultPaymentMethod: null,
    paymentMethods: null,

    async getPaymentMethods(): Promise<
      IDataFetcherResponse<IStripePaymentMethod[]>
    > {
      setState({ loading: true });

      const {
        success,
        error,
        data: paymentMethods,
      } = await dataFetcher(generateBEAPIUrl("/account/payment_methods"));

      if (success) {
        const defaultPaymentMethod: IStripePaymentMethod =
          paymentMethods.find(
            (paymentMethod: IStripePaymentMethod) => paymentMethod.default,
          ) || null;

        setState({
          error: null,
          loading: false,
          paymentMethods,
          defaultPaymentMethod,
        });
      } else {
        setState({
          error,
          loading: false,
          paymentMethods: null,
          defaultPaymentMethod: null,
        });
      }

      return {
        success,
        error,
        data: paymentMethods,
      };
    },

    clearPaymentMethods(): void {
      setState({
        error: null,
        loading: false,
        paymentMethods: null,
        defaultPaymentMethod: null,
      });
    },

    paymentMethod: null,

    // TEMP
    // as there is no endpoint
    // to retrieve a single payment method
    async getPaymentMethod(cardId: string): Promise<IDataFetcherResponse> {
      setState({ loading: true });

      let paymentMethod: IStripePaymentMethod | null = null;

      let paymentMethods = getState().paymentMethods;
      if (paymentMethods) {
        paymentMethod = paymentMethods?.find(({ id }) => id === cardId) || null;
      }

      // fetch all payment methods
      if (!paymentMethod) {
        const getPaymentMethods = getState().getPaymentMethods;
        await getPaymentMethods();
      }

      // filter thru all payment methods
      setState({ loading: true });
      paymentMethods = getState().paymentMethods;
      paymentMethod = paymentMethods?.find(({ id }) => id === cardId) || null;

      // result
      const success = !!paymentMethod;
      const error = paymentMethod
        ? null
        : { message: "Payment method not found", status: 404 };

      setState({
        error,
        loading: false,
        paymentMethods,
        paymentMethod,
      });

      return {
        success,
        error,
      };
    },

    async updatePaymentMethod(
      paymentMethod: IStripeWithPaymentMethodOnly | IStripeWithPaymentMethod,
    ): Promise<IDataFetcherResponse> {
      setState({ loading: true });

      let { success, error } = await dataFetcher(
        generateBEAPIUrl("/account/payment_methods/new"),
        {
          method: "POST",
          body: paymentMethod,
        },
      );

      if (success) {
        const getPaymentMethods = getState().getPaymentMethods;
        await getPaymentMethods();
      } else {
        if (error?.status === 500) {
          error = { message: "Invalid credit card details", status: 500 };
        }

        setState({
          error,
          loading: false,
        });
      }

      return {
        success,
        error,
      };
    },

    async deletePaymentMethod(cardId: string): Promise<IDataFetcherResponse> {
      setState({ loading: true });

      const url = `/account/payment_methods/${cardId}`;
      const { success, error } = await dataFetcher(generateBEAPIUrl(url), {
        method: "DELETE",
      });

      if (success) {
        // fetch all payment methods
        const getPaymentMethods = getState().getPaymentMethods;
        await getPaymentMethods();
      } else {
        setState({
          error,
          loading: false,
        });
      }

      return {
        success,
        error,
      };
    },
  }),
);
