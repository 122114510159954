import { ICancellationReasonsUI } from "./types";

export const HOSTING_FEATURE_TRIAL_UP = "trial_up";
export const HOSTING_FEATURE_CUSTOM_DOMAIN = "custom_domain";
export const HOSTING_FEATURE_INVOICING_ELIGIBILITY = "invoicing_eligibility";
export const HOSTING_FEATURE_INVOICEABLE = "invoiceable";
export const HOSTING_FEATURE_CLICKHOUSE_DWH = "clickhouse-dwh";

export const TRIAL_UP_ACTIVE = "active";
const TRIAL_UP_CANCELLED = "cancelled";
export const TRIAL_UP_EXPIRED = "expired";
const TRIAL_UP_CONVERTED = "converted";
export const TRIAL_UPS_STATUSES = [
  TRIAL_UP_ACTIVE,
  TRIAL_UP_CANCELLED,
  TRIAL_UP_EXPIRED,
  TRIAL_UP_CONVERTED,
] as const;

const CANCELLATION_REASON_DATA_SOURCE = "data-source";
const CANCELLATION_REASON_MISSING_FUNCTIONALITY = "missing-functionality";
const CANCELLATION_REASON_EXPENSIVE = "expensive";
const CANCELLATION_REASON_BUGS_OR_PERFORMANCE = "bugs-or-performance";
const CANCELLATION_REASON_TIMING = "timing";
const CANCELLATION_REASON_SOMETHING_ELSE = "something-else";

export const CANCELLATION_REASONS = [
  CANCELLATION_REASON_DATA_SOURCE,
  CANCELLATION_REASON_MISSING_FUNCTIONALITY,
  CANCELLATION_REASON_EXPENSIVE,
  CANCELLATION_REASON_BUGS_OR_PERFORMANCE,
  CANCELLATION_REASON_TIMING,
  CANCELLATION_REASON_SOMETHING_ELSE,
] as const;

export const CANCELLATION_REASONS_UI: ICancellationReasonsUI[] = [
  {
    id: CANCELLATION_REASON_DATA_SOURCE,
    label: "Can’t connect to data source",
    prompt:
      "We’re sorry to hear that. What data source are you trying to connect to?",
  },
  {
    id: CANCELLATION_REASON_MISSING_FUNCTIONALITY,
    label: "Missing functionality",
    prompt:
      "We’re sorry to hear that. What functionality where you looking for?",
  },
  {
    id: CANCELLATION_REASON_EXPENSIVE,
    label: "Too expensive",
    prompt:
      "We’re sorry to hear that. What do you think would be a fair price?",
  },
  {
    id: CANCELLATION_REASON_BUGS_OR_PERFORMANCE,
    label: "Bugs or performance issues",
    prompt:
      "We’re sorry to hear that. What bugs or performance issues did you run into?",
  },
  {
    id: CANCELLATION_REASON_TIMING,
    label: "Timing",
    prompt:
      "We’re sorry to hear that. What made this the wrong timing for you?",
  },
  {
    id: CANCELLATION_REASON_SOMETHING_ELSE,
    label: "Something else",
    prompt:
      "We’re sorry to hear that. Can you tell us more? What you share really goes into improving the product.",
  },
];
