import Script from "next/script";
import { ReactElement, useEffect, useState } from "react";

export default function Hotjar(): ReactElement {
  // add hotjar only once
  const [added, setAdded] = useState(false);

  useEffect(() => {
    if (window.hj) {
      setAdded(true);
    }
  }, []);

  // already added
  // OR not production
  if (window.hj || added || process.env.BUILD_ENV !== "production") {
    return <></>;
  }

  return (
    <Script
      id="hotjar-lib"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2384034,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `,
      }}
    />
  );
}
