import { IFormatPriceOptions } from "./types";

export const DEFAULT_FORMAT_PRICE_OPTIONS: IFormatPriceOptions = {
  locale: "en-US",
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const NUMBER_FORMAT_PRICE_OPTIONS: IFormatPriceOptions = {
  locale: "en-US",
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
};
