import { ReactElement, useEffect, useState, useCallback } from "react";
import Script from "next/script";
import { isProduction } from "@common/utils/config";
import { useRouter } from "next/router";

export default function SnowplowAnon(): ReactElement {
  const router = useRouter();

  // add Snowplow Anon only once
  const [added, setAdded] = useState(false);

  useEffect(() => {
    if (window.snowplowanon) {
      setAdded(true);
    }
  }, []);

  // tracking
  const callSnowplow = useCallback((method: string, options?) => {
    // loaded
    if (typeof window.snowplowanon === "function") {
      window.snowplowanon(method, options);
    }
    // invalid OR not loaded
    else {
      console.warn(`Snowplow Anon, ${method}:`, options);
    }
  }, []);

  // page view
  const onRouteChangeComplete = useCallback(() => {
    callSnowplow("trackPageView");
  }, [callSnowplow]);

  useEffect(() => {
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events, onRouteChangeComplete]);

  // already added
  // OR not production
  if (window.snowplowanon || added || !isProduction()) {
    return <></>;
  }

  return (
    <Script
      id="snowplow-anon-lib"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          ;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
          p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
          };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
          n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//cdn.jsdelivr.net/gh/snowplow/sp-js-assets@2.18.0/sp.min.js","snowplowanon"));
          window.snowplowanon('newTracker', 'anon', 'sp.metabase.com', {
            appId: "anon-store",
            platform: 'web',
            anonymousTracking: { withServerAnonymisation: true },
            stateStorageStrategy: 'none',
            eventMethod: 'post',
            contexts: {
              webPage: true,
              performanceTiming: false
            }
          });

          // first page view tracking
          window.snowplowanon("trackPageView");
        `,
      }}
    />
  );
}
