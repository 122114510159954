import { ReactElement } from "react";

export default function IconBook(): ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V14C0 15.1046 0.89543 16 2 16H11.5V4.5H3.5C2.94772 4.5 2.5 4.05229 2.5 3.5C2.5 2.94772 2.94772 2.5 3.5 2.5H11.5H12H12.5C13.0523 2.5 13.5 2.94772 13.5 3.5V12.5H16V2C16 0.89543 15.1046 0 14 0H3Z"
        fill="#4C5773"
      />
    </svg>
  );
}
