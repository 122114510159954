import { ReactElement } from "react";

export default function IconVisit(): ReactElement {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42857 12H2.57143C1.88944 12 1.23539 11.7291 0.753154 11.2469C0.270918 10.7646 0 10.1106 0 9.42858V2.57143C0 1.88944 0.270918 1.23539 0.753154 0.753155C1.23539 0.270918 1.88944 0 2.57143 0H5.14286V1.71429H2.57143C2.3441 1.71429 2.12608 1.80459 1.96534 1.96534C1.80459 2.12608 1.71429 2.3441 1.71429 2.57143V9.42858C1.71429 9.65591 1.80459 9.87393 1.96534 10.0347C2.12608 10.1954 2.3441 10.2857 2.57143 10.2857H9.42857C9.6559 10.2857 9.87392 10.1954 10.0347 10.0347C10.1954 9.87393 10.2857 9.65591 10.2857 9.42858V6.85715H12V9.42858C12 10.1106 11.7291 10.7646 11.2468 11.2469C10.7646 11.7291 10.1106 12 9.42857 12ZM5.5715 7.64143L4.35864 6.42858L9.07293 1.71429H6.85721V0H11.1429C11.3703 0 11.5883 0.0903061 11.749 0.251052C11.9098 0.411797 12.0001 0.629815 12.0001 0.857144V5.14286H10.2858V2.92715L5.5715 7.64143Z"
        fill="#434856"
      />
    </svg>
  );
}
