import { DEFAULT_FORMAT_PRICE_OPTIONS } from "./constants";
import { IFormatPriceOptions } from "./types";

export function formatList(values: string): string[] {
  return values.split(",").map((value) => value.trim());
}

export function tokenize(str: string): string {
  return (
    str
      .toLowerCase()
      .normalize("NFD")
      // latin characters only (no accents)
      .replace(/[\u0300-\u036f]/g, "")
      // replace special characters with spaces
      .replace(/[^\w\s]/gi, " ")
      // compact spaces
      .replace(/\s\s+/g, " ")
      .trim()
      // replace spaces with -
      .replace(/\s+/g, "-")
  );
}

export function generateId(prefix?: string): string {
  return `${prefix ? `${prefix}_` : ""}${Math.floor(
    Math.random() * Number.MAX_SAFE_INTEGER,
  )}`;
}

// from http://emailregex.com
// const REGEXP_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// used by input[type=email]
// const REGEXP_EMAIL = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// from https://github.com/metabase/metabase/blob/ce939219f8a3e28ecb00deb232154780a1dcfcef/frontend/@metabase/lib/utils.js#L146-L149
// const REGEXP_EMAIL =
//   /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// from https://github.com/segmentio/is-email/blob/master/lib/index.js
const REGEXP_EMAIL =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export function isEmail(emailStr: string): boolean {
  return REGEXP_EMAIL.test(emailStr);
}

export const PASSWORD_RULES =
  "At least 8 chars, with at least 1 letter, 1 number and 1 symbol";

// From the BE: if pass.length()>7, re.match("`~! @#$%^&*()_+={[}]|:;\"'<,>.?/- .",pass), and re.match("\d",pass) then ok else invalid end
// - minimum length of 8
// - any characters
// - at least 1 number
// - at least 1 symbol
export function isPassword(password: string): boolean {
  return (
    password.length > 7 &&
    /\d/gi.test(password) &&
    /[`~! @#$%\^&*()_+={\[}\]|:;\"'<,>.?\/-]/gi.test(password)
  );
}

// price

export function formatPrice(
  price: string | number,
  options: IFormatPriceOptions = DEFAULT_FORMAT_PRICE_OPTIONS,
): string {
  const formattedPrice = typeof price !== "number" ? Number(price) : price;

  if (isNaN(formattedPrice)) {
    throw new Error(`formatPrice, invalid price: ${price}`);
  }

  return formattedPrice.toLocaleString(options.locale, options);
}

export function revertFormatPrice(
  str: string,
  locale = DEFAULT_FORMAT_PRICE_OPTIONS.locale,
): number {
  const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
  const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
  let formattedValue = String(str);

  // TEMP
  // to replace with dynamic currency
  if (formattedValue.indexOf("$") > -1) {
    formattedValue = formattedValue.replace("$", "");
  }

  if (formattedValue.length === 0) {
    return 0;
  }

  formattedValue = formattedValue
    .replace(new RegExp(`\\${group}`, "g"), "")
    .replace(new RegExp(`\\${decimal}`, "g"), ".");

  const value = parseFloat(formattedValue);

  if (isNaN(value)) {
    throw new Error(`revertFormatPrice, invalid price: ${str}`);
  }

  return value;
}

// capitalize

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function prettyPrintValue(value: string): string {
  return capitalize(value).split("-").join(" ").split("_").join(" ");
}

// plural

const plural: Record<string, string> = {
  "(quiz)$": "$1zes",
  "^(ox)$": "$1en",
  "([m|l])ouse$": "$1ice",
  "(matr|vert|ind)ix|ex$": "$1ices",
  "(x|ch|ss|sh)$": "$1es",
  "([^aeiouy]|qu)y$": "$1ies",
  "(hive)$": "$1s",
  "(?:([^f])fe|([lr])f)$": "$1$2ves",
  "(shea|lea|loa|thie)f$": "$1ves",
  sis$: "ses",
  "([ti])um$": "$1a",
  "(tomat|potat|ech|her|vet)o$": "$1oes",
  "(bu)s$": "$1ses",
  "(alias)$": "$1es",
  "(octop)us$": "$1i",
  "(ax|test)is$": "$1es",
  "(us)$": "$1es",
  "([^s]+)$": "$1s",
};

const irregular: Record<string, string> = {
  move: "moves",
  foot: "feet",
  goose: "geese",
  sex: "sexes",
  child: "children",
  man: "men",
  tooth: "teeth",
  person: "people",
};

const uncountable: string[] = [
  "sheep",
  "fish",
  "deer",
  "moose",
  "series",
  "species",
  "money",
  "rice",
  "information",
  "equipment",
  "bison",
  "cod",
  "offspring",
  "pike",
  "salmon",
  "shrimp",
  "swine",
  "trout",
  "aircraft",
  "hovercraft",
  "spacecraft",
  "sugar",
  "tuna",
  "you",
  "wood",
];

export function pluralize(word: string, amount?: number): string {
  if (amount !== undefined && amount <= 1) {
    return word;
  }

  // uncountable
  if (uncountable.indexOf(word.toLowerCase()) >= 0) {
    return word;
  }

  // irregular
  for (const w in irregular) {
    const pattern = new RegExp(`${w}$`, "i");
    const replace = irregular[w];
    if (pattern.test(word)) {
      return word.replace(pattern, replace);
    }
  }

  // using regular expressions
  for (const reg in plural) {
    const pattern = new RegExp(reg, "i");
    if (pattern.test(word)) {
      return word.replace(pattern, plural[reg]);
    }
  }

  return word;
}

export function leftPad(value: string | number): string {
  if (
    (typeof value === "string" && value.length === 1) ||
    (typeof value === "number" && value < 10)
  ) {
    return `0${value}`;
  }

  return `${value}`;
}
