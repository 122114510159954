export function getEnv(short = false): string {
  // test
  if (process.env.SENTRY_ENV === "test") {
    return "test";
  }
  // staging
  else if (
    process.env.NEXT_PUBLIC_HARBORMASTER_API_BASE_URL?.indexOf("staging") > -1
  ) {
    return short ? "stgn" : "staging";
  }
  // development
  else if (
    process.env.NEXT_PUBLIC_HARBORMASTER_API_BASE_URL?.indexOf("localhost") > -1
  ) {
    return short ? "dev" : "development";
  }
  // production
  else if (process.env.NODE_ENV === "production") {
    return short ? "prod" : "production";
  }

  return process.env.NODE_ENV;
}

export function isDevelopment(): boolean {
  if (getEnv() === "development") {
    return true;
  }

  return false;
}

export function isTest(): boolean {
  if (getEnv() === "test") {
    return true;
  }

  return false;
}

export function isStaging(): boolean {
  if (getEnv() === "staging") {
    return true;
  }

  return false;
}

export function isProduction(): boolean {
  if (getEnv() === "production") {
    return true;
  }

  return false;
}
