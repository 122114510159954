import { PropsWithChildren, ReactElement } from "react";
import { Text } from "@mantine/core";
import { IErrorMessageProps } from "./types";

export default function ErrorMessage({
  children,
  m,
  mb,
  ml,
  mr,
  mt,
}: PropsWithChildren<IErrorMessageProps>): ReactElement {
  return (
    <Text
      c="red.2"
      fz="sm"
      m={m || 0}
      mb={mb || 0}
      ml={ml || 0}
      mr={mr || 0}
      mt={mt || 0}
    >
      {children}
    </Text>
  );
}
