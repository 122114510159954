import { COUNTRY_US_CODE, STATES_US } from "@common/constants/data";
import { ICustomerAddress } from "./types";

export const DEFAULT_CUSTOMER_ADDRESS: ICustomerAddress = {
  street: "",
  street2: "",
  country: COUNTRY_US_CODE,
  city: "",
  region: STATES_US[0].value,
  zip: "",
};
