import { usePaymentMethodsStore } from "@stores/payment-methods";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useState, useCallback, ReactElement } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { IUpdatePaymentMethodFormProps } from "./types";
import Form from "@common/components/ui/form/Form";
import ErrorMessage from "@common/components/text/ErrorMessage";

interface IUpdatePaymentMethodForm {
  name: string;
}

export default function UpdatePaymentMethodForm({
  account,
}: IUpdatePaymentMethodFormProps): ReactElement {
  const theme = useMantineTheme();

  const { error, loading, defaultPaymentMethod, createPaymentMethod } =
    usePaymentMethodsStore((state) => ({
      error: state.error,
      loading: state.loading,
      defaultPaymentMethod: state.defaultPaymentMethod,
      createPaymentMethod: state.createPaymentMethod,
    }));

  const { handleSubmit, register, reset } = useForm<IUpdatePaymentMethodForm>({
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const stripe = useStripe();
  const elements = useElements();
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [stripeError, setStripeError] = useState<string | null>(null);

  const handleStripeSubmit = useCallback(
    async (formData: IUpdatePaymentMethodForm) => {
      if (!stripe || !elements) {
        return;
      }

      setLoadingStripe(true);

      // 1. Stripe related
      const card = elements.getElement(CardElement);
      if (!card) {
        console.error("CreatePaymentMethodForm, missing Stripe card element");
        return;
      }

      // format data
      const { name, ...address } = formData;

      // 2. save customer PM
      const { success, error } = await createPaymentMethod(card, stripe, {
        name,
        email: account.email,
        address,
      });
      // success
      if (success) {
        setStripeError(null);
        card?.clear(); // empty CC stripe form
        reset(); // reset the form
      }
      // error
      else {
        setStripeError(error?.message || null);
      }

      setLoadingStripe(false);
    },
    [account, stripe, elements, createPaymentMethod, reset],
  );

  const isLoading = loadingStripe || loading;

  return (
    <Form
      data-testid="UpdatePaymentMethodForm"
      onSubmit={handleSubmit(handleStripeSubmit)}
      disabled={isLoading}
    >
      <Stack spacing="sm">
        <TextInput
          id="name"
          type="text"
          label="Name on card"
          placeholder="Name on card"
          required
          {...register("name", { required: true })}
        />

        <div>
          <Text c="gray.5" fz="sm" fw={700} mb={theme.spacing.xs / 2}>
            Credit card info *
          </Text>
          <Card id="stripe-form" withBorder p="sm">
            <CardElement
              options={{
                disableLink: true,
                hidePostalCode: true,
                style: {
                  base: {
                    fontFamily: theme.fontFamily,
                  },
                },
              }}
            />
          </Card>
          {stripeError && <ErrorMessage m={0}>{stripeError}</ErrorMessage>}
        </div>

        {error && (
          <ErrorMessage m={0}>{error?.message || error?.status}</ErrorMessage>
        )}
      </Stack>

      <Button
        type="submit"
        loading={isLoading}
        loaderPosition="right"
        disabled={isLoading}
        mt="lg"
      >
        {!defaultPaymentMethod ? "Add" : "Update"}
      </Button>
    </Form>
  );
}
