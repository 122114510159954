import { PropsWithChildren, ReactElement } from "react";
import { Card, Flex, useMantineTheme } from "@mantine/core";
import IconInfoOutline from "@common/components/icons/IconInfoOutline";
import { IInfoCardProps } from "./types";
import { useStyles } from "./InfoCard.styled";

export default function InfoCard({
  withBorder,
  mb,
  mt,
  children,
}: PropsWithChildren<IInfoCardProps>): ReactElement {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Card
      className={classes.root}
      withBorder={withBorder}
      bg="gray.1"
      maw={theme.breakpoints.sm}
      mb={mb || 0}
      mt={mt || 0}
      p="sm"
    >
      <Flex
        align="flex-start"
        direction="row"
        justify="flex-start"
        wrap="nowrap"
        gap="xs"
      >
        <Flex miw={11} mt={5}>
          <IconInfoOutline color={theme.colors.gray[4]} size={11} />
        </Flex>

        {children}
      </Flex>
    </Card>
  );
}
