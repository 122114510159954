import { IGeoLocation } from "./types";
import { useEffect, useState } from "react";
import { hasCookiePolicyRestriction } from "./utils";

export default function useGeoLocation(): IGeoLocation | undefined {
  const [geoLocation, setGeoLocation] = useState<IGeoLocation>();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    async function getGeoLocationAsync() {
      try {
        const response = await fetch(
          "https://get.geojs.io/v1/ip/country.json",
          {
            method: "GET",
          },
        );
        const res = await response.json();
        setGeoLocation(res);
      } catch (err) {
        console.error(err);
        timeoutId = setTimeout(getGeoLocationAsync, 3000);
      }
    }

    getGeoLocationAsync();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  if (geoLocation) {
    geoLocation.hasCookiePolicyRestriction =
      hasCookiePolicyRestriction(geoLocation);
  }

  return geoLocation;
}
