import { ReactElement, useCallback, useEffect, useState } from "react";
import { ISnowplow } from "./types";
import { useRouter, NextRouter } from "next/router";
import Script from "next/script";
import { SKIP_WHEN_LOGGED_IN } from "./constants";
import { isProduction } from "@common/utils/config";

export default function Snowplow({
  initCallsArgs,
  referrerUrl,
  account,
}: ISnowplow): ReactElement {
  const router = useRouter();

  // add Snowplow only once
  const [added, setAdded] = useState(false);

  useEffect(() => {
    if (window.snowplow) {
      setAdded(true);
    }
  }, []);

  // tracking
  const callSnowplow = useCallback(
    (method: string, options?) => {
      // loaded
      if (typeof window.snowplow === "function") {
        if (referrerUrl) {
          window.snowplow("setReferrerUrl", referrerUrl);
        }
        window.snowplow(method, options);
      }
      // invalid OR not loaded
      else {
        if (referrerUrl) {
          console.warn("Snowplow, setReferrerUrl:", referrerUrl);
        }
        console.warn(`Snowplow, ${method}:`, options);
      }
    },
    [referrerUrl],
  );

  const isValidForTrackPageView = useCallback(
    (urlOrRouter: string | NextRouter) => {
      // router
      if (typeof urlOrRouter === "object") {
        const { asPath, pathname } = urlOrRouter;
        return (
          !account ||
          (account &&
            (SKIP_WHEN_LOGGED_IN.indexOf(asPath) === -1 ||
              SKIP_WHEN_LOGGED_IN.indexOf(pathname) === -1))
        );
      }
      // string
      else if (typeof urlOrRouter === "string") {
        return (
          !account ||
          (account && SKIP_WHEN_LOGGED_IN.indexOf(urlOrRouter) === -1)
        );
      }

      return true;
    },
    [account],
  );

  // first page view
  const [firstLoading, setFirstLoading] = useState(true);
  useEffect(() => {
    if (firstLoading) {
      if (isValidForTrackPageView(router)) {
        callSnowplow("trackPageView");
      }
      setFirstLoading(false);
    }
  }, [
    firstLoading,
    isValidForTrackPageView,
    callSnowplow,
    router,
    router.asPath,
  ]);

  // page view
  const onRouteChangeComplete = useCallback(
    (url) => {
      if (isValidForTrackPageView(url)) {
        callSnowplow("trackPageView");
      }
    },
    [isValidForTrackPageView, callSnowplow],
  );

  useEffect(() => {
    router.events.on("routeChangeComplete", onRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, [router.events, onRouteChangeComplete]);

  // user id
  const onAccountChange = useCallback(() => {
    callSnowplow("setUserId", account?.id || null);
  }, [callSnowplow, account]);

  useEffect(onAccountChange, [onAccountChange, account]);

  // already added
  // OR not production
  if (window.snowplow || added || !isProduction()) {
    return <></>;
  }

  return (
    <Script
      id="snowplow-lib"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          ;(function(p,l,o,w,i,n,g){if(!p[i]){p.GlobalSnowplowNamespace=p.GlobalSnowplowNamespace||[];
          p.GlobalSnowplowNamespace.push(i);p[i]=function(){(p[i].q=p[i].q||[]).push(arguments)
          };p[i].q=p[i].q||[];n=l.createElement(o);g=l.getElementsByTagName(o)[0];n.async=1;
          n.src=w;g.parentNode.insertBefore(n,g)}}(window,document,"script","//cdn.jsdelivr.net/gh/snowplow/sp-js-assets@2.18.0/sp.min.js","snowplow"));
          window.snowplow('newTracker', 'cf', 'sp.metabase.com', {
            appId: 'store',
            platform: 'web',
            cookieDomain: '.metabase.com',
            post: true,
            contexts: {
              webPage: true,
              performanceTiming: true
            }
          });

          ${initCallsArgs
            .map((initCallArgs) => `window.snowplow(${initCallArgs});`)
            .join("")}
        `,
      }}
    />
  );
}
