import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconPin({ color, size }: IIconProps): ReactElement {
  return (
    <svg
      height={size || 16}
      width={size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84576 10.511C3.56059 10.2324 3.30251 9.92618 3.07582 9.59669L3.02128 9.53192L3.02687 9.52445C2.37816 8.55225 2 7.38412 2 6.12766C2 2.74345 4.74345 0 8.12766 0C11.5119 0 14.2553 2.74345 14.2553 6.12766C14.2553 7.38412 13.8772 8.55225 13.2284 9.52446L13.234 9.53192L8.46808 16L3.84576 10.511ZM10.1702 6.12766C10.1702 7.25573 9.25573 8.17022 8.12766 8.17022C6.99959 8.17022 6.08511 7.25573 6.08511 6.12766C6.08511 4.99959 6.99959 4.08511 8.12766 4.08511C9.25573 4.08511 10.1702 4.99959 10.1702 6.12766Z"
        fill={color || "#4C5773"}
      />
    </svg>
  );
}
