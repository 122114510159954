import { Accordion } from "@mantine/core";
import { PropsWithChildren, ReactElement } from "react";
import { useStyles } from "./AccordionSection.styled";
import { ACCORDION_SECTION_VALUE } from "./constants";
import { IAccordionSectionProps } from "./types";

export default function AccordionSection({
  chevron,
  children,
}: PropsWithChildren<IAccordionSectionProps>): ReactElement {
  // FIX
  // So we can pass the `component` property
  // that does not exists in Accordion.Control
  const extraProps = { component: "section" };

  // styles
  const { classes } = useStyles();

  return (
    <Accordion.Item value={ACCORDION_SECTION_VALUE}>
      <Accordion.Control
        className={classes.control}
        chevron={chevron || <></>}
        disabled
        {...extraProps}
      >
        {children}
      </Accordion.Control>
    </Accordion.Item>
  );
}
