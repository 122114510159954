import { SelectItem } from "@mantine/core";

export function normaliseValue(
  data: SelectItem[],
  defaultValue: string | null | undefined,
): string | undefined {
  const foundDefaultValue = data.find(
    ({ label, value }) => label === defaultValue || value === defaultValue,
  );

  return foundDefaultValue?.value || defaultValue || undefined;
}
