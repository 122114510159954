import { loadStripe, Stripe } from "@stripe/stripe-js";
import { captureException } from "@sentry/nextjs";

let stripe: Promise<Stripe | null>;

export function getStripe(stripeKey: string): Promise<Stripe | null> {
  // Load Stripe only once
  if (!stripe) {
    try {
      stripe = loadStripe(stripeKey);
    } catch (err) {
      // TEMP
      // Stripe is not loading
      captureException(err);
    }
  }

  return stripe;
}
