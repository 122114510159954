import { ReactElement, useState } from "react";
import { useForm } from "react-hook-form";
import { ITaxIdFormData } from "./types";
import {
  MAX_NUMBER_OF_TAX_IDS,
  TAX_TYPE_OPTIONS,
} from "@stores/billing/constants";
import {
  getTaxTypeFromUniqueTaxType,
  getUniqueTaxType,
  getErrorMessage,
  getTaxLabel,
} from "./utils";
import { billingStore } from "@stores/billing";
import { Button, Select, Stack, Text, TextInput } from "@mantine/core";
import Form from "@common/components/ui/form/Form";
import InfoCard from "@common/components/thirdParty/mantine/InfoCard";
import ErrorMessage from "@common/components/text/ErrorMessage";

export default function CreateTaxIdForm(): ReactElement {
  const { isLoading: isTaxIDsLoading, data: taxIds = [] } =
    billingStore.useGetTaxIds();
  const {
    isLoading,
    mutate: createTaxId,
    error,
  } = billingStore.useCreateTaxId();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<ITaxIdFormData>({
    mode: "onChange",
    defaultValues: {
      type: "",
      value: "",
    },
  });

  const [taxIdType, setTaxIdType] = useState<string | null>(null);
  async function onCreateTaxId(data) {
    if (taxIdType) {
      // format type
      data.type = getTaxTypeFromUniqueTaxType(taxIdType);

      // create
      createTaxId(data, {
        onSuccess() {
          console.log("Tax ID created!!!");
        },
      });
    }
  }

  const hasMaxTaxIds = taxIds.length >= MAX_NUMBER_OF_TAX_IDS;

  return hasMaxTaxIds ? (
    <Text fw={700} fz="sm">
      Maximum number of tax Ids reached.
    </Text>
  ) : (
    <Form
      data-testid="CreateTaxIdForm"
      onSubmit={handleSubmit(onCreateTaxId)}
      disabled={isLoading || isTaxIDsLoading}
    >
      <Stack spacing="sm">
        <InfoCard withBorder>
          <Text c="gray.4" fz="xs">
            You can add, edit, or removed up to 5 Tax IDs which will be
            displayed on your invoices.
          </Text>
        </InfoCard>

        <Select
          data-testid="CreateTaxIdForm-taxIdType"
          label="Country"
          placeholder="Select one..."
          data={TAX_TYPE_OPTIONS.map((taxTypeOption) => ({
            value: getUniqueTaxType(taxTypeOption),
            label: getTaxLabel(taxTypeOption),
          }))}
          disabled={isLoading}
          onChange={(value) => setTaxIdType(value)}
        />

        <TextInput
          label="Tax ID"
          placeholder={
            TAX_TYPE_OPTIONS.find(
              (taxTypeOption) => getUniqueTaxType(taxTypeOption) === taxIdType,
            )?.example || "abc.123"
          }
          id="value"
          disabled={isLoading}
          {...register("value", {
            required: true,
          })}
        />

        {error && <ErrorMessage m={0}>{getErrorMessage(error)}</ErrorMessage>}
      </Stack>

      <Button
        data-testid="CreateTaxIdForm-button"
        type="submit"
        loading={isLoading}
        loaderPosition="right"
        disabled={!taxIdType || !isValid || isLoading || isTaxIDsLoading}
        mt="lg"
      >
        Save
      </Button>
    </Form>
  );
}
