import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconCreditCard({
  color,
  size,
}: IIconProps): ReactElement {
  return (
    <svg
      height={size || 16}
      width={size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 2.5C2.46957 2.5 1.96086 2.71071 1.58579 3.08579C1.21071 3.46086 1 3.96957 1 4.5V5H15V4.5C15 3.96957 14.7893 3.46086 14.4142 3.08579C14.0391 2.71071 13.5304 2.5 13 2.5H3Z"
        fill={color || "#4C5773"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6.5H1V11.5C1 12.0304 1.21071 12.5391 1.58579 12.9142C1.96086 13.2893 2.46957 13.5 3 13.5H13C13.5304 13.5 14.0391 13.2893 14.4142 12.9142C14.7893 12.5391 15 12.0304 15 11.5V6.5ZM3 9C3 8.86739 3.05268 8.74022 3.14645 8.64645C3.24021 8.55268 3.36739 8.5 3.5 8.5H7.5C7.63261 8.5 7.75979 8.55268 7.85355 8.64645C7.94732 8.74022 8 8.86739 8 9C8 9.13261 7.94732 9.25978 7.85355 9.35355C7.75979 9.44732 7.63261 9.5 7.5 9.5H3.5C3.36739 9.5 3.24021 9.44732 3.14645 9.35355C3.05268 9.25978 3 9.13261 3 9ZM3.5 10.5C3.36739 10.5 3.24021 10.5527 3.14645 10.6464C3.05268 10.7402 3 10.8674 3 11C3 11.1326 3.05268 11.2598 3.14645 11.3536C3.24021 11.4473 3.36739 11.5 3.5 11.5H5.5C5.63261 11.5 5.75979 11.4473 5.85355 11.3536C5.94732 11.2598 6 11.1326 6 11C6 10.8674 5.94732 10.7402 5.85355 10.6464C5.75979 10.5527 5.63261 10.5 5.5 10.5H3.5Z"
        fill={color || "#4C5773"}
      />
    </svg>
  );
}
