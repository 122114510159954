import { PropsWithChildren, ReactElement } from "react";
import { useStyles } from "./DisabledContainer.styled";
import { IDisabledContainerProps } from "./types";

export default function DisabledContainer({
  disabled,
  children,
  ...props
}: PropsWithChildren<IDisabledContainerProps>): ReactElement {
  const { classes } = useStyles({
    disabled,
  });

  return (
    <div className={classes.root} {...props}>
      {children}
    </div>
  );
}
