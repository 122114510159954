import { IFormatDateOptions } from "./types";

export const DATE_FORMAT_DEFAULT: IFormatDateOptions = {
  locale: "en-US",
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const DATE_FORMAT_SHORT: IFormatDateOptions = {
  locale: "en-US",
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const DATE_FORMAT_WITH_TIME: IFormatDateOptions = {
  ...DATE_FORMAT_SHORT,
  hour: "2-digit",
  minute: "2-digit",
};

export const DATE_FORMAT_COMPACT: IFormatDateOptions = {
  locale: "en-US",
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

export const DATE_FORMAT_COMPACT_WITH_TIME: IFormatDateOptions = {
  ...DATE_FORMAT_COMPACT,
  hour: "2-digit",
  minute: "2-digit",
};

export const DATE_FORMAT_LONG: IFormatDateOptions = {
  locale: "en-US",
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "long",
};

export const DATE_FORMAT_LONG_WITH_TIME: IFormatDateOptions = {
  ...DATE_FORMAT_LONG,
  hour: "2-digit",
  minute: "2-digit",
  timeZoneName: "short",
};
