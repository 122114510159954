import { AllPlanSlugType } from "@common/config/plan/types";
import { IDataFetcherResponse } from "@common/utils/use-query/types";
import { generateBEAPIUrl } from "@utils/api";
import { dataFetcher } from "@utils/data";
import create from "zustand";
import {
  IChangeCustomDomainData,
  IChangeDNSAliasData,
  CreateInstanceDataType,
  IPurchase,
  IPurchaseEstimate,
  PurchasesStore,
  ICreateCloudInstanceData,
  IChangeRegionData,
  ICancellationSurveyDataProps,
} from "./types";
import { comparePurchase } from "./utils";

export const usePurchasesStore = create<PurchasesStore>(
  (setState, getState): PurchasesStore => ({
    loading: false,
    isLoadingEstimate: false,
    error: null,

    purchases: null,
    updatedPlanEstimate: null,

    async getPurchases(): Promise<IDataFetcherResponse<IPurchase[]>> {
      setState({ loading: true });

      const {
        success,
        error,
        data: purchases,
      } = await dataFetcher(generateBEAPIUrl("/account/purchases"));

      if (success) {
        setState({
          error: null,
          loading: false,
          purchases: purchases?.sort(comparePurchase),
        });
      } else {
        setState({
          error,
          loading: false,
          purchases: null,
        });
      }

      return {
        error,
        success,
        data: purchases,
      };
    },

    purchase: null,

    async getPurchase(id: string): Promise<IDataFetcherResponse<IPurchase>> {
      const {
        success,
        error,
        data: purchase,
      } = await dataFetcher(generateBEAPIUrl(`/account/purchases/${id}`));

      // 101 + 102 + 103

      if (success) {
        // update purchases list
        const currentPurchases = getState().purchases;
        if (currentPurchases) {
          const purchaseIndex = currentPurchases.findIndex(
            (purchaseDetails) => purchaseDetails.id === id,
          );
          currentPurchases[purchaseIndex] = purchase;
          setState({ purchases: currentPurchases });
        }

        setState({
          error: null,
          loading: false,
          purchase,
        });
      } else {
        setState({
          error,
          loading: false,
          purchase: null,
        });
      }

      return {
        error,
        success,
        data: purchase,
      };
    },

    async cancelPurchase(id: string): Promise<IDataFetcherResponse> {
      setState({ loading: true });

      const { success, error } = await dataFetcher(
        generateBEAPIUrl(`/account/purchases/${id}`),
        {
          method: "DELETE",
        },
      );

      if (success) {
        // reload purchase details
        // so the data are up to date
        const getPurchase = getState().getPurchase;
        return await getPurchase(id);
      } else {
        setState({
          error,
          loading: false,
        });
      }

      return {
        error,
        success,
      };
    },

    async sendPostCancellationSurvey(
      id: string,
      data: ICancellationSurveyDataProps,
    ): Promise<IDataFetcherResponse> {
      setState({ loading: true });

      const { success, error } = await dataFetcher(
        generateBEAPIUrl(`/account/purchases/${id}/cancellation_survey`),
        {
          method: "POST",
          body: data,
        },
      );

      if (!success) {
        setState({
          error,
          loading: false,
        });
      }

      return {
        error,
        success,
      };
    },

    async getPlanChangePurchaseEstimate(
      purchase_id: string,
      new_plan_alias: AllPlanSlugType,
    ): Promise<IDataFetcherResponse<IPurchaseEstimate>> {
      setState({ isLoadingEstimate: true });

      const {
        success,
        error,
        data: estimate,
      } = await dataFetcher(
        generateBEAPIUrl(
          `/account/purchases/${purchase_id}/action/change_plan_preview`,
        ),
        {
          method: "PUT",
          body: { new_plan_alias },
        },
      );

      if (success) {
        setState({
          error: null,
          isLoadingEstimate: false,
          updatedPlanEstimate: estimate,
        });
      } else {
        setState({
          error,
          isLoadingEstimate: false,
        });
      }

      return {
        error,
        success,
      };
    },

    async changePurchasePlan(
      purchase_id: string,
      new_plan_alias: AllPlanSlugType,
    ): Promise<IDataFetcherResponse<IPurchase>> {
      setState({ loading: true });

      const { success, error } = await dataFetcher(
        generateBEAPIUrl(
          `/account/purchases/${purchase_id}/action/change_plan`,
        ),
        {
          method: "PUT",
          body: { new_plan_alias },
        },
      );

      if (success) {
        // reload purchase details
        // so the data are up to date
        const getPurchase = getState().getPurchase;
        return await getPurchase(purchase_id);
      } else {
        setState({
          error,
        });
      }

      return {
        error,
        success,
      };
    },

    newInstanceData: null,

    // intermediary method to store a new instance details
    // used during checkout
    async checkInstanceDNSAliasAvailability(
      instanceData: ICreateCloudInstanceData,
    ): Promise<IDataFetcherResponse<CreateInstanceDataType>> {
      setState({ loading: true, newInstanceData: instanceData });

      // check DNS alias availability
      const { dns_alias } = instanceData;
      let { success, error } = await dataFetcher(
        generateBEAPIUrl(
          `/account/purchases/action/instance_dns_available?dns_alias=${dns_alias}`,
        ),
      );

      if (success) {
        setState({
          error: null,
          loading: false,
          newInstanceData: instanceData,
        });
      } else {
        if (error?.status === 409) {
          error = {
            message: "Looks like that DNS alias is already taken.",
            status: 409,
          };
        }
        setState({
          error,
          loading: false,
          newInstanceData: null,
        });
      }

      return {
        error,
        success,
        data: instanceData,
      };
    },

    async createInstance(
      instanceData: CreateInstanceDataType,
    ): Promise<IDataFetcherResponse<IPurchase>> {
      setState({ loading: true, newInstanceData: instanceData });

      // check DNS availability
      // when new instance as param only
      if (instanceData["dns_alias"] && instanceData["region"]) {
        const checkInstanceDNSAliasAvailability =
          getState().checkInstanceDNSAliasAvailability;
        const {
          success: successDNSAliasAvailability,
          error: errorDNSAliasAvailability,
        } = await checkInstanceDNSAliasAvailability(
          instanceData as ICreateCloudInstanceData,
        );
        if (!successDNSAliasAvailability) {
          setState({
            error: errorDNSAliasAvailability,
            loading: false,
          });

          return {
            error: errorDNSAliasAvailability,
            success: successDNSAliasAvailability,
          };
        }
      }

      // reset the loading state
      setState({ loading: true });

      // create instance
      const { success, error, data } = await dataFetcher(
        generateBEAPIUrl("/account/purchases"),
        {
          method: "POST",
          body: instanceData,
        },
      );
      const formattedError =
        error?.message &&
        error.message.indexOf("already running") > -1 &&
        error.message.indexOf("trial") > -1
          ? { message: error.message, status: 402 }
          : error;

      if (success) {
        setState({ newInstanceData: instanceData });

        // retrieve purchase
        const getPurchase = getState().getPurchase;
        return await getPurchase(data["purchase_id"]);
      } else {
        setState({
          error: formattedError,
          loading: false,
        });
      }

      return {
        error: formattedError,
        success,
        data,
      };
    },

    async migrateInstance(
      purchase_id: string,
    ): Promise<IDataFetcherResponse<IPurchase>> {
      setState({ loading: true });

      const { success, error } = await dataFetcher(
        generateBEAPIUrl(
          `/account/purchases/${purchase_id}/action/initiate_instance_migration`,
        ),
        {
          method: "PUT",
        },
      );

      if (success) {
        // reload purchase details
        // so the data are up to date
        const getPurchase = getState().getPurchase;
        return await getPurchase(purchase_id);
      } else {
        setState({
          error,
          loading: false,
        });
      }

      return {
        error,
        success,
      };
    },

    async changeInstanceDNSAlias(
      purchase_id: string,
      data: IChangeDNSAliasData,
    ): Promise<IDataFetcherResponse<IPurchase>> {
      setState({ loading: true });

      let { success, error } = await dataFetcher(
        generateBEAPIUrl(
          `/account/purchases/${purchase_id}/action/change_instance_dns_alias`,
        ),
        {
          method: "PUT",
          body: data,
        },
      );

      if (success) {
        // reload purchase details
        // so the data are up to date
        const getPurchase = getState().getPurchase;
        return await getPurchase(purchase_id);
      } else {
        if (error?.status === 409 || error?.status === 400) {
          error = {
            message: "Looks like that DNS alias is already taken.",
            status: 409,
          };
        }
        setState({
          error,
          loading: false,
        });
      }

      return {
        error,
        success,
      };
    },

    async changeInstanceRegion(
      purchase_id: string,
      data: IChangeRegionData,
    ): Promise<IDataFetcherResponse<IPurchase>> {
      setState({ loading: true });

      const { success, error } = await dataFetcher(
        generateBEAPIUrl(
          `/account/purchases/${purchase_id}/action/change_region`,
        ),
        {
          method: "PUT",
          body: data,
        },
      );

      if (success) {
        // reload purchase details
        // so the data are up to date
        const getPurchase = getState().getPurchase;
        return await getPurchase(purchase_id);
      } else {
        setState({
          error,
          loading: false,
        });
      }

      return {
        error,
        success,
      };
    },

    async changeInstanceCustomDomain(
      purchase_id: string,
      data: IChangeCustomDomainData,
    ): Promise<IDataFetcherResponse<IPurchase>> {
      setState({ loading: true });

      let { success, error } = await dataFetcher(
        generateBEAPIUrl(
          `/account/purchases/${purchase_id}/action/change_instance_custom_domain`,
        ),
        {
          method: "PUT",
          body: data,
        },
      );

      if (success) {
        // reload purchase details
        // so the data are up to date
        const getPurchase = getState().getPurchase;
        return await getPurchase(purchase_id);
      } else {
        if (error?.status === 409 || error?.status === 400) {
          error = {
            message: "Looks like that custom domain is already taken.",
            status: 409,
          };
        }
        setState({
          error,
          loading: false,
        });
      }

      return {
        error,
        success,
      };
    },

    async tryProPlan(
      purchase_id: string,
    ): Promise<IDataFetcherResponse<IPurchase>> {
      setState({ loading: true });

      const { success, error } = await dataFetcher(
        generateBEAPIUrl(`/account/purchases/${purchase_id}/action/trial_up`),
        {
          method: "PUT",
        },
      );

      if (success) {
        // reload purchase details
        // so the data are up to date
        const getPurchase = getState().getPurchase;
        return await getPurchase(purchase_id);
      } else {
        setState({
          error,
          loading: false,
        });
      }

      return {
        error,
        success,
      };
    },
  }),
);
