import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconWarning({ color, size }: IIconProps): ReactElement {
  return (
    <svg
      height={size || 24}
      width={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.95221 16.3535L10.2152 5.85653C10.9531 4.38476 13.0539 4.38515 13.7913 5.85718L19.0495 16.3542C19.7156 17.684 18.7487 19.2499 17.2613 19.2499H6.74008C5.25235 19.2499 4.28541 17.6834 4.95221 16.3535Z"
        stroke={color || "#F9CB39"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10V12"
        stroke={color || "#F9CB39"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
        fill={color || "#F9CB39"}
      />
    </svg>
  );
}
