import Tick from "@common/components/icons/Tick";
import ErrorMessage from "@common/components/text/ErrorMessage";
import InfoCard from "@common/components/thirdParty/mantine/InfoCard";
import Form from "@common/components/ui/form/Form";
import { Button, Stack, Text, TextInput } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { customerStore } from "@stores/customer";
import { ReactElement, useCallback, useState } from "react";
import { useForm } from "react-hook-form";

type PONumberFormType = { purchase_order_number: string };

export default function PONumberForm(): ReactElement {
  const [theLoading, setTheLoading] = useState(false);

  // customer
  const {
    isLoading: loadingCustomerDetails,
    data: customerDetails,
    error: customerDetailsError,
  } = customerStore.useGetDetails();
  const {
    mutate: updatePONumber,
    isLoading: loadingPONumber,
    error: PONumberError,
  } = customerStore.useUpdatePONumber();
  const theError = customerDetailsError || PONumberError || null;

  // Form
  const {
    register,
    formState: { isValid },
    handleSubmit,
  } = useForm<PONumberFormType>({
    shouldFocusError: true,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      purchase_order_number: customerDetails?.purchase_order_number || "",
    },
  });

  const handleStripeSubmit = useCallback(
    async ({ purchase_order_number }: PONumberFormType) => {
      setTheLoading(true);
      updatePONumber(
        { purchase_order_number },
        {
          onSuccess() {
            setTheLoading(false);
            showNotification({
              color: "teal",
              icon: <Tick color="white" />,
              autoClose: 5000,
              message: `PO number updated.`,
            });
          },

          onError() {
            setTheLoading(false);
          },
        },
      );
    },
    [updatePONumber],
  );

  const showSpinner = loadingCustomerDetails || loadingPONumber || theLoading;

  return (
    <Form
      data-testid="PONumberForm"
      onSubmit={handleSubmit(handleStripeSubmit)}
      disabled={showSpinner}
    >
      <Stack spacing="sm">
        <InfoCard withBorder>
          <Text c="gray.4" fz="xs">
            A PO (Purchase Order) number is a unique code used to track and
            identify a specific order. You can add only one PO number, which
            will be displayed on your invoices.
          </Text>
        </InfoCard>

        <TextInput
          w="100%"
          id="purchase_order_number"
          type="text"
          placeholder="PO number"
          required
          defaultValue={
            loadingCustomerDetails
              ? ""
              : customerDetails?.purchase_order_number || ""
          }
          disabled={loadingCustomerDetails}
          {...register("purchase_order_number", {
            required: true,
            validate(value) {
              return !!(value.trim().length > 0);
            },
          })}
        />

        {theError && (
          <ErrorMessage m={0}>
            {theError.message || theError.status}
          </ErrorMessage>
        )}
      </Stack>

      <Button
        type="submit"
        loading={showSpinner}
        loaderPosition="right"
        disabled={!isValid || showSpinner}
        mt="lg"
      >
        {customerDetails?.purchase_order_number ? "Update" : "Save"}
      </Button>
    </Form>
  );
}
