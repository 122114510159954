import { captureException } from "@sentry/nextjs";
import { IPlan } from "./types";

export function isDWHPlan(slug: string): boolean {
  return slug.includes("-with-dwh");
}

export function isMonthlyPlan({
  billing_period_months,
}: Pick<IPlan, "billing_period_months">): boolean {
  return !!(billing_period_months === 1);
}

export function isAnnualPlan({
  billing_period_months,
}: Pick<IPlan, "billing_period_months">): boolean {
  return !!(billing_period_months === 12);
}

export function getBillingFrequencyCopy({
  billing_period_months,
}: Pick<IPlan, "billing_period_months">): string {
  if (billing_period_months === 1) {
    return "Monthly Billing";
  } else if (billing_period_months === 12) {
    return "Annual Billing";
  }

  captureException(
    new Error(
      `getBillingFrequencyCopy error: unknown billing frequency of "${billing_period_months}"`,
    ),
  );
  return "Unknown Billing Frequency";
}

export function formatPlanName(name: string): string {
  return name.replace("Metabase", "").trim();
}
