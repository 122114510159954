import { useStyles } from "./Form.styled";
import { IForm } from "./types";
import { PropsWithChildren, ReactElement } from "react";

export default function Form({
  disabled,
  children,
  mt,
  onSubmit,
  "data-testid": dataTestid,
}: PropsWithChildren<IForm>): ReactElement {
  const { classes } = useStyles({ disabled, mt });

  return (
    <form data-testid={dataTestid} className={classes.form} onSubmit={onSubmit}>
      {children}
    </form>
  );
}
