import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  warningIcon: {
    margin: `-1px 0 0 -2px`,
  },
}));

export const usePurchaseRowStyles = createStyles((theme) => ({
  cell: {
    width: "45%",

    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      minWidth: "36%",
      width: "auto",
    },
  },
}));
