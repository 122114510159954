import IconVisit from "@common/components/icons/IconVisit";
import {
  Accordion,
  Anchor,
  Flex,
  Loader,
  Progress,
  Title,
} from "@mantine/core";
import Link from "next/link";
import { Children, PropsWithChildren, ReactElement } from "react";
import { IAccordionHeaderProps } from "./types";
import { useStyles } from "./AccordionHeader.styled";

export default function AccordionHeader({
  icon,
  title,
  titleLink,
  externalLink,
  progress,
  loading,
  children,
}: PropsWithChildren<IAccordionHeaderProps>): ReactElement {
  // children
  const cleanedUpChildren =
    Children.toArray(children).filter((child) => !!child).length > 0
      ? children
      : null;

  // styles
  const { classes } = useStyles({ children: cleanedUpChildren });

  return (
    <Accordion.Item className={classes.item} value="header">
      <Accordion.Control
        className={classes.control}
        chevron={icon || <></>}
        disabled
      >
        {titleLink ? (
          externalLink ? (
            <Anchor
              className={classes.anchor}
              href={titleLink}
              target="_blank"
              rel="noreferrer"
            >
              <Flex align="baseline" direction="row">
                <Title m={0} mr="xs" order={2} size="h3">
                  {title}
                </Title>
                <IconVisit />
              </Flex>
            </Anchor>
          ) : (
            <Title m={0} order={2} size="h3">
              <Link href={titleLink}>{title}</Link>
            </Title>
          )
        ) : (
          <Title m={0} order={2} size="h3">
            {title}
          </Title>
        )}

        {loading && <Loader mt="xs" />}

        {!loading && cleanedUpChildren && (
          <div className={classes.container}>{cleanedUpChildren}</div>
        )}
      </Accordion.Control>

      {progress && (
        <Progress
          animate
          striped
          value={typeof progress === "number" ? progress : 100}
          mt="md"
        />
      )}
    </Accordion.Item>
  );
}
