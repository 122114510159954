import { createStyles } from "@mantine/core";
import { HEADER_NAV_HEIGHT } from "./constants";

export const useLogoLinkStyles = createStyles((theme) => ({
  root: {
    color: theme.colors.gray[6],
    display: "inline-block",
    fontStyle: "normal",
    fontWeight: 700,
    height: HEADER_NAV_HEIGHT,
    margin: 0,
    padding: 0,
    position: "relative",
    textDecoration: "none",
    width: "auto",

    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export const useHeaderNavLinkStyles = createStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    border: "none",
    display: "inline-block",
    fontSize: theme.fontSizes.xs,
    fontStyle: "normal",
    fontWeight: 700,
    margin: `0 0 0 ${theme.spacing.md}px`,
    padding: 0,

    "&:hover": {
      backgroundColor: "transparent",
      border: "none",
      color: theme.colors.gray[6],
      textDecoration: "underline",
    },
  },

  main: {
    "&:hover": {
      color: theme.colors.blue[5],
      textDecoration: "none",
    },
  },
}));
