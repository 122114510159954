import {
  IDataFetcherResponse,
  IDataFetcherResponseError,
} from "@common/utils/use-query/types";
import { generateBEAPIUrl } from "@utils/api";
import { dataFetcher } from "@utils/data";
import create from "zustand";
import { ICreateAccount, ILoginDetails, SessionStore, IAccount } from "./types";

export const useSessionStore = create<SessionStore>(
  (setState, getState): SessionStore => ({
    loading: true,
    error: null,

    loginDetails: null,

    setLoginDetails(loginDetails: ILoginDetails) {
      setState({ loginDetails });
    },

    account: null,

    async createAccount(
      account: ICreateAccount,
      params?: Record<string, any>,
    ): Promise<IDataFetcherResponse<IAccount>> {
      setState({ loading: true });

      const { success, error }: IDataFetcherResponse = await dataFetcher(
        generateBEAPIUrl("/account", params),
        {
          method: "POST",
          body: account,
        },
      );

      if (success) {
        const getAccountDetails = getState().getAccountDetails;
        return await getAccountDetails();
      } else {
        setState({
          error,
          loading: false,
          account: null,
        });
      }

      return {
        success,
        error,
      };
    },

    async getAccountDetails(): Promise<IDataFetcherResponse<IAccount>> {
      setState({ loading: true });

      const {
        success,
        error,
        data: account,
      }: IDataFetcherResponse = await dataFetcher(generateBEAPIUrl("/account"));
      if (success) {
        setState({
          error: null,
          loading: false,
          account: {
            ...account,
            // billing_method: "invoice",
            // is_eligible_for_invoicing: true,
          },
        });
      } else {
        setState({
          error,
          loading: false,
          account: null,
        });
      }

      return {
        success,
        error,
      };
    },

    async updateAccount(
      data: IAccount,
    ): Promise<IDataFetcherResponse<IAccount>> {
      setState({ loading: true });

      const { success, error }: IDataFetcherResponse = await dataFetcher(
        generateBEAPIUrl("/account"),
        {
          method: "PUT",
          body: data,
        },
      );

      if (success) {
        // load account details
        const getAccountDetails = getState().getAccountDetails;
        return await getAccountDetails();
      } else {
        setState((state) => ({
          ...state,
          error,
          loading: false,
        }));
      }

      return {
        success,
        error,
      };
    },

    async login(
      account: ILoginDetails,
    ): Promise<IDataFetcherResponse<IAccount>> {
      setState({ loading: true });

      let { success, error }: IDataFetcherResponse = await dataFetcher(
        generateBEAPIUrl("/session"),
        {
          method: "POST",
          body: account,
        },
      );

      if (success) {
        // load account details
        const getAccountDetails = getState().getAccountDetails;
        return await getAccountDetails();
      } else {
        if (error?.status === 400) {
          error = {
            message:
              "Sorry, it looks like this email is invalid. Please double-check for typos and try again.",
            status: 400,
          };
        } else if (error?.status === 401) {
          error = {
            message:
              "Sorry, your login failed. Please double-check for typos and try again.",
            status: 401,
          };
        } else if (error?.status === 429) {
          error = {
            message: "Please try to login again in a couple of minutes.",
            status: 429,
          };
        } else if (error?.status === 500) {
          error = {
            message:
              "Something went wrong. Please try to login again in a couple of minutes",
            status: 500,
          };
        } else {
          error = {
            message:
              "An error occured. Please try to login again in a couple of minutes",
            status: error?.status || 500,
          };
        }

        setState({
          error,
          loading: false,
          account: null,
        });
      }

      return {
        error,
        success,
      };
    },

    async logout(): Promise<IDataFetcherResponse> {
      setState({ loading: true });

      const { success, error }: IDataFetcherResponse = await dataFetcher(
        generateBEAPIUrl("/session"),
        {
          method: "DELETE",
        },
      );
      if (success) {
        setState({
          error: null,
          loading: false,
          account: null,
        });
      } else {
        setState({
          error,
          loading: false,
        });
      }

      return {
        success,
        error,
      };
    },

    setError(error: IDataFetcherResponseError | null) {
      setState({ error });
    },

    async switchToInvoicing(): Promise<IDataFetcherResponse<IAccount>> {
      setState({ loading: true });

      const { success, error }: IDataFetcherResponse = await dataFetcher(
        generateBEAPIUrl("/account/payment_collection_method"),
        {
          method: "PUT",
          body: { collection_method: "send_invoice" },
        },
      );
      if (success) {
        const getAccountDetails = getState().getAccountDetails;
        return await getAccountDetails();
      } else {
        setState({
          error,
          loading: false,
        });
      }

      return {
        success,
        error,
      };
    },
  }),
);
