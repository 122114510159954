export function formatPath(path: string): string {
  if (path[0] === "/") {
    return path;
  }

  return `/${path}`;
}

export function generateFEAPIUrl(path: string): string {
  // API path + request path
  return `${process.env.NEXT_PUBLIC_HARBORMASTER_API_BASE_PATH}${formatPath(
    path,
  )}`;
}

export function generateBEAPIUrl(
  path: string,
  params?: Record<string, any>,
): string {
  const baseUrl = formatPath(generateFEAPIUrl(path));
  const queryParams =
    params && Object.keys(params).length > 0
      ? `${baseUrl.includes("?") ? "&" : "?"}${Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join("&")}`
      : "";

  // BE url + FE path
  return `${process.env.NEXT_PUBLIC_HARBORMASTER_API_BASE_URL}${baseUrl}${queryParams}`;
}
