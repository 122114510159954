import { Accordion } from "@mantine/core";
import { useRouter } from "next/router";
import { PropsWithChildren, ReactElement, useEffect, useState } from "react";
import { ACCORDION_SECTION_VALUE } from "../AccordionSection/constants";
import { IAccordionCustomProps } from "./types";
import { useWindowScroll } from "@mantine/hooks";
import { ACCORDION_TRANSITION_DURATION } from "./constants";
import { getHashParams } from "@common/utils/router";

export default function AccordionCustom({
  id,
  sections = [],
  defaultSection,
  children,
}: PropsWithChildren<IAccordionCustomProps>): ReactElement {
  const router = useRouter();
  const hash = getHashParams(router.asPath);
  const { section } = hash;

  // initial
  const [isInitial, setIsInitial] = useState(true);

  // current section
  const [currentSection, setCurrentSection] = useState<string | null>();
  useEffect(() => {
    const formattedSection = String(section);

    // focus on accordeon
    if (id === formattedSection) {
      setTimeout(() => {
        setCurrentSection(formattedSection);
      }, 100);
    }
    // focus on section
    else {
      const selectedSections = sections
        .map((tab) =>
          tab === formattedSection ||
          (formattedSection &&
            tab.includes("*") &&
            formattedSection.indexOf(tab.replace("*", "")) === 0)
            ? formattedSection
            : null,
        )
        .filter((tab) => !!tab);
      const [selectedTab] =
        selectedSections && selectedSections.length > 0 ? selectedSections : [];

      setTimeout(() => {
        setCurrentSection(selectedTab);
      }, 100);
    }
  }, [id, section, sections]);

  // scroll
  const [, scrollTo] = useWindowScroll();
  useEffect(() => {
    let timeoutId;

    if (defaultSection !== currentSection) {
      const currentSectionSelector =
        id === currentSection
          ? `[id=${currentSection}]` // accordeon
          : `[id*=-${currentSection}]`; // section
      const $currentSection = document.querySelector(currentSectionSelector);
      if ($currentSection) {
        timeoutId = setTimeout(() => {
          const { top: bodyY } = document.body.getBoundingClientRect();
          const { top: sectionY } = $currentSection.getBoundingClientRect();
          scrollTo({ y: sectionY - bodyY - 105 }); // TODO improve animation
          setIsInitial(false);
        }, ACCORDION_TRANSITION_DURATION + 50);
      }
    }

    return () => clearTimeout(timeoutId);
  }, [id, isInitial, defaultSection, currentSection, scrollTo]);

  return (
    <div id={id}>
      <Accordion
        id={id}
        transitionDuration={ACCORDION_TRANSITION_DURATION}
        defaultValue={currentSection || defaultSection}
        value={currentSection || defaultSection}
        onChange={(value) => {
          if (value && value !== ACCORDION_SECTION_VALUE) {
            router.replace({
              hash: `section=${value}`,
            });
          }
        }}
      >
        {children}
      </Accordion>
    </div>
  );
}
