import { Badge, Box, Flex, Title } from "@mantine/core";
import { PropsWithChildren, ReactElement } from "react";
import { ITitleWithIconProps } from "./types";

export default function TitleWithIcon({
  children,
  icon,
  title,
  order,
  size,
  isNew,
  extraContent,
}: PropsWithChildren<ITitleWithIconProps>): ReactElement {
  return (
    <Flex
      align="center"
      direction="row"
      justify="space-between"
      wrap="nowrap"
      w="100%"
    >
      <Flex
        align="flex-start"
        direction="row"
        gap="sm"
        justify="flex-start"
        wrap="nowrap"
        w="100%"
      >
        <Flex
          align="flex-start"
          direction="row"
          gap="sm"
          justify="flex-start"
          wrap="nowrap"
          w="100%"
        >
          <Flex
            align="center"
            direction="row"
            justify="flex-start"
            wrap="nowrap"
            miw={140}
          >
            <Flex align="center" mr="xs" miw={16} mt={2}>
              {icon}
            </Flex>
            <Title order={order} size={size} m={0}>
              {title}
            </Title>
          </Flex>

          {children}
        </Flex>

        {isNew && (
          <Box>
            <Badge size="sm" radius="sm" variant="filled" bg="brand.4">
              New
            </Badge>
          </Box>
        )}
      </Flex>

      {extraContent && <Box>{extraContent}</Box>}
    </Flex>
  );
}
