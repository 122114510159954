import { PLAN_STARTER } from "@common/config/plan/constants";
import { captureException } from "@sentry/nextjs";
import { CreateInstanceDataType } from "@stores/purchases/types";
import { IInstanceSetupData } from "./types";
import { isDWHPlan } from "@stores/plans/utils";

export function getPlanAlias(planAlias: string): string {
  return planAlias.replace("-cloud", "").replace("-self-hosted", "");
}

export function getCreateInstanceData({
  isDWH,
  planObj,
  isSelfHosted,
  isAnnualBilling,
  dnsAlias,
  region,
}: IInstanceSetupData): CreateInstanceDataType | null {
  if (planObj) {
    const dwhStr = isDWH ? "-with-dwh" : "";
    const deploymentStr = isSelfHosted ? "-self-hosted" : "-cloud";
    const billingStr = isAnnualBilling ? "-yearly" : "";

    return {
      plan_alias:
        planObj.slug !== PLAN_STARTER && !isDWHPlan(planObj.slug)
          ? `${planObj.slug}${deploymentStr}${dwhStr}${billingStr}`
          : isDWHPlan(planObj.slug)
          ? `${planObj.slug}${billingStr}`
          : `${planObj.slug}${dwhStr}${billingStr}`,
      dns_alias: dnsAlias,
      region,
    };
  } else {
    captureException(new Error("InstanceSetup: planObj is undefined"));
  }

  return null;
}
