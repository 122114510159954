import { FC, createContext, useContext, useMemo } from "react";
import {
  QueryFunctionContext,
  useQuery as reactQueryUseQuery,
  useMutation as reactQueryUseMutation,
  QueryClientProvider as ReactQueryQueryClientProvider,
  UseQueryOptions,
  UseQueryResult,
  UseMutationResult,
  UseMutationOptions,
  QueryClient,
} from "react-query";
import {
  IDataFetcherResponseError,
  IUseQueryContext,
  MutationKey,
  NormalizedQueryKey,
  QueryClientProviderProps,
  QueryKey,
} from "./types";
import { dataFetcher, getAbsoluteUrl, normalizeQueryKey } from "./utils";

const useQueryContext = createContext<IUseQueryContext>({});

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
>(
  queryKey: QueryKey,
  options: Omit<
    UseQueryOptions<
      TQueryFnData,
      IDataFetcherResponseError<TError>,
      TData,
      NormalizedQueryKey
    >,
    "queryKey" | "queryFn"
  > = {},
): UseQueryResult<TData, IDataFetcherResponseError<TError>> {
  const { baseUrl, onError } = useContext(useQueryContext);

  return reactQueryUseQuery(
    normalizeQueryKey(queryKey),
    async function useQueryFetcher({
      queryKey,
    }: QueryFunctionContext<NormalizedQueryKey>) {
      const [, context] = queryKey;
      const { url, method, params } = !context
        ? { url: queryKey[0], method: "GET", params: {} }
        : context;

      const absoluteUrl = getAbsoluteUrl(url, { baseUrl, params });
      const { success, error, data } = await dataFetcher(absoluteUrl, {
        method,
        onError,
      });

      if (success) {
        return data;
      }

      throw error;
    },
    { refetchOnWindowFocus: false, ...options },
  );
}

export function useMutation<
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown,
>(
  mutationKey: MutationKey,
  options?: Omit<
    UseMutationOptions<
      TData,
      IDataFetcherResponseError<TError>,
      TVariables,
      TContext
    >,
    "mutationFn"
  >,
): UseMutationResult<
  TData,
  IDataFetcherResponseError<TError>,
  TVariables,
  TContext
> {
  const { url, method, params } = mutationKey;
  const { baseUrl, onError } = useContext(useQueryContext);
  const absoluteUrl = getAbsoluteUrl(url, { baseUrl, params });

  return reactQueryUseMutation(async function useMutationFetcher(body: any) {
    const { success, data, error } = await dataFetcher(absoluteUrl, {
      method,
      body,
      onError,
    });

    if (success) {
      return data;
    }

    throw error;
  }, options);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

export const QueryClientProvider: FC<QueryClientProviderProps> = ({
  baseUrl,
  onError,
  ...props
}) => {
  const contextValue = useMemo(() => {
    return {
      baseUrl,
      onError,
    };
  }, [baseUrl, onError]);

  return (
    <useQueryContext.Provider value={contextValue}>
      <ReactQueryQueryClientProvider client={queryClient} {...props} />
    </useQueryContext.Provider>
  );
};
