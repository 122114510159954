import { ILogo } from "./types";
import { ReactElement } from "react";
import { useLogoWrapperStyles, useLogoIconStyles } from "./Logo.styled";

export default function Logo({ height, width }: ILogo): ReactElement {
  const { classes: logoWrapperClasses } = useLogoWrapperStyles();
  const { classes: logoIconClasses } = useLogoIconStyles({ height, width });

  return (
    <div className={logoWrapperClasses.root}>
      <svg
        className={logoIconClasses.root}
        height={height}
        width={width}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7955 17.1222C12.0977 17.1222 13.1534 16.0595 13.1534 14.7486C13.1534 13.4377 12.0977 12.375 10.7955 12.375C9.49319 12.375 8.4375 13.4377 8.4375 14.7486C8.4375 16.0595 9.49319 17.1222 10.7955 17.1222Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7955 23.7682C12.0977 23.7682 13.1534 22.7055 13.1534 21.3946C13.1534 20.0837 12.0977 19.021 10.7955 19.021C9.49319 19.021 8.4375 20.0837 8.4375 21.3946C8.4375 22.7055 9.49319 23.7682 10.7955 23.7682Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3975 23.8329C18.6998 23.8329 19.7555 22.7702 19.7555 21.4593C19.7555 20.1484 18.6998 19.0857 17.3975 19.0857C16.0952 19.0857 15.0396 20.1484 15.0396 21.4593C15.0396 22.7702 16.0952 23.8329 17.3975 23.8329Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.2046 17.1222C38.5069 17.1222 39.5626 16.0595 39.5626 14.7486C39.5626 13.4377 38.5069 12.375 37.2046 12.375C35.9024 12.375 34.8467 13.4377 34.8467 14.7486C34.8467 16.0595 35.9024 17.1222 37.2046 17.1222Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6021 23.8329C31.9044 23.8329 32.96 22.7702 32.96 21.4593C32.96 20.1484 31.9044 19.0857 30.6021 19.0857C29.2998 19.0857 28.2441 20.1484 28.2441 21.4593C28.2441 22.7702 29.2998 23.8329 30.6021 23.8329Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.2046 23.8329C38.5069 23.8329 39.5626 22.7702 39.5626 21.4593C39.5626 20.1484 38.5069 19.0857 37.2046 19.0857C35.9024 19.0857 34.8467 20.1484 34.8467 21.4593C34.8467 22.7702 35.9024 23.8329 37.2046 23.8329Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7955 30.4144C12.0977 30.4144 13.1534 29.3517 13.1534 28.0408C13.1534 26.7299 12.0977 25.6672 10.7955 25.6672C9.49319 25.6672 8.4375 26.7299 8.4375 28.0408C8.4375 29.3517 9.49319 30.4144 10.7955 30.4144Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 30.4789C25.3023 30.4789 26.358 29.4162 26.358 28.1053C26.358 26.7944 25.3023 25.7317 24 25.7317C22.6978 25.7317 21.6421 26.7944 21.6421 28.1053C21.6421 29.4162 22.6978 30.4789 24 30.4789Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.2046 30.4789C38.5069 30.4789 39.5626 29.4162 39.5626 28.1053C39.5626 26.7944 38.5069 25.7317 37.2046 25.7317C35.9024 25.7317 34.8467 26.7944 34.8467 28.1053C34.8467 29.4162 35.9024 30.4789 37.2046 30.4789Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7955 37.0604C12.0977 37.0604 13.1534 35.9977 13.1534 34.6868C13.1534 33.3759 12.0977 32.3132 10.7955 32.3132C9.49319 32.3132 8.4375 33.3759 8.4375 34.6868C8.4375 35.9977 9.49319 37.0604 10.7955 37.0604Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.2046 37.1249C38.5069 37.1249 39.5626 36.0622 39.5626 34.7513C39.5626 33.4404 38.5069 32.3777 37.2046 32.3777C35.9024 32.3777 34.8467 33.4404 34.8467 34.7513C34.8467 36.0622 35.9024 37.1249 37.2046 37.1249Z"
          fill="#509EE3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5559 16.9734C18.864 16.9734 19.9243 15.9156 19.9243 14.6107C19.9243 13.3059 18.864 12.248 17.5559 12.248C16.2479 12.248 15.1875 13.3059 15.1875 14.6107C15.1875 15.9156 16.2479 16.9734 17.5559 16.9734Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1873 16.9734C25.4953 16.9734 26.5557 15.9156 26.5557 14.6107C26.5557 13.3059 25.4953 12.248 24.1873 12.248C22.8792 12.248 21.8188 13.3059 21.8188 14.6107C21.8188 15.9156 22.8792 16.9734 24.1873 16.9734Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1873 9.4129C25.4953 9.4129 26.5557 8.35508 26.5557 7.0502C26.5557 5.74532 25.4953 4.6875 24.1873 4.6875C22.8792 4.6875 21.8188 5.74532 21.8188 7.0502C21.8188 8.35508 22.8792 9.4129 24.1873 9.4129Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1873 23.6534C25.4953 23.6534 26.5557 22.5956 26.5557 21.2907C26.5557 19.9858 25.4953 18.928 24.1873 18.928C22.8792 18.928 21.8188 19.9858 21.8188 21.2907C21.8188 22.5956 22.8792 23.6534 24.1873 23.6534Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8191 16.9734C32.1272 16.9734 33.1875 15.9156 33.1875 14.6107C33.1875 13.3059 32.1272 12.248 30.8191 12.248C29.5111 12.248 28.4507 13.3059 28.4507 14.6107C28.4507 15.9156 29.5111 16.9734 30.8191 16.9734Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5559 30.2689C18.864 30.2689 19.9243 29.211 19.9243 27.9062C19.9243 26.6013 18.864 25.5435 17.5559 25.5435C16.2479 25.5435 15.1875 26.6013 15.1875 27.9062C15.1875 29.211 16.2479 30.2689 17.5559 30.2689Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8191 30.2689C32.1272 30.2689 33.1875 29.211 33.1875 27.9062C33.1875 26.6013 32.1272 25.5435 30.8191 25.5435C29.5111 25.5435 28.4507 26.6013 28.4507 27.9062C28.4507 29.211 29.5111 30.2689 30.8191 30.2689Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5559 36.8843C18.864 36.8843 19.9243 35.8265 19.9243 34.5216C19.9243 33.2168 18.864 32.1589 17.5559 32.1589C16.2479 32.1589 15.1875 33.2168 15.1875 34.5216C15.1875 35.8265 16.2479 36.8843 17.5559 36.8843Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1873 36.8843C25.4953 36.8843 26.5557 35.8265 26.5557 34.5216C26.5557 33.2168 25.4953 32.1589 24.1873 32.1589C22.8792 32.1589 21.8188 33.2168 21.8188 34.5216C21.8188 35.8265 22.8792 36.8843 24.1873 36.8843Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1873 43.5001C25.4953 43.5001 26.5557 42.4422 26.5557 41.1374C26.5557 39.8325 25.4953 38.7747 24.1873 38.7747C22.8792 38.7747 21.8188 39.8325 21.8188 41.1374C21.8188 42.4422 22.8792 43.5001 24.1873 43.5001Z"
          fill="#EBF3FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8191 36.8843C32.1272 36.8843 33.1875 35.8265 33.1875 34.5216C33.1875 33.2168 32.1272 32.1589 30.8191 32.1589C29.5111 32.1589 28.4507 33.2168 28.4507 34.5216C28.4507 35.8265 29.5111 36.8843 30.8191 36.8843Z"
          fill="#EBF3FA"
        />
      </svg>
    </div>
  );
}

Logo.defaultProps = {
  height: 48,
  width: 48,
};
