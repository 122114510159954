import { Elements } from "@stripe/react-stripe-js";
import { PropsWithChildren, ReactElement } from "react";
import { ElementsOptions } from "./constants";
import { getStripe } from "./utils";

export default function StripeProvider({
  children,
}: PropsWithChildren): ReactElement {
  if (!process.env.STRIPE_KEY) {
    throw new Error("StripeProvider, `STRIPE_KEY` is missing");
  }

  return (
    <Elements
      options={ElementsOptions}
      stripe={getStripe(process.env.STRIPE_KEY)}
    >
      {children}
    </Elements>
  );
}
