import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconInfoOutline({
  color,
  size,
}: IIconProps): ReactElement {
  return (
    <svg
      height={size || 14}
      width={size || 14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6668 7.00065C13.6668 3.32606 10.6747 0.333984 7.00016 0.333984C3.32558 0.333984 0.333496 3.32606 0.333496 7.00065C0.333496 10.6752 3.32558 13.6673 7.00016 13.6673C10.6747 13.6673 13.6668 10.6752 13.6668 7.00065ZM1.40016 7.00065C1.40016 3.91517 3.91468 1.40065 7.00016 1.40065C10.0856 1.40065 12.6002 3.91517 12.6002 7.00065C12.6002 10.0861 10.0856 12.6007 7.00016 12.6007C3.91468 12.6007 1.40016 10.0861 1.40016 7.00065ZM6.38288 3.29695V4.53152H7.61745V3.29695H6.38288ZM6.38288 10.0871V5.76608H7.61745V10.0871H6.38288Z"
        fill={color || "#4C5773"}
      />
    </svg>
  );
}
