import { ReactElement } from "react";

export default function IconTag(): ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="12" height="12" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.09765 1.5C1.14012 1.5 0 2.27622 0 3.23375V9.0129C0 9.97043 1.14012 10.7467 2.09765 10.7467H7.6434C8.33951 10.7467 8.99854 10.433 9.43751 9.89272L11.6118 7.21661C12.1294 6.57964 12.1294 5.66704 11.6118 5.03003L9.43751 2.35395C8.99854 1.81369 8.33951 1.5 7.6434 1.5H2.09765ZM7.8768 6.12337C7.8768 6.60214 8.26493 6.99026 8.74369 6.99026C9.22245 6.99026 9.61054 6.60214 9.61054 6.12337C9.61054 5.64461 9.22245 5.25649 8.74369 5.25649C8.26493 5.25649 7.8768 5.64461 7.8768 6.12337Z"
        fill="#4C5773"
      />
    </svg>
  );
}
