import { ReactElement } from "react";
import { Flex, Text } from "@mantine/core";
import { customerStore } from "@stores/customer";
import { IPONumberDetailsProps } from "./types";
import ButtonAsText from "@common/components/thirdParty/mantine/ButtonAsText";
import DisabledContainer from "@common/components/layout/DisabledContainer";
import ErrorMessage from "@common/components/text/ErrorMessage";

export default function PONumberDetails({
  purchaseOrderNumber,
}: IPONumberDetailsProps): ReactElement {
  const {
    isLoading,
    error,
    refetch: deletePONumber,
  } = customerStore.useDeletePONumber();

  return (
    <Flex data-testid="PONumberDetails" align="baseline" direction="row">
      <Text fz="sm" mr="sm">
        {purchaseOrderNumber}
      </Text>

      <DisabledContainer disabled={isLoading}>
        <ButtonAsText
          // So we do not have a HTML hierarchy issue
          // with buttons inside button
          component="div"
          onClick={(e) => {
            e.stopPropagation();
            deletePONumber();
          }}
          disabled={isLoading}
        >
          Remove
        </ButtonAsText>
      </DisabledContainer>

      {error && <ErrorMessage ml="sm">{error.message}</ErrorMessage>}
    </Flex>
  );
}
