import { ReactElement, useEffect, useState } from "react";
import {
  Accordion,
  Anchor,
  Button,
  Container,
  Flex,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import AccordionCustom from "@common/components/thirdParty/mantine/accordion/AccordionCustom";
import AccordionHeader from "@common/components/thirdParty/mantine/accordion/AccordionHeader";
import AccordionSection from "@common/components/thirdParty/mantine/accordion/AccordionSection";
import { usePaymentMethodsStore } from "@stores/payment-methods";
import { billingStore } from "@stores/billing";
import UpdatePaymentMethodForm from "@components/forms/payment/UpdatePaymentMethodForm";
import StripeProvider from "@components/thirdParty/stripe/StripeProvider";
import CreateTaxIdForm from "@components/forms/billing/taxId/CreateTaxIdForm";
import TaxIdsList from "@components/forms/billing/taxId/TaxIdsList";
import InvoicesList from "@components/forms/billing/invoice/InvoicesList";
import { pluralize } from "@common/utils/string";
import { IInvoice } from "@stores/billing/types";
import TitleWithIcon from "@common/components/thirdParty/mantine/TitleWithIcon";
import IconInvoicing from "@common/components/icons/IconInvoicing";
import IconTag from "@common/components/icons/IconTag";
import IconCreditCard from "@common/components/icons/IconCreditCard";
import { useSessionStore } from "@stores/session";
import ConfirmationModal from "@components/account/invoicing/ConfirmationModal";
import { useDisclosure } from "@mantine/hooks";
import AccordionControlLink from "@common/components/thirdParty/mantine/accordion/AccordionControlLink";
import { persistUTMQueryParamsToString } from "@common/utils/router";
import { useRouter } from "next/router";
import { BILLING_METHOD_INVOICE_TYPE } from "@stores/session/constants";
import { usePurchasesStore } from "@stores/purchases";
import { IDataFetcherResponseError } from "@common/utils/use-query/types";
import CreatePaymentMethodForm from "@components/forms/payment/CreatePaymentMethodForm";
import BillingAddressForm from "@components/forms/billing/address/BillingAddressForm";
import { customerStore } from "@stores/customer";
import { formatAddress } from "@stores/customer/utils";
import WarningCard from "@common/components/thirdParty/mantine/WarningCard";
import Link from "next/link";
import PONumberForm from "@components/forms/billing/poNumber/PONumberForm";
import PONumberDetails from "@components/forms/billing/poNumber/PONumberDetails";
import { configStore } from "@stores/config";
import ShippingAddressForm from "@components/forms/billing/address/ShippingAddressForm";
import IconHome from "@common/components/icons/IconHome";
import IconPin from "@common/components/icons/IconPin";
import IconPage from "@common/components/icons/IconPage";
import InfoCard from "@common/components/thirdParty/mantine/InfoCard";
import ErrorMessage from "@common/components/text/ErrorMessage";

export const SECTION_PAYMENT_METHOD = "payment-method";
const SECTION_INVOICING = "switch-to-invoicing";
export const SECTION_BILLING_ADDRESS = "billing-address";
export const SECTION_SHIPPING_ADDRESS = "shipping-address";
const SECTION_NEW_TAX_ID = "new-tax-id";
const SECTION_PO_NUMBER = "po-number";
const SECTION_INVOICES_LIST_PREFIX = "invoices-";
const SECTION_INVOICES_LIST = `${SECTION_INVOICES_LIST_PREFIX}*`;
const SECTIONS = [
  SECTION_PAYMENT_METHOD,
  SECTION_INVOICING,
  SECTION_BILLING_ADDRESS,
  SECTION_SHIPPING_ADDRESS,
  SECTION_NEW_TAX_ID,
  SECTION_PO_NUMBER,
  SECTION_INVOICES_LIST,
];

export const SHOW_SWITCH_TO_INVOICING_BUTTON = "sstib";

export default function ManageAccountBillingPage(): ReactElement {
  const router = useRouter();

  // show the switch to invoicing button
  // IF on localhost
  // AND query param
  const { query } = router;
  const { [SHOW_SWITCH_TO_INVOICING_BUTTON]: showSwitchToInvoicing } = query;
  const showSwitchToInvoicingButton =
    window.location.host.includes("localhost") && !!showSwitchToInvoicing;

  // session
  const { loading: accountLoading, account } = useSessionStore((state) => ({
    loading: state.loading,
    account: state.account,
  }));

  // config
  const { data: config } = configStore.useGetConfig();

  // customer
  const { isLoading: customerDetailsLoading, data: customerDetails } =
    customerStore.useGetDetails();

  // payment
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false);
  const { defaultPaymentMethod, getPaymentMethods } = usePaymentMethodsStore(
    (state) => ({
      defaultPaymentMethod: state.defaultPaymentMethod,
      getPaymentMethods: state.getPaymentMethods,
    }),
  );

  useEffect(() => {
    async function getPaymentMethodsAsync() {
      setPaymentMethodsLoading(true);
      await getPaymentMethods();
      setPaymentMethodsLoading(false);
    }
    getPaymentMethodsAsync();
  }, [getPaymentMethods]);

  // purchases
  const [theLoadingPurchases, setTheLoadingPurchases] = useState(false);
  const [theErrorPurchases, setTheErrorPurchases] =
    useState<IDataFetcherResponseError | null>(null);
  const { purchases, getPurchases } = usePurchasesStore((state) => ({
    purchases: state.purchases,
    getPurchases: state.getPurchases,
  }));
  useEffect(() => {
    async function getPurchasesAsync() {
      setTheLoadingPurchases(true);
      const { success, error } = await getPurchases();
      setTheErrorPurchases(success ? null : error);
      setTheLoadingPurchases(false);
    }

    if (account?.is_eligible_for_invoicing) {
      getPurchasesAsync();
    }
  }, [getPurchases, account]);

  // invoicing
  const [
    invoicingModalOpened,
    { open: openInvoicingModal, close: closeInvoicingModal },
  ] = useDisclosure(false);

  // tax ids
  const { isLoading: isTaxIDsLoading, data: taxIds } =
    billingStore.useGetTaxIds();

  // invoices
  const {
    isLoading: isInvoicesLoading,
    error: invoicesLoadingError,
    data: invoices = [],
  } = billingStore.useGetInvoices();
  const invoicesByYear: Record<string, IInvoice[]> = invoices.reduce(
    (group, invoiceObj) => {
      const { created } = invoiceObj;
      const [category] = created.split("-");
      group[category] = group[category] ?? [];
      group[category].push(invoiceObj);

      return group;
    },
    {},
  );

  const isPaymentMethodSectionLoading =
    accountLoading || paymentMethodsLoading || customerDetailsLoading;

  return (
    <Container>
      <Stack spacing="lg">
        {/* Details */}
        <AccordionCustom sections={SECTIONS}>
          <AccordionHeader title="Payment details" />

          {/* payment method */}
          {account?.billing_method === BILLING_METHOD_INVOICE_TYPE ? (
            <AccordionSection>
              <TitleWithIcon
                icon={<IconCreditCard />}
                title={"Payment method"}
                order={2}
                size="h6"
              >
                <Text fz="sm" m={0}>
                  Invoicing
                </Text>
              </TitleWithIcon>
            </AccordionSection>
          ) : (
            <Accordion.Item value={SECTION_PAYMENT_METHOD}>
              <Accordion.Control>
                <TitleWithIcon
                  icon={<IconCreditCard />}
                  title={"Payment method"}
                  order={2}
                  size="h6"
                >
                  {isPaymentMethodSectionLoading ? (
                    <Loader size="sm" />
                  ) : (
                    defaultPaymentMethod && (
                      <Text fz="sm" m={0}>
                        {defaultPaymentMethod.type} ending in{" "}
                        {defaultPaymentMethod.last4} (
                        {defaultPaymentMethod.expiration})
                      </Text>
                    )
                  )}
                </TitleWithIcon>
              </Accordion.Control>
              <Accordion.Panel>
                {isPaymentMethodSectionLoading && <Loader mt="xs" />}
                {!isPaymentMethodSectionLoading && account && (
                  <StripeProvider>
                    {defaultPaymentMethod ? (
                      <UpdatePaymentMethodForm account={account} />
                    ) : (
                      <CreatePaymentMethodForm account={account} />
                    )}
                  </StripeProvider>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          )}

          {defaultPaymentMethod && (
            <>
              <Accordion.Item value={SECTION_BILLING_ADDRESS}>
                <Accordion.Control data-testid="billing-address_accordion-section">
                  <TitleWithIcon
                    icon={<IconHome />}
                    title="Billing address"
                    order={2}
                    size="h6"
                  >
                    {customerDetails?.billing_address && config && (
                      <Text fz="sm" m={0}>
                        {formatAddress(
                          customerDetails.billing_address,
                          config.permitted_countries,
                        )}
                      </Text>
                    )}
                  </TitleWithIcon>
                </Accordion.Control>
                <Accordion.Panel>
                  <BillingAddressForm />
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item value={SECTION_SHIPPING_ADDRESS}>
                <Accordion.Control data-testid="shipping-address_accordion-section">
                  <TitleWithIcon
                    icon={<IconPin />}
                    title="Shipping address"
                    order={2}
                    size="h6"
                  >
                    {customerDetails?.shipping_address && config && (
                      <Text fz="sm" m={0}>
                        {formatAddress(
                          customerDetails.shipping_address,
                          config.permitted_countries,
                        )}
                      </Text>
                    )}
                  </TitleWithIcon>
                </Accordion.Control>
                <Accordion.Panel>
                  <ShippingAddressForm />
                </Accordion.Panel>
              </Accordion.Item>
            </>
          )}

          {/* tax ids */}
          <Accordion.Item value={SECTION_NEW_TAX_ID}>
            <Accordion.Control>
              <TitleWithIcon
                icon={<IconPage />}
                title="Invoice Tax IDs"
                order={2}
                size="h6"
              >
                {isTaxIDsLoading ? (
                  <Loader size="sm" />
                ) : (
                  taxIds && taxIds.length > 0 && <TaxIdsList taxIds={taxIds} />
                )}
              </TitleWithIcon>
            </Accordion.Control>
            <Accordion.Panel>
              {isTaxIDsLoading ? <Loader mt="xs" /> : <CreateTaxIdForm />}
            </Accordion.Panel>
          </Accordion.Item>

          {/* PO number */}
          <Accordion.Item value={SECTION_PO_NUMBER}>
            <Accordion.Control>
              <TitleWithIcon
                icon={<IconTag />}
                title="PO number"
                order={2}
                size="h6"
              >
                {customerDetailsLoading ? (
                  <Loader size="sm" />
                ) : (
                  customerDetails?.purchase_order_number && (
                    <PONumberDetails
                      purchaseOrderNumber={
                        customerDetails.purchase_order_number
                      }
                    />
                  )
                )}
              </TitleWithIcon>
            </Accordion.Control>
            <Accordion.Panel>
              <PONumberForm />
            </Accordion.Panel>
          </Accordion.Item>

          {/* Switch to invoicing */}
          {account?.billing_method !== BILLING_METHOD_INVOICE_TYPE &&
            account?.is_eligible_for_invoicing && (
              <>
                <Accordion.Item
                  data-testid="SwitchToInvoicing"
                  value={SECTION_INVOICING}
                >
                  <Accordion.Control>
                    <TitleWithIcon
                      icon={<IconInvoicing />}
                      title="Switch to invoicing"
                      order={2}
                      size="h6"
                    />
                  </Accordion.Control>
                  <Accordion.Panel>
                    {!customerDetails?.billing_address && (
                      <WarningCard mb="sm">
                        <Text c="gray.6" fz="sm">
                          To switch to invoicing, you&apos;ll need to{" "}
                          <Link
                            href={persistUTMQueryParamsToString(
                              router,
                              defaultPaymentMethod
                                ? `/account/manage/billing#section=${SECTION_BILLING_ADDRESS}`
                                : `/account/manage/billing#section=${SECTION_PAYMENT_METHOD}`,
                            )}
                          >
                            add a billing address
                          </Link>
                          .
                        </Text>
                      </WarningCard>
                    )}
                    <InfoCard withBorder>
                      <Text c="gray.4" fz="xs">
                        Receive an invoice and pay with ACH or wire transfer.{" "}
                        <Anchor
                          href={persistUTMQueryParamsToString(
                            router,
                            "https://www.metabase.com/docs/latest/cloud/how-billing-works#invoicing-for-pro-plans",
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Learn more about invoicing
                        </Anchor>
                        .
                      </Text>
                    </InfoCard>
                    <Flex
                      align="center"
                      direction="row"
                      justify="flex-start"
                      gap="md"
                    >
                      {showSwitchToInvoicingButton ? (
                        <>
                          <Button
                            data-testid="SwitchToInvoicing-button"
                            mt="md"
                            onClick={() => openInvoicingModal()}
                            loading={theLoadingPurchases}
                            loaderPosition="right"
                            disabled={
                              !customerDetails?.billing_address ||
                              theLoadingPurchases
                            }
                          >
                            Switch to invoicing
                          </Button>
                          {theErrorPurchases && (
                            <ErrorMessage>
                              {theErrorPurchases.message}
                            </ErrorMessage>
                          )}
                        </>
                      ) : (
                        <Button
                          data-testid="switch-to-invoicing-button"
                          component={Link}
                          href="mailto:billing@metabase.com"
                          mt="md"
                        >
                          Contact us
                        </Button>
                      )}
                    </Flex>
                  </Accordion.Panel>
                </Accordion.Item>
                <ConfirmationModal
                  purchases={purchases}
                  opened={invoicingModalOpened}
                  onClose={closeInvoicingModal}
                />
              </>
            )}

          {/* invoicing */}
          {account?.billing_method === BILLING_METHOD_INVOICE_TYPE && (
            <>
              <Accordion.Item value={SECTION_PAYMENT_METHOD}>
                <Accordion.Control>
                  <TitleWithIcon
                    icon={<IconInvoicing />}
                    title="Change payment method to credit card"
                    order={2}
                    size="h6"
                  />
                </Accordion.Control>
                <Accordion.Panel>
                  <Text fz="sm">
                    To switch back to paying with a credit card,{" "}
                    <Anchor href="mailto:billing@metabase.com?subject=Switch from invoicing to credit card">
                      contact us
                    </Anchor>
                    .
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>

              <AccordionSection>
                <AccordionControlLink
                  icon={<IconInvoicing />}
                  title="Add additional billing contacts"
                  href={persistUTMQueryParamsToString(
                    router,
                    `/account/manage/settings#section=contacts`,
                  )}
                />
              </AccordionSection>
            </>
          )}
        </AccordionCustom>

        {/* invoices list */}
        <AccordionCustom sections={SECTIONS}>
          {!isInvoicesLoading &&
          !invoicesLoadingError &&
          invoices.length === 0 ? (
            <AccordionHeader title="You don't have any invoices yet">
              <Text fz="sm" mr="xs">
                When you&apos;re billed, they&apos;ll show up here.
              </Text>
            </AccordionHeader>
          ) : (
            <>
              <AccordionHeader title="Invoices" loading={isInvoicesLoading}>
                <Text fz="sm" mr="xs">
                  {invoices.length ? (
                    <>
                      You have {invoices.length}{" "}
                      {pluralize("invoice", invoices.length)}.
                    </>
                  ) : (
                    "No invoices."
                  )}
                </Text>
              </AccordionHeader>

              {!isInvoicesLoading &&
                Object.keys(invoicesByYear)
                  .sort()
                  .reverse()
                  .map((year, index) => (
                    <Accordion.Item
                      key={index}
                      value={`${SECTION_INVOICES_LIST_PREFIX}${year}`}
                    >
                      <Accordion.Control>
                        <Title order={2} size="h6">
                          {year}
                        </Title>
                      </Accordion.Control>
                      <Accordion.Panel>
                        <InvoicesList invoices={invoicesByYear[year]} />
                      </Accordion.Panel>
                    </Accordion.Item>
                  ))}
            </>
          )}
          {invoicesLoadingError && (
            <AccordionHeader title="Invoices">
              <ErrorMessage>
                {invoicesLoadingError.message || invoicesLoadingError.status}
              </ErrorMessage>
            </AccordionHeader>
          )}
        </AccordionCustom>
      </Stack>
    </Container>
  );
}
