export const API_URL = "https://get.geojs.io/v1/ip/country.json";

export const COUNTRIES_WITH_COOKIE_POLICY_RESTRICTION = [
  { name: "Austria", country: "AT", country_3: "AUT" },
  { name: "Belgium", country: "BE", country_3: "BEL" },
  { name: "Bulgaria", country: "BG", country_3: "BGR" },
  { name: "Croatia", country: "HR", country_3: "HRV" },
  { name: "Cyprus", country: "CY", country_3: "CYP" },
  { name: "Czech Republic", country: "CZ", country_3: "CZE" },
  { name: "Denmark", country: "DK", country_3: "DNK" },
  { name: "Estonia", country: "EE", country_3: "EST" },
  { name: "Finland", country: "FN", country_3: "FIN" },
  { name: "France", country: "FR", country_3: "FRA" },
  { name: "Germany", country: "DE", country_3: "DEU" },
  { name: "Greece", country: "GR", country_3: "GRC" },
  { name: "Hungary", country: "HU", country_3: "HUN" },
  { name: "Ireland", country: "IE", country_3: "IRL" },
  { name: "Italy", country: "IT", country_3: "ITA" },
  { name: "Latvia", country: "LV", country_3: "LVA" },
  { name: "Lithuania", country: "LT", country_3: "LTU" },
  { name: "Luxembourg", country: "LU", country_3: "LUX" },
  { name: "Malta", country: "MT", country_3: "MLT" },
  { name: "The Netherlands", country: "NL", country_3: "NLD" },
  { name: "Poland", country: "PL", country_3: "POL" },
  { name: "Portugal", country: "PT", country_3: "PRT" },
  { name: "Romania", country: "RO", country_3: "ROU" },
  { name: "Slovakia", country: "SK", country_3: "SVK" },
  { name: "Slovenia", country: "SI", country_3: "SVN" },
  { name: "Spain", country: "ES", country_3: "ESP" },
  { name: "Sweden", country: "SE", country_3: "SWE" },
  { name: "United Kingdom", country: "GB", country_3: "GBR" },
  { name: "Switzerland", country: "CH", country_3: "CHE" },
  { name: "Brazil", country: "BR", country_3: "BRA" },
];
