import { ReactElement } from "react";
import { ITaxIdsProps } from "./types";
import TaxIdRow from "./TaxIdsList.components";
import { Stack, useMantineTheme } from "@mantine/core";

export default function TaxIdsList({
  taxIds,
}: ITaxIdsProps): ReactElement | null {
  const theme = useMantineTheme();

  if (taxIds.length > 0) {
    return (
      <Stack data-testid="TaxIdsList" spacing={theme.spacing.xs / 2}>
        {taxIds.map((taxId) => (
          <TaxIdRow key={taxId.id} taxId={taxId} />
        ))}
      </Stack>
    );
  }

  return null;
}
