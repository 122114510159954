import { ReactElement } from "react";
import { IIconProps } from "../types";

export default function IconHome({ color, size }: IIconProps): ReactElement {
  return (
    <svg
      height={size || 16}
      width={size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1L1 6.6L2.47368 15H6.15789V11.15C6.15789 10.1835 6.98263 9.4 8 9.4C9.01737 9.4 9.8421 10.1835 9.8421 11.15V15H13.5263L15 6.6L8 1Z"
        fill={color || "#4C5773"}
      />
    </svg>
  );
}
