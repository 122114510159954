import {
  DATE_FORMAT_COMPACT,
  DATE_FORMAT_COMPACT_WITH_TIME,
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_LONG,
  DATE_FORMAT_LONG_WITH_TIME,
  DATE_FORMAT_SHORT,
  DATE_FORMAT_WITH_TIME,
} from "./constants";
import { DateFormatType, IFormatDateOptions } from "./types";
import { isBrowser } from "@common/utils/browser";
import { getISODateWithOffset, normalize } from "@common/utils/date";

export function getDaysCountBetweenDates(
  date1: Date | string | number,
  date2?: Date | string | number,
): number {
  date1 = normalize(date1);
  date2 = normalize(date2 || Date.now());
  const diffInTime =
    date2 > date1
      ? date2.getTime() - date1.getTime()
      : date1.getTime() - date2.getTime();

  return Math.round(diffInTime / (1000 * 3600 * 24));
}

export function formatDate(
  date: Date | string | number,
  format?: IFormatDateOptions | DateFormatType,
): string {
  const formattedDate = normalize(date);

  const locale = isBrowser() ? window.navigator.language : "en-US";

  // for now since we're not translated
  // we'll only do longer text strings for english
  const options: Intl.DateTimeFormatOptions =
    normalizeFormat(format) || (locale === "en-US" ? DATE_FORMAT_DEFAULT : {});

  return formattedDate.toLocaleDateString(locale, options);
}

function normalizeFormat(
  format: DateFormatType | IFormatDateOptions | undefined,
): IFormatDateOptions | undefined {
  if (format === "default") {
    return DATE_FORMAT_DEFAULT;
  }

  if (format === "short") {
    return DATE_FORMAT_SHORT;
  }

  if (format === "with-time") {
    return DATE_FORMAT_WITH_TIME;
  }

  if (format === "compact") {
    return DATE_FORMAT_COMPACT;
  }

  if (format === "compact-with-time") {
    return DATE_FORMAT_COMPACT_WITH_TIME;
  }

  if (format === "long") {
    return DATE_FORMAT_LONG;
  }

  if (format === "long-with-time") {
    return DATE_FORMAT_LONG_WITH_TIME;
  }

  return format;
}

export function getTrialEndDate(
  date: Date | string | number,
  format?: IFormatDateOptions | DateFormatType,
): string {
  const dateStr = getISODateWithOffset(date);
  return formatDate(dateStr, format);
}
