import { ReactElement, useEffect, useCallback } from "react";
import Script from "next/script";
import { useGDPRStore } from "@stores/gdpr";
import { captureException } from "@sentry/nextjs";
import { isDevelopment } from "@utils/config";
import { getCookie, setCookie } from "@utils/cookie";
import {
  GDPR_NOTICE_TIMEOUT,
  COOKIE_POLICY_LOCALES,
  GDPR_COOKIE_NAME,
} from "./constants";
import useGeoLocation from "@common/hooks/useGeoLocation";

// https://github.com/passatgt/gdpr-cookie-notice/blob/master/dist/script.js
// https://github.com/metabase/metabase.github.io/blob/master/js/cookie-consent.js
export default function GDPRCookieNotice(): ReactElement {
  const { setMarketing, setAnalytics, setPerformance } = useGDPRStore(
    (state) => ({
      setMarketing: state.setMarketing,
      setAnalytics: state.setAnalytics,
      setPerformance: state.setPerformance,
    }),
  );

  // geolocation
  const geolocationObj = useGeoLocation();
  useEffect(() => {
    // all cookies set to true
    // if there is no restriction
    if (geolocationObj && !geolocationObj.hasCookiePolicyRestriction) {
      setMarketing(true);
      setAnalytics(true);
      setPerformance(true);
    }
  }, [geolocationObj, setMarketing, setAnalytics, setPerformance]);

  // resize
  const handleWindowResize = useCallback(() => {
    const timeoutId = setTimeout(() => {
      const $gdprBar = document.querySelector(".gdpr-cookie-notice");
      document.body.style.paddingBottom = $gdprBar
        ? `${$gdprBar.getBoundingClientRect().height}px`
        : "0";
    }, GDPR_NOTICE_TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, []);

  // GDPR event
  const handleGDPRCookiesEvent = useCallback(
    (e: Event) => {
      const { detail } = e as CustomEvent;
      const { marketing, analytics, performance } = detail;

      setMarketing(marketing);
      setAnalytics(analytics);
      setPerformance(performance);

      // development only
      if (isDevelopment()) {
        setCookie(GDPR_COOKIE_NAME, true);
      }

      const timeoutId = setTimeout(() => {
        const $html = document.documentElement;

        // remove GDPR bar
        $html.classList.remove("gdpr-cookie-notice-loaded");
        const $gdprBar = document.querySelector(".gdpr-cookie-notice");
        const $gdprBarParent = $gdprBar?.parentNode;
        if ($gdprBar && $gdprBarParent) {
          $gdprBarParent.removeChild($gdprBar);
        }

        // remove GDPR modal
        $html.classList.remove("gdpr-cookie-notice-show-modal");
        const gdprModal = document.querySelector(".gdpr-cookie-notice-modal");
        const gdprModalParent = $gdprBar?.parentNode;
        if (gdprModal && gdprModalParent) {
          gdprModalParent.removeChild(gdprModal);
        }

        // remove padding
        handleWindowResize();
      }, GDPR_NOTICE_TIMEOUT);

      return () => clearTimeout(timeoutId);
    },
    [setMarketing, setAnalytics, setPerformance, handleWindowResize],
  );

  // listening to window resizing and GDPR event
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    document.addEventListener("gdprCookiesEnabled", handleGDPRCookiesEvent);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      document.removeEventListener(
        "gdprCookiesEnabled",
        handleGDPRCookiesEvent,
      );
    };
  }, [handleWindowResize, handleGDPRCookiesEvent]);

  // first resize
  useEffect(() => {
    const timeoutId = setTimeout(handleWindowResize, GDPR_NOTICE_TIMEOUT * 2);
    return () => clearTimeout(timeoutId);
  }, [handleWindowResize]);

  // development only
  if (isDevelopment() && getCookie(GDPR_COOKIE_NAME)) {
    return <></>;
  }

  // has geolocation?
  if (!geolocationObj) {
    return <></>;
  }

  return (
    <Script
      id="gdpr-lib"
      type="text/javascript"
      src="/gdpr-cookie-notice/script.js"
      onLoad={() => {
        try {
          if (window.gdprCookieNotice) {
            // locales
            window.gdprCookieNoticeLocales = COOKIE_POLICY_LOCALES;

            // initialize plugin
            window.gdprCookieNotice({
              statement: "https://www.metabase.com/privacy-policies",
              performance: ["JSESSIONID"],
              analytics: ["_gat", "ga"],
              domain: ".metabase.com",
              marketing: ["SSID"],
              timeout: GDPR_NOTICE_TIMEOUT,
            });
          } else {
            captureException(
              new Error("gdpr-cookie-notice/script.js not loaded"),
            );
          }
        } catch (err) {
          captureException(err);
        }
      }}
    />
  );
}
