import { isDevelopment } from "@utils/config";
import { captureException, captureMessage } from "@sentry/nextjs";
import { request } from "./utils";
import { formatError, parseResponse } from "@common/utils/use-query/utils";
import {
  IDataFetcherOptions,
  IDataFetcherResponse,
} from "@common/utils/use-query/types";
import { DEFAULT_ERROR_MESSAGE } from "@common/utils/use-query/constants";

// API request
// no JSON call as sometime there's no response body
export async function dataFetcher(
  url: string,
  options?: IDataFetcherOptions,
): Promise<IDataFetcherResponse> {
  try {
    // body
    // from `object` to `string`
    const body: string | undefined = options?.body
      ? JSON.stringify(options.body)
      : undefined;

    // request
    const response: Response = await request(url, options?.method, body);

    // response
    const data = await parseResponse(response);

    // valid response
    if (response?.ok) {
      return {
        success: true,
        error: null,
        data,
        url: response.url,
      };
    }
    // throw error
    // with status as string
    else {
      const error = formatError(data || {}, response);

      // We do not capture exception with Sentry
      // if it is trying to load the account details
      const isNotLoggedIn = !!(
        url.indexOf("api/v2/account") > -1 &&
        (options?.method === "GET" || options?.method === undefined) &&
        error.message === "Not Found"
      );
      if (!isNotLoggedIn) {
        if (isDevelopment()) {
          console.error(`data, dataFetcher error for ${url}: ${error.message}`);
        }

        // Capture exception with Sentry
        const errorMessage = `${error.status} - ${error.message}`;

        // Errors are formatted on HM side:
        // - shoppe.controller.user/create-user!
        // - harbormaster.backend.controller.user/update!
        if (
          (error.status === 409 &&
            error.message ===
              "A user with that email address already exists.") ||
          (error.status === 400 &&
            error.message === "The specified email address is already in use.")
        ) {
          captureMessage(errorMessage);
        } else {
          captureException(errorMessage);
        }
      }

      return {
        success: false,
        error,
        data,
        url: response.url,
      };
    }
  } catch (error: any) {
    // Capture error with Sentry
    captureException(error);

    if (error.message === "Failed to fetch") {
      return {
        success: false,
        error: {
          message: `${DEFAULT_ERROR_MESSAGE}. Please try again in a couple of minutes.`,
          status: 503,
        },
        data: null,
        url,
      };
    }

    return {
      success: false,
      error: { message: error.message, status: 400 },
      data: null,
      url,
    };
  }
}
