import { PLANS_LIST } from "@common/config/plan";
import { ReactElement } from "react";
import {
  Badge,
  Flex,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import IconArrowRight from "@common/components/icons/IconArrowRight";
import { IPurchaseRowProps } from "./types";
import { usePurchaseRowStyles } from "./ConfirmationModal.styled";
import { captureException } from "@sentry/nextjs";
import { getPlanAlias } from "@components/checkout/steps/InstanceSetup/utils";

export function PurchaseRow({
  index,
  purchase,
}: IPurchaseRowProps): ReactElement {
  const { id, hosted_instance, product } = purchase;
  const { plan } = product;

  // styles
  const theme = useMantineTheme();
  const { classes } = usePurchaseRowStyles();

  // purchase
  const purchasePlan = PLANS_LIST.find(
    ({ slug }) => slug === getPlanAlias(plan.alias),
  );

  // no matching plan
  if (!purchasePlan) {
    captureException(
      `Invoicing, ConfirmationModal: no matching plan found for plan with alias: ${plan.alias}`,
    );
    return <></>;
  }

  return (
    <Stack key={`${id}_${index}`} data-testid={id} spacing="xs">
      <Text c="gray.5" fw={700} fz="xs">
        {hosted_instance?.alias_url || plan.description}
      </Text>

      <Flex align="center" direction="row" gap="xl" justify="flex-start">
        <div className={classes.cell}>
          <Text c="gray.5" fw={700} fz="xs">
            {plan.price}/month
          </Text>
          <Text c="gray.4" fw={400} fz="xs">
            Incl. {plan.users_included} users, then {plan.per_user_price}
            /user/month
          </Text>
        </div>

        <Flex
          align="center"
          direction="row"
          justify="flex-start"
          w={{ base: "10%", sm: "auto" }}
        >
          <IconArrowRight color={theme.colors.yellow[3]} />
        </Flex>

        <div className={classes.cell}>
          <Group spacing={theme.spacing.xs / 2}>
            <Text c="gray.5" fw={700} fz="xs">
              ${purchasePlan.annualPrice}/year
            </Text>
            <Badge
              color="yellow.2"
              radius="sm"
              variant="outline"
              bg="yellow.0"
              c="yellow.3"
              fw={700}
              lh={`${theme.fontSizes.sm}px`}
              h="auto"
              fz={theme.fontSizes.lg / 2}
              lts={-0.25}
              py={0}
              tt="none"
            >
              Saves 10%
            </Badge>
          </Group>
          <Text c="gray.4" fw={400} fz="xs">
            Incl. {purchasePlan.usersIncluded} users, then $
            {purchasePlan.annualUserPrice}/user/year
          </Text>
        </div>
      </Flex>
    </Stack>
  );
}
