import { HEALTH_HEALTHY, STATUS_RUNNING } from "@stores/instances/constants";
import {
  SUBSCRIPTION_STATUS_ACTIVE,
  SUBSCRIPTION_STATUS_CANCELLATION_PENDING,
  SUBSCRIPTION_STATUS_CANCELLED,
  SUBSCRIPTION_STATUS_TRIAL,
} from "@stores/subscriptions/constants";
import { IPurchase, IPurchaseTrialUp } from "./types";
import {
  HOSTING_FEATURE_CUSTOM_DOMAIN,
  HOSTING_FEATURE_TRIAL_UP,
} from "./constants";

export function getPurchaseChargedNext(purchases: IPurchase[]): IPurchase {
  const purchasesSortedByNextChargeDates = purchases
    .filter(({ next_charge }) => next_charge)
    .sort((purchase1, purchase2) =>
      purchase1.next_charge?.date &&
      purchase2.next_charge?.date &&
      new Date(purchase1.next_charge.date) >
        new Date(purchase2.next_charge.date)
        ? 1
        : -1,
    );
  return purchasesSortedByNextChargeDates[0];
}

export function getPurchaseBillingFrequency(frequency: number): string {
  // yearly
  if (frequency === 12) {
    return "year";
  }
  // monthly
  else if (frequency === 1) {
    return "month";
  }

  return `${frequency}-months`;
}

export function isCanceledOrDeleted({
  subscription,
  hosted_instance,
}: IPurchase): boolean {
  // Not using subscription.is_canceled because that includes cancellation pending
  return (
    subscription.status === SUBSCRIPTION_STATUS_CANCELLED ||
    hosted_instance?.is_deleted === true
  );
}

export function isBlocked(purchase: IPurchase): boolean {
  // return false;
  const { hosted_instance } = purchase;
  return (
    isCanceledOrDeleted(purchase) || hosted_instance?.health !== HEALTH_HEALTHY
  );
}

// Sort by:
//  - status: trial/active, followed by cancellation-pending, followed by cancelled
//  - within each status, sort by purchase date with recent purchase at the top
export function comparePurchase(
  purchase1: IPurchase,
  purchase2: IPurchase,
): number {
  return (
    statusScore(purchase2) - statusScore(purchase1) ||
    purchase2.subscription.created_at.localeCompare(
      purchase1.subscription.created_at,
    )
  );
}

function statusScore(purchase: IPurchase): number {
  const { subscription } = purchase;
  if (isCanceledOrDeleted(purchase)) {
    return 0;
  } else if (subscription.status === SUBSCRIPTION_STATUS_CANCELLATION_PENDING) {
    return 1;
  } else {
    return 2;
  }
}

// changing plan
// - purchase is not canceled
// - instance is `running` OR self-hosted
// - subscription is `active` OR in `trial`
export function canChangePlan(purchase: IPurchase): boolean {
  const { hosted_instance, subscription } = purchase;
  return (
    !isCanceledOrDeleted(purchase) &&
    !!(
      (!hosted_instance || hosted_instance.status === STATUS_RUNNING) &&
      (subscription.status === SUBSCRIPTION_STATUS_ACTIVE ||
        subscription.status === SUBSCRIPTION_STATUS_TRIAL)
    )
  );
}

function hasHostingFeature(
  { hosting_features }: IPurchase,
  hostingFeature: string,
): boolean {
  if (hosting_features) {
    return !!(Object.keys(hosting_features).indexOf(hostingFeature) > -1);
  }

  return false;
}

// We allow custom domain only if present in the hosting features list
export function canHaveACustomDomain(purchase: IPurchase): boolean {
  return hasHostingFeature(purchase, HOSTING_FEATURE_CUSTOM_DOMAIN);
}

// Can create new instance?
export function canAccountCreateNewInstance(
  purchases: IPurchase[] | null,
): boolean {
  const purchasesInTrial = purchases?.filter(
    (purchase) => purchase.subscription.in_trial,
  );
  return !purchasesInTrial || (purchasesInTrial && purchasesInTrial.length < 2);
}

// Is purchase eligible for pro trial?
export function canTryProPlan(purchase: IPurchase): boolean {
  return (
    hasHostingFeature(purchase, HOSTING_FEATURE_TRIAL_UP) &&
    purchase.subscription.status === SUBSCRIPTION_STATUS_ACTIVE
  );
}

export function getProTrial({
  trial_ups,
}: IPurchase): IPurchaseTrialUp | undefined {
  return trial_ups?.find(({ status }) => !!status);
}
