export const MAX_NUMBER_OF_TAX_IDS = 5;

// https://stripe.com/docs/billing/customer/tax-ids
export const TAX_TYPE_OPTIONS = [
  {
    country: "AD",
    country_name: "Andorra",
    type: "ad_nrt",
    description: "Andorran NRT number",
    example: "A-123456-Z",
  },
  {
    country: "AR",
    country_name: "Argentina",
    type: "ar_cuit",
    description: "Argentinian tax ID number",
    example: "12-3456789-01",
  },
  {
    country: "AU",
    country_name: "Australia",
    type: "au_abn",
    description: "Australian Business Number (AU ABN)",
    example: "12345678912",
  },
  {
    country: "AU",
    country_name: "Australia",
    type: "au_arn",
    description: "Australian Taxation Office Reference Number",
    example: "123456789123",
  },
  {
    country: "AT",
    country_name: "Austria",
    type: "eu_vat",
    description: "Austria European VAT number",
    example: "ATU12345678",
  },
  {
    country: "BE",
    country_name: "Belgium",
    type: "eu_vat",
    description: "Belgium European VAT number",
    example: "BE0123456789",
  },
  {
    country: "BO",
    country_name: "Bolivia",
    type: "bo_tin",
    description: "Bolivian tax ID",
    example: "123456789",
  },
  {
    country: "BR",
    country_name: "Brazil",
    type: "br_cnpj",
    description: "Brazilian CNPJ number",
    example: "01.234.456/5432-10",
  },
  {
    country: "BR",
    country_name: "Brazil",
    type: "br_cpf",
    description: "Brazilian CPF number",
    example: "123.456.789-87",
  },
  {
    country: "BG",
    country_name: "Bulgaria",
    type: "bg_uic",
    description: "Bulgaria Unified Identification Code",
    example: "123456789",
  },
  {
    country: "BG",
    country_name: "Bulgaria",
    type: "eu_vat",
    description: "Bulgaria European VAT number",
    example: "BG0123456789",
  },
  {
    country: "CA",
    country_name: "Canada",
    type: "ca_bn",
    description: "Canadian BN",
    example: "123456789",
  },
  {
    country: "CA",
    country_name: "Canada",
    type: "ca_gst_hst",
    description: "Canadian GST/HST number",
    example: "123456789RT0002",
  },
  {
    country: "CA",
    country_name: "Canada",
    type: "ca_pst_bc",
    description: "Canadian PST number (British Columbia)",
    example: "PST-1234-5678",
  },
  {
    country: "CA",
    country_name: "Canada",
    type: "ca_pst_mb",
    description: "Canadian PST number (Manitoba)",
    example: "123456-7",
  },
  {
    country: "CA",
    country_name: "Canada",
    type: "ca_pst_sk",
    description: "Canadian PST number (Saskatchewan)",
    example: "1234567",
  },
  {
    country: "CA",
    country_name: "Canada",
    type: "ca_qst",
    description: "Canadian QST number (Québec)",
    example: "1234567890TQ1234",
  },
  {
    country: "CL",
    country_name: "Chile",
    type: "cl_tin",
    description: "Chilean TIN",
    example: "12.345.678-K",
  },
  {
    country: "CN",
    country_name: "China",
    type: "cn_tin",
    description: "Chinese tax ID",
    example: "123456789012345678",
  },
  {
    country: "CO",
    country_name: "Colombia",
    type: "co_nit",
    description: "Colombian NIT number",
    example: "123.456.789-0",
  },
  {
    country: "CR",
    country_name: "Costa Rica",
    type: "cr_tin",
    description: "Costa Rican tax ID",
    example: "1-234-567890",
  },
  {
    country: "HR",
    country_name: "Croatia",
    type: "eu_vat",
    description: "Croatia European VAT number",
    example: "HR12345678912",
  },
  {
    country: "CY",
    country_name: "Cyprus",
    type: "eu_vat",
    description: "Cyprus European VAT number",
    example: "CY12345678Z",
  },
  {
    country: "CZ",
    country_name: "Czech Republic",
    type: "eu_vat",
    description: "Czech Republic European VAT number",
    example: "CZ1234567890",
  },
  {
    country: "DK",
    country_name: "Denmark",
    type: "eu_vat",
    description: "Denmark European VAT number",
    example: "DK12345678",
  },
  {
    country: "DO",
    country_name: "Dominican Republic",
    type: "do_rcn",
    description: "Dominican RCN number",
    example: "123-4567890-1",
  },
  {
    country: "EC",
    country_name: "Ecuador",
    type: "ec_ruc",
    description: "Ecuadorian RUC number",
    example: "1234567890001",
  },
  {
    country: "EG",
    country_name: "Egypt",
    type: "eg_tin",
    description: "Egyptian Tax Identification Number",
    example: "123456789",
  },
  {
    country: "SV",
    country_name: "El Salvador",
    type: "sv_nit",
    description: "El Salvadorian NIT number",
    example: "1234-567890-123-4",
  },
  {
    country: "EE",
    country_name: "Estonia",
    type: "eu_vat",
    description: "Estonia European VAT number",
    example: "EE123456789",
  },
  {
    country: "EU",
    country_name: "EU",
    type: "eu_oss_vat",
    description: "European One Stop Shop VAT number for non-Union scheme",
    example: "EU123456789",
  },
  {
    country: "FI",
    country_name: "Finland",
    type: "eu_vat",
    description: "Finland European VAT number",
    example: "FI12345678",
  },
  {
    country: "FR",
    country_name: "France",
    type: "eu_vat",
    description: "France European VAT number",
    example: "FRAB123456789",
  },
  {
    country: "GE",
    country_name: "Georgia",
    type: "ge_vat",
    description: "Georgian VAT",
    example: "123456789",
  },
  {
    country: "DE",
    country_name: "Germany",
    type: "eu_vat",
    description: "Germany European VAT number",
    example: "DE123456789",
  },
  {
    country: "GR",
    country_name: "Greece",
    type: "eu_vat",
    description: "Greece European VAT number",
    example: "EL123456789",
  },
  {
    country: "HK",
    country_name: "Hong Kong",
    type: "hk_br",
    description: "Hong Kong BR number",
    example: "12345678",
  },
  {
    country: "HU",
    country_name: "Hungary",
    type: "eu_vat",
    description: "Hungary European VAT number",
    example: "HU12345678",
  },
  {
    country: "HU",
    country_name: "Hungary",
    type: "hu_tin",
    description: "Hungary tax number (adószám)",
    example: "12345678-1-23",
  },
  {
    country: "IS",
    country_name: "Iceland",
    type: "is_vat",
    description: "Icelandic VAT",
    example: "123456",
  },
  {
    country: "IN",
    country_name: "India",
    type: "in_gst",
    description: "Indian GST number",
    example: "12ABCDE3456FGZH",
  },
  {
    country: "ID",
    country_name: "Indonesia",
    type: "id_npwp",
    description: "Indonesian NPWP number",
    example: "12.345.678.9-012.345",
  },
  {
    country: "IE",
    country_name: "Ireland",
    type: "eu_vat",
    description: "Ireland European VAT number",
    example: "IE1234567AB",
  },
  {
    country: "IL",
    country_name: "Israel",
    type: "il_vat",
    description: "Israel VAT",
    example: "000012345",
  },
  {
    country: "IT",
    country_name: "Italy",
    type: "eu_vat",
    description: "Italy European VAT number",
    example: "IT12345678912",
  },
  {
    country: "JP",
    country_name: "Japan",
    type: "jp_cn",
    description: "Japanese Corporate Number (*Hōjin Bangō*)",
    example: "1234567891234",
  },
  {
    country: "JP",
    country_name: "Japan",
    type: "jp_rn",
    description:
      "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
    example: "12345",
  },
  {
    country: "JP",
    country_name: "Japan",
    type: "jp_trn",
    description: "Japanese Tax Registration Number (*Tōroku Bangō*)",
    example: "T1234567891234",
  },
  {
    country: "KE",
    country_name: "Kenya",
    type: "ke_pin",
    description: "Kenya Revenue Authority Personal Identification Number",
    example: "P000111111A",
  },
  {
    country: "LV",
    country_name: "Latvia",
    type: "eu_vat",
    description: "Latvia European VAT number",
    example: "LV12345678912",
  },
  {
    country: "LI",
    country_name: "Liechtenstein",
    type: "li_uid",
    description: "Liechtensteinian UID number",
    example: "CHE123456789",
  },
  {
    country: "LT",
    country_name: "Lithuania",
    type: "eu_vat",
    description: "Lithuania European VAT number",
    example: "LT123456789123",
  },
  {
    country: "LU",
    country_name: "Luxembourg",
    type: "eu_vat",
    description: "Luxembourg European VAT number",
    example: "LU12345678",
  },
  {
    country: "MY",
    country_name: "Malaysia",
    type: "my_frp",
    description: "Malaysian FRP number",
    example: "12345678",
  },
  {
    country: "MY",
    country_name: "Malaysia",
    type: "my_itn",
    description: "Malaysian ITN",
    example: "C 1234567890",
  },
  {
    country: "MY",
    country_name: "Malaysia",
    type: "my_sst",
    description: "Malaysian SST number",
    example: "A12-3456-78912345",
  },
  {
    country: "MT",
    country_name: "Malta",
    type: "eu_vat",
    description: "Malta European VAT number",
    example: "MT12345678",
  },
  {
    country: "MX",
    country_name: "Mexico",
    type: "mx_rfc",
    description: "Mexican RFC number",
    example: "ABC010203AB9",
  },
  {
    country: "NL",
    country_name: "Netherlands",
    type: "eu_vat",
    description: "Netherlands European VAT number",
    example: "NL123456789B12",
  },
  {
    country: "NZ",
    country_name: "New Zealand",
    type: "nz_gst",
    description: "New Zealand GST number",
    example: "123456789",
  },
  {
    country: "NO",
    country_name: "Norway",
    type: "no_vat",
    description: "Norwegian VAT number",
    example: "123456789MVA",
  },
  {
    country: "PE",
    country_name: "Peru",
    type: "pe_ruc",
    description: "Peruvian RUC number",
    example: "12345678901",
  },
  {
    country: "PH",
    country_name: "Philippines",
    type: "ph_tin",
    description: "Philippines Tax Identification Number",
    example: "123456789012",
  },
  {
    country: "PL",
    country_name: "Poland",
    type: "eu_vat",
    description: "Poland European VAT number",
    example: "PL1234567890",
  },
  {
    country: "PT",
    country_name: "Portugal",
    type: "eu_vat",
    description: "Portugal European VAT number",
    example: "PT123456789",
  },
  {
    country: "RO",
    country_name: "Romania",
    type: "eu_vat",
    description: "Romania European VAT number",
    example: "RO1234567891",
  },
  {
    country: "RO",
    country_name: "Romania",
    type: "ro_tin",
    description: "Romanian tax ID number",
    example: "1234567890123",
  },
  {
    country: "RU",
    country_name: "Russia",
    type: "ru_inn",
    description: "Russian INN",
    example: "1234567891",
  },
  {
    country: "RU",
    country_name: "Russia",
    type: "ru_kpp",
    description: "Russian KPP",
    example: "123456789",
  },
  {
    country: "SA",
    country_name: "Saudi Arabia",
    type: "sa_vat",
    description: "Saudi Arabia VAT",
    example: "123456789012345",
  },
  {
    country: "RS",
    country_name: "Serbia",
    type: "rs_pib",
    description: "Serbian PIB number",
    example: "123456789",
  },
  {
    country: "SG",
    country_name: "Singapore",
    type: "sg_gst",
    description: "Singaporean GST",
    example: "M12345678X",
  },
  {
    country: "SG",
    country_name: "Singapore",
    type: "sg_uen",
    description: "Singaporean UEN",
    example: "123456789F",
  },
  {
    country: "SK",
    country_name: "Slovakia",
    type: "eu_vat",
    description: "Slovakia European VAT number",
    example: "SK1234567891",
  },
  {
    country: "SI",
    country_name: "Slovenia",
    type: "eu_vat",
    description: "Slovenia European VAT number",
    example: "SI12345678",
  },
  {
    country: "SI",
    country_name: "Slovenia",
    type: "si_tin",
    description: "Slovenia tax number (davčna številka)",
    example: "12345678",
  },
  {
    country: "ZA",
    country_name: "South Africa",
    type: "za_vat",
    description: "South African VAT number",
    example: "4123456789",
  },
  {
    country: "KR",
    country_name: "South Korea",
    type: "kr_brn",
    description: "Korean BRN",
    example: "123-45-67890",
  },
  {
    country: "ES",
    country_name: "Spain",
    type: "es_cif",
    description: "Spanish NIF number (previously Spanish CIF number)",
    example: "A12345678",
  },
  {
    country: "ES",
    country_name: "Spain",
    type: "eu_vat",
    description: "Spain European VAT number",
    example: "ESA1234567Z",
  },
  {
    country: "SE",
    country_name: "Sweden",
    type: "eu_vat",
    description: "Sweden European VAT number",
    example: "SE123456789123",
  },
  {
    country: "CH",
    country_name: "Switzerland",
    type: "ch_vat",
    description: "Switzerland VAT number",
    example: "CHE-123.456.789 MWST",
  },
  {
    country: "TW",
    country_name: "Taiwan",
    type: "tw_vat",
    description: "Taiwanese VAT",
    example: "12345678",
  },
  {
    country: "TH",
    country_name: "Thailand",
    type: "th_vat",
    description: "Thai VAT",
    example: "1234567891234",
  },
  {
    country: "TR",
    country_name: "Turkey",
    type: "tr_tin",
    description: "Turkish Tax Identification Number",
    example: "0123456789",
  },
  {
    country: "UA",
    country_name: "Ukraine",
    type: "ua_vat",
    description: "Ukrainian VAT",
    example: "123456789",
  },
  {
    country: "AE",
    country_name: "United Arab Emirates",
    type: "ae_trn",
    description: "United Arab Emirates TRN",
    example: "123456789012345",
  },
  {
    country: "GB",
    country_name: "United Kingdom",
    type: "eu_vat",
    description: "Northern Ireland VAT number",
    example: "XI123456789",
  },
  {
    country: "GB",
    country_name: "United Kingdom",
    type: "gb_vat",
    description: "United Kingdom VAT number",
    example: "GB123456789",
  },
  {
    country: "US",
    country_name: "United States",
    type: "us_ein",
    description: "United States EIN",
    example: "12-3456789",
  },
  {
    country: "UY",
    country_name: "Uruguay",
    type: "uy_ruc",
    description: "Uruguayan RUC number",
    example: "123456789012",
  },
  {
    country: "VE",
    country_name: "Venezuela",
    type: "ve_rif",
    description: "Venezuelan RIF number",
    example: "A-12345678-9",
  },
  {
    country: "VN",
    country_name: "Vietnam",
    type: "vn_tin",
    description: "Vietnamese tax ID number",
    example: "1234567890",
  },
] as const;

const INVOICE_STATUS_TYPE_DRAFT = "draft";
const INVOICE_STATUS_TYPE_OPEN = "open";
export const INVOICE_STATUS_TYPE_PAID = "paid";
const INVOICE_STATUS_TYPE_VOID = "void";
const INVOICE_STATUS_TYPE_UNCOLLECTIBLE = "uncollectible";
export const INVOICE_STATUS_TYPES = [
  INVOICE_STATUS_TYPE_DRAFT,
  INVOICE_STATUS_TYPE_OPEN,
  INVOICE_STATUS_TYPE_PAID,
  INVOICE_STATUS_TYPE_VOID,
  INVOICE_STATUS_TYPE_UNCOLLECTIBLE,
] as const;
