import { ReactElement } from "react";
import { IIconProps } from "../types";

const DEFAULT_WIDTH = 7;
const DEFAULT_HEIGHT = 10;
const RATIO = DEFAULT_WIDTH / DEFAULT_HEIGHT;

export default function IconArrowRight({
  color,
  size,
}: IIconProps): ReactElement {
  return (
    <svg
      height={size ? size / RATIO : DEFAULT_HEIGHT}
      width={size || DEFAULT_WIDTH}
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L5 5L1 9" stroke={color || "#509EE3"} strokeWidth="1.5" />
    </svg>
  );
}
