import { useQuery, useMutation, useQueryClient } from "@common/utils/use-query";
import { useCallback } from "react";
import { BillingStore, ITaxId } from "./types";

export const billingStore: BillingStore = {
  useGetTaxIds() {
    return useQuery("/account/tax_ids");
  },

  useCreateTaxId() {
    const queryClient = useQueryClient();
    return useMutation(
      {
        url: "/account/tax_ids",
        method: "POST",
      },
      {
        onSuccess(createdTaxId: ITaxId) {
          queryClient.setQueryData<ITaxId[] | undefined>(
            "/account/tax_ids",
            (taxIds) => {
              if (taxIds) {
                return [...taxIds, createdTaxId];
              }

              return taxIds;
            },
          );
        },
      },
    );
  },

  useDeleteTaxId(taxIdToBeDeleted: ITaxId) {
    const queryClient = useQueryClient();
    const { mutate, ...useMutationResult } = useMutation<null>(
      { url: `/account/tax_ids/${taxIdToBeDeleted.id}`, method: "DELETE" },
      {
        onSuccess() {
          queryClient.setQueryData<ITaxId[] | undefined>(
            "/account/tax_ids",
            (taxIds) => {
              if (taxIds) {
                return taxIds.filter(
                  (taxId) => taxId.id !== taxIdToBeDeleted.id,
                );
              }
              return taxIds;
            },
          );
        },
      },
    );

    const newMutate: typeof mutate = useCallback(
      (_, options) => mutate(undefined, options),
      [mutate],
    );

    return {
      ...useMutationResult,
      mutate: newMutate,
    };
  },

  useGetInvoices() {
    return useQuery("/account/invoices");
  },
};
