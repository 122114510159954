import { Anchor, ChevronIcon, Flex, Title } from "@mantine/core";
import Link from "next/link";
import { ReactElement } from "react";
import TitleWithIcon from "../../TitleWithIcon";
import { useStyles } from "./AccordionControlLink.styled";
import { IAccordionControlLinkProps } from "./types";

export default function AccordionControlLink({
  icon,
  title,
  href,
}: IAccordionControlLinkProps): ReactElement {
  const { classes, cx } = useStyles();

  return (
    <Anchor className={cx(classes.root)} component={Link} href={href}>
      <Flex align="center" direction="row" justify="space-between">
        {icon ? (
          <TitleWithIcon icon={icon} title={title} order={2} size="h6" />
        ) : (
          <Title m={0} order={2} size="h6">
            {title}
          </Title>
        )}
        <ChevronIcon className={classes.chevronIcon} />
      </Flex>
    </Anchor>
  );
}
