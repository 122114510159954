import { createStyles } from "@mantine/core";
import { PropsWithChildren } from "react";

export const useStyles = createStyles(
  (theme, { children }: PropsWithChildren) => ({
    item: {
      paddingBottom: theme.spacing.xl,
      paddingTop: theme.spacing.xl,
    },

    control: {
      alignItems: children ? "flex-start" : "center",
      cursor: "default",
      opacity: 1,

      "&:disabled": {
        cursor: "default",
        opacity: 1,
      },
    },

    container: {
      marginTop: theme.spacing.xs,
    },

    anchor: {
      color: theme.colors.gray[6],

      "*": {
        color: "inherit",
      },
    },
  }),
);
