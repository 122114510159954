import { ITaxId } from "@stores/billing/types";

type TaxIdRowType = Pick<ITaxId, "country" | "type">;

export function getUniqueIdForTaxIdForRow(
  { country, type }: TaxIdRowType,
  value: string,
): string {
  return `${country}-${type}-${value}`
    .split(".")
    .join("-")
    .split("/")
    .join("_")
    .split(" ")
    .join("_");
}

export function getTaxIdLabelForRow(
  { country, type }: TaxIdRowType,
  value: string,
): string {
  return `${country} (${type.split("_").join(" ").toUpperCase()}): ${value}`;
}
