// Status
// 400 = bad request
// 404 = not found
// 409 = conflict

// base request to API
export function request(
  url: string,
  method = "GET",
  body?: string,
): Promise<Response> {
  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    credentials: "include",
    method,
    body,
  });
}
