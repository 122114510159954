import { IDataFetcherResponseError } from "@common/utils/use-query/types";
import { TaxTypeOptionType } from "@stores/billing/types";

const SEPARATOR = ".";

export function getUniqueTaxType({ country, type }: TaxTypeOptionType): string {
  return `${country}${SEPARATOR}${type}`;
}

export function getTaxLabel({
  description,
  example,
}: TaxTypeOptionType): string {
  return `${description} e.g. ${example}`;
}

export function getTaxTypeFromUniqueTaxType(uniqueTaxType: string): string {
  const uniqueTaxTypeSplit = uniqueTaxType.split(SEPARATOR);
  if (uniqueTaxTypeSplit.length >= 2) {
    return uniqueTaxTypeSplit[1];
  } else {
    console.warn(`Unique tax ID type is invalid: ${uniqueTaxType}`);
  }

  return uniqueTaxType;
}

export function getErrorMessage(error: IDataFetcherResponseError): string {
  if (error.data?.code === "tax_id_invalid") {
    return "Invalid tax ID format for the specified type.";
  }

  return error.message;
}
