import { ReactElement } from "react";
import { ITick } from "./types";

export default function Tick({ color }: ITick): ReactElement {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3653 1.40496L5.6143 10.156L0.57666 5.11836L1.63732 4.0577L5.6143 8.03468L13.3047 0.344299L14.3653 1.40496Z"
        fill={color || "#509ee3"}
      />
    </svg>
  );
}
