import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  root: {
    color: theme.colors.gray[6],

    "*": {
      color: "inherit",
    },
  },

  chevronIcon: { marginRight: "5px", transform: "rotate(-90deg)" },
}));
